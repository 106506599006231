import { useQuery } from "@tanstack/react-query";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddApplicationTxkmChangeModal from "./AddApplicationTxkmChangeModal";
import { Tag } from "antd";
import { get } from "lodash";
import FilterBtn from "pages/Application/Txkm/TxkmFilterBtn";
import ApplicationTxkmFilterBtn from "pages/Application/TxkmChange/ApplicationTxkmFilterBtn";
import { ColumnsType } from "antd/es/table";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Button, Pagination, Table } from "components/shared";

interface IChangeTxkm {
	id: number;
	terminalId: string;
	newCtoName: string;
	oldCtoName: string;
	kkmCategoryName: string;
	serialNumber: string;
	createdDate: string;
	status: number;
}

const SIZE = 20;

const ApplicationTxkmChangeList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const statusQS = new URLSearchParams(window.location.search).get("status");
	const fm_numberQS = new URLSearchParams(window.location.search).get("fm_number");

	const queryTxkmChange = useQuery({
		queryKey: ["queryTxkmChange", state.page, statusQS, fm_numberQS],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `change-cto-application/index`,
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
					status: statusQS,
					terminalId: fm_numberQS,
				},
			});
		},
	});
	const data = get(queryTxkmChange, "data.data.data", []);
	const total = get(queryTxkmChange, "data.data.data.totalElements", 0) as number;

	const columns: ColumnsType<IChangeTxkm> = [
		{
			title: "№",
			dataIndex: "number",
			render: (_, __, i) => <span>{Number(state.page) * Number(total) + i + 1} </span>,
		},
		{
			title: t(`ФМ терминал ИД`),
			dataIndex: "terminalId",
		},
		{
			title: t(`Эски ТХКМ`),
			dataIndex: "oldCtoName",
		},
		{
			title: t(`Янги ТХКМ`),
			dataIndex: "newCtoName",
		},
		{
			title: t(`Онлайн-НКМ категорияси`),
			dataIndex: "kkmCategoryName",
		},
		{
			title: t(`ОНКМ серия рақами`),
			dataIndex: "serialNumber",
		},
		{
			title: t(`Яратилган сана`),
			dataIndex: "createdDate",
		},
		{
			title: t("Статус"),
			dataIndex: "status",
			align: "center",
			render(value, record, index) {
				if (get(record, "status") === 0) {
					return <Tag color="#0356fc">{t("Тасдиқланмаган")}</Tag>;
				}

				if (get(record, "status") === 1) {
					return <Tag color="#0356fc">{t("Янги")}</Tag>;
				}

				if (get(record, "status") === 2) {
					return <Tag color="#FFA500">{t("Кўриб чиқилмоқда")}</Tag>;
				}

				if (get(record, "status") === 3) {
					return <Tag color="#87d068">{t("Қабул қилинди")}</Tag>;
				}

				if (get(record, "status") === 4) {
					return <Tag color="#f50">{t("Рад этилди")}</Tag>;
				}

				if (get(record, "status") === 5) {
					return <Tag color="#87d068">{t("Корхона тасдиклади")}</Tag>;
				}

				if (get(record, "status") === 6) {
					return <Tag color="#f50">{t("Корхона рад килди")}</Tag>;
				}

				return null;
			},
		},
		{
			title: " ",
			dataIndex: "IDX",
			render(_, record) {
				return (
					<Button
						className="!shadow-none !w-9 !h-9 !p-0 flex items-center justify-center"
						onClick={() => navigate(`/application/txkm-almashtirish/${record.id}`)}
					>
						<ArrowRight size={20} strokeWidth={1.5} />
					</Button>
				);
			},
		},
	];

	return (
		<>
			<div className="flex items-center justify-between">
				<ApplicationTxkmFilterBtn />

				<AddApplicationTxkmChangeModal />
			</div>

			<Table columns={columns} dataSource={data} />

			<div className="h-[20px]" />

			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</>
	);
};

export default ApplicationTxkmChangeList;
