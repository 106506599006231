import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Language.module.scss";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Skeleton } from "antd";
import classNames from "classnames";
import { ILang, Languages } from "types";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { request, queryClient } from "services/api";
import { useGetLanguages } from "hooks/queries/general";

interface LanguageItem extends Omit<MenuItemType, "key"> {
	key: Languages;
}

const Language = ({ dark }: { dark?: boolean }) => {
	const { i18n } = useTranslation();

	const defaultList: Array<LanguageItem> = [
		{
			key: "ru",
			label: "Русский",
			onClick: () => i18n.changeLanguage("ru"),
		},
		{
			key: "uz",
			label: "Ўзбекча",
			onClick: () => i18n.changeLanguage("uz"),
		},
	];

	const { data, isLoading: getLoading } = useGetLanguages({});
	const { mutate, isLoading } = useMutation({ mutationFn: (lang: string) => request.put(`/user/change-language/${lang}`) });
	const loading = getLoading || isLoading;

	const storageLang = localStorage.getItem("i18nextLng") as Languages;

	const changeLang = (lang: string) => {
		if (lang !== storageLang) {
			mutate(lang.toLowerCase(), {
				onSuccess(data) {
					if (data?.data?.success) {
						i18n.changeLanguage(lang);
						queryClient.invalidateQueries();
					}
				},
			});
		}
	};

	const items: Array<LanguageItem> = useMemo(() => {
		if (data?.data?.data?.length) {
			return data?.data?.data?.map((item: ILang) => ({
				key: item?.code,
				label: item?.name,
				onClick: () => changeLang(item?.code),
			}));
		}
		return defaultList;
	}, [data]);

	const currentLang = items.find(({ key }) => key.toLocaleLowerCase() === storageLang.toLocaleLowerCase());

	if (loading) return <Skeleton.Button active />;

	return (
		<Dropdown menu={{ items }} className={classes.dropdown}>
			<div
				className={classNames({
					"flex !items-center !gap-2 bg-gray-100/10 !py-2 !px-2 !pl-4 rounded-lg hover:bg-gray-100/20 duration-200": true,
					"!text-black": !!dark,
				})}
			>
				<p className="!my-0">{currentLang?.label}</p>
				<DownOutlined rev={"span"} className="!text-[14px]" />
			</div>
		</Dropdown>
	);
};

export default Language;
