import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { get } from "lodash";
import { ColumnsType } from "antd/es/table";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Pagination, Table } from "components/shared";
import { EFmChangeType } from "pages/Application/components/TabFilialFmChange";

const SIZE = 20;

function TabDefaultFmChange() {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const fmChangeOptions = [
		{
			label: t("Хотираси тўлган"),
			value: EFmChangeType.FISKAL_XOTIRASI_TOLGAN,
		},
		{
			label: t("Яроқсиз"),
			value: EFmChangeType.FISKAL_MODUL_YAROQSIZ,
		},
		{
			label: t("Йўқотилган"),
			value: EFmChangeType.FISKAL_MODUL_YOQOTILGAN,
		},
	];

	const statusQS = new URLSearchParams(window.location.search).get("status");
	const oldFmQS = new URLSearchParams(window.location.search).get("oldFm");
	const newFmQS = new URLSearchParams(window.location.search).get("newFm");

	const queryFmChanges = useQuery({
		queryKey: ["queryFmChanges", state.page, statusQS, oldFmQS, newFmQS],
		queryFn: () => {
			return req("reestr")({
				url: `/fm-list/fm-change-application`,
				method: "GET",
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
					status: statusQS,
					oldTerminalId: oldFmQS,
					terminalId: newFmQS,
				},
			});
		},
	});

	const columns: ColumnsType<any> = [
		{
			width: 50,
			dataIndex: "number",
			title: "№",
			render: (data, mock, i) => <>{Number(state.page - 1) * Number(SIZE) + i + 1}</>,
		},
		{
			title: t("ФМ терминал"),
			dataIndex: "terminalId",
		},
		{
			title: t("Эски ФМ"),
			dataIndex: "oldTerminalId",
		},
		{
			title: t("Сабаб"),
			dataIndex: "reason",
		},
		{
			title: t("Юборидган сана"),
			dataIndex: "createdDate",
		},
		{
			title: t("Тип"),
			dataIndex: "type",
			render(value, record, index) {
				const reason = fmChangeOptions.find((option) => option.value === value)?.label;

				return <Tag color="#f50">{t(reason || "")}</Tag>;
			},
		},
		{
			title: t("Статус"),
			dataIndex: "status",
			align: "center",
			render(value, record, index) {
				if (get(record, "status") === 10) {
					return <Tag color="#0356fc">{t("Yangi")}</Tag>;
				}

				if (get(record, "status") === 20) {
					return <Tag color="#FFA500">{t("Янги (авто)")}</Tag>;
				}

				if (get(record, "status") === 30) {
					return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
				}

				if (get(record, "status") === 35) {
					return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
				}

				if (get(record, "status") === 25) {
					return <Tag color="#f50">{t("Қабул қилинди (авто)")}</Tag>;
				}

				return value;
			},
		},
	];

	const total = get(queryFmChanges, "data.data.recordsTotal", 0);
	const data = get(queryFmChanges, "data.data.data", 0);

	return (
		<>
			<Table columns={columns} dataSource={data} />

			<div className="h-[20px]" />

			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</>
	);
}

export default TabDefaultFmChange;
