import { useQuery } from "@tanstack/react-query";
import { find, get } from "lodash";
import Visual from "pages/warehouse/Visual";
import { req } from "services/api";

const WarehouseVisualChart = ({ tree = [] }: any) => {
	const queryVisualChartData = useQuery({
		queryKey: ["queryVisualChartData"],
		queryFn: () => {
			return req("reestr")({
				url: `/fm-list-storage/stat`,
				method: "GET",
			});
		},
	});
	const stat = get(queryVisualChartData, "data.data.data.tree", []);
	const eimzoCount = get(queryVisualChartData, "data.data.data.eimzo", 0);

	let total = 0;
	stat.forEach((stat: any) => {
		total += +get(stat, "statusCount", 0);
	});

	return (
		<Visual
			data={{
				jami: +total + +eimzoCount,
				yangi_fiskal_modullar:
					+get(
						find(stat, (data) => data.status === 1),
						"statusCount",
						0
					)
					+get(
						find(stat, (data) => data.status === 2),
						"statusCount",
						0
					),
				aplet_yozilmagan:
					+get(
						find(stat, (data) => data.status === 1),
						"statusCount",
						0
					) + +eimzoCount,
				aplet_yozilgan:
					get(
						find(stat, (data) => data.status === 2),
						"statusCount",
						0
					) +
					get(
						find(stat, (data) => data.status === 3),
						"statusCount",
						0
					) +
					get(
						find(stat, (data) => data.status === 4),
						"statusCount",
						0
					),
				ecp: eimzoCount,
				txkm: get(
					find(stat, (data) => data.status === 3),
					"statusCount",
					0
				),
				fmo_left:
					get(
						find(stat, (data) => data.status === 1),
						"statusCount",
						0
					),
				fmo_right:
					get(
						find(stat, (data) => data.status === 2),
						"statusCount",
						0
					) +
					get(
						find(stat, (data) => data.status === 4),
						"statusCount",
						0
					),
				yangi: get(
					find(stat, (data) => data.status === 2),
					"statusCount",
					0
				),
				qaytarilgan: get(
					find(stat, (data) => data.status === 4),
					"statusCount",
					0
				),
			}}
		/>
	);
};

export default WarehouseVisualChart;
