import { useReducer } from 'react';

type TAction<T> = {
	[K in keyof T]: {
		type: K;
		param: T[K];
	};
}[keyof T];

export const useAutoReducer = <T extends object>(initialState: T) => {
	return useReducer((state: T, action: TAction<T>): T => {
		return { ...state, [action.type]: action.param };
	}, initialState);
};

export default useAutoReducer;
