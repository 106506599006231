// ============ COMMON_ICONS ============
import { SVGProps, useMemo } from "react";

import Approve from "./common/Approve";
import ArrowDown2 from "./common/ArrowDown2";
import ArrowLeft from "./common/ArrowLeft";
import AttachInterface from "./common/AttachInterface";
import CirclePlus from "./common/CirclePlus";
import Close from "./common/Close";
import DownloadSvg from "./common/DownloadSvg";
import Error from "./common/Error";
import Excel from "./common/Excel";
import FilterSettingsSvg from "./common/FilterSettingsSvg";
import MenuArrowRight from "./common/MenuArrowRight";
import RefreshCcv from "./common/RefreshCcv";

import { createProxyWithHandler } from "utils/proxy";

// ============ OTHER_ICONS ============
// ....

export type SvgTypes =
	| "filterSettings"
	| "download"
	| "menu-arrow-right"
	| "arrow-down2"
	| "refresh-ccv"
	| "circle-plus"
	| "attach-interface"
	| "close"
	| "approve"
	| "error"
	| "arrow-left"
	| "excel";

interface ISvgProps extends SVGProps<SVGSVGElement> {
	icon: SvgTypes;
}

export default function Svg({ icon, ...props }: ISvgProps) {
	const SVG_LIST: Record<SvgTypes, JSX.Element> = useMemo(
		() => ({
			filterSettings: <FilterSettingsSvg {...props} />,
			download: <DownloadSvg name={icon} {...props} />,
			"menu-arrow-right": <MenuArrowRight name={icon} {...props} />,
			"arrow-down2": <ArrowDown2 name={icon} {...props} />,
			"refresh-ccv": <RefreshCcv name={icon} {...props} />,
			"circle-plus": <CirclePlus name={icon} {...props} />,
			"attach-interface": <AttachInterface name={icon} {...props} />,
			close: <Close name={icon} {...props} />,
			approve: <Approve name={icon} {...props} />,
			error: <Error name={icon} {...props} />,
			"arrow-left": <ArrowLeft name={icon} {...props} />,
			excel: <Excel name={icon} {...props} />
		}),
		[]
	);

	const proxySvgList = createProxyWithHandler(SVG_LIST, <span />);

	return proxySvgList[icon];
}

/* =============
Example:

1) <Svg icon="error" />

2) <Svg icon="error" style={{color: "blue"}} />

3) <Svg icon="error" {...otherSvgProps} />

============= */
