import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Alert, Button, Spin } from "antd";
import { get, isEmpty } from "lodash";
import { req } from "services/api";
import { Input } from "components/shared";
import ErrorText from "pages/Rester/components/BranchesView/ErrorText";
import SavdoShoxobcha from "pages/Rester/components/BranchesView/SavdoShoxobcha";

export interface ICompany {
	region: number;
	regionName: string;
	district: number;
	districtName: string;
	name: string;
	address: string;
	noMoney: boolean;
	balance: {
		tin: string;
		pinfl: string;
		overpaymentSum: number;
		terminalCost: number;
	};
}

function KorxonaForm() {
	const { t } = useTranslation();
	const {
		control,
		setValue,
		trigger,
		getValues,
		watch,
		formState: { errors },
	} = useFormContext();

	const mutateGetCompanyData = useMutation({
		mutationKey: ["mutateGetCompanyData"],
		mutationFn: (companyTin: string) => {
			if (companyTin.length > 9) {
				return req("reestr")({
					url: `/information/get-company-by-pinfl/${companyTin}`,
					method: "GET",
				});
			}

			return req("reestr")({
				method: "GET",
				url: `/information/get-company-by-tin/${companyTin}`,
			});
		},
	});
	const companyData = get(mutateGetCompanyData, "data.data.data", {}) as ICompany;

	useEffect(() => {
		if (!isEmpty(companyData)) {
			setValue("companyData_name", get(companyData, "name", "-"));
			setValue("companyData_address", get(companyData, "address", "-"));
			setValue("companyData_regionName", get(companyData, "regionName", "-"));
			setValue("companyData_districtName", get(companyData, "districtName", "-"));
			setValue("companyData_neighborhoodName", get(companyData, "neighborhoodName", "-"));
		}
	}, [companyData]);

	// DO NOT REMOVE should trigger re-render
	watch("companyData_name");

	return (
		<>
			<>
				<div className="flex gap-5">
					<Controller
						name="search_tin"
						control={control}
						rules={{
							required: true,
							validate: (value) => {
								if (value.length === 9 || value.length === 14) {
									return true;
								}

								return false;
							},
						}}
						render={({ field }) => (
							<>
								<div className="!w-64">
									<NumericFormat
										maxLength={14}
										customInput={Input}
										className="!w-64"
										placeholder={t("СТИР/ЖШШИР")}
										{...field}
									/>

									<ErrorText>{get(errors, "search_tin", null) ? t("СТИР/ЖШШИР шарт!") : null}</ErrorText>
								</div>

								<Button
									htmlType="button"
									onClick={async () => {
										const passes = await trigger("search_tin");

										if (passes) {
											setValue("tin", field.value);
											setValue("is_inn_entered", true);

											mutateGetCompanyData.mutate(field.value);
										}
									}}
								>
									{t("Текшириш")}
								</Button>
							</>
						)}
					/>
				</div>

				<div className="h-[20px]" />

				{companyData.noMoney ? (
					<div>
						<Alert description={<span className="!text-red-500">{t("Puli tolanmagan")}</span>} showIcon type="error" />
					</div>
				) : (
					<Spin spinning={mutateGetCompanyData.isLoading}>
						<div className="flex !gap-10">
							<div className="border !border-gray-400 rounded-lg w-1/2 !p-5 space-y-7" style={{ border: "1px solid" }}>
								<h3 className="text-blue-700 text-lg text-center block">{t("Корхона")}</h3>

								<Input
									label={t("Номи")}
									disabled
									className=""
									placeholder="-"
									size="large"
									value={getValues("companyData_name")}
								/>

								<Input
									label={t("Манзили")}
									disabled
									className=""
									placeholder="-"
									size="large"
									value={getValues("companyData_address")}
								/>

								<div className="grid grid-cols-3 gap-5">
									<Input size="large" label={t("Вилоят")} disabled value={getValues("companyData_regionName")} />
									<Input size="large" label={t("Туман")} disabled value={getValues("companyData_districtName")} />
									<Input size="large" label={t("Махалла")} disabled value={getValues("companyData_neighborhoodName")} />
								</div>
							</div>

							<SavdoShoxobcha />
						</div>
					</Spin>
				)}
			</>
		</>
	);
}

export default KorxonaForm;
