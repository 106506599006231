import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { find, get, toPairs } from "lodash";
import { req } from "services/api";
import { Input, Select } from "components/shared";
import ErrorText from "pages/Rester/components/BranchesView/ErrorText";

interface FormValues {
	reestr: string;
	name: string;
	faoliyat_turi: string;
	shoxobcha_toifasi: string;
	saleAddress: string;
	phone: string;
}

function SavdoShoxobcha() {
	const { t } = useTranslation();
	const {
		resetField,
		control,
		watch,
		setValue,
		formState: { errors },
		clearErrors,
	} = useFormContext<FormValues & any>();

	const selectedReestr = watch("reestr");
	const faoliyatTuri = watch("faoliyat_turi");
	const tin = watch("tin");

	const queryReestrSelect = useQuery({
		queryKey: ["queryReestrSelect", tin],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/sale-point/index`,
				params: {
					tin,
					pageNo: 0,
					pageSize: 100_000,
				},
			});
		},
		enabled: tin.length === 9 || tin.length === 14,
	});
	const reestrs = get(queryReestrSelect, "data.data.data", []);

	const queryFaoliyatTurlari = useQuery({
		queryKey: ["queryFaoliyatTurlari", tin],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/information/index/ns412`,
				params: {
					pageNo: 0,
					pageSize: 100_000,
				},
			});
		},
	});
	const faoliyatTurlari = get(queryFaoliyatTurlari, "data.data.data", []);

	const queryQoshimcha = useQuery({
		queryKey: ["queryQoshimcha", faoliyatTuri],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/information/index/ns413/${faoliyatTuri}`,
				params: {
					pageNo: 0,
					pageSize: 100_000,
				},
			});
		},
		enabled: !!faoliyatTuri,
	});
	const faoliyatQoshimcha = get(queryQoshimcha, "data.data.data", []);

	useEffect(() => {
		if (selectedReestr && queryReestrSelect.status === "success" && selectedReestr !== "new") {
			const selectedReestrData = find(reestrs, (_i) => {
				return _i.id === selectedReestr;
			});

			const values: Omit<FormValues, "reestr" | "phone"> = {
				name: selectedReestrData?.name,
				saleAddress: selectedReestrData?.address,
				faoliyat_turi: selectedReestrData?.ns412Code,
				shoxobcha_toifasi: selectedReestrData?.ns413Code,
			};

			toPairs(values).forEach(([key, value]) => {
				setValue(key as keyof FormValues, value);
				clearErrors(key);
			});
		}
	}, [selectedReestr]);

	return (
		<>
			<div className="border !border-gray-400 rounded-lg w-1/2 !p-5 space-y-7" style={{ border: "1px solid" }}>
				<h3 className="text-blue-700 text-lg text-center block">{t("Савдо шахобчаси")}</h3>

				<Controller
					control={control}
					rules={{ required: true }}
					name="reestr"
					render={({ field }) => {
						return (
							<Select
								label={t("Реестр")}
								placeholder="-"
								defaultValue={"new"}
								options={[
									{
										label: t("Янги"),
										value: "new",
									},
									...(reestrs
										? reestrs.map((reestr: any) => {
												return {
													label: reestr.name,
													value: reestr.id,
												};
										  })
										: []),
								]}
								{...field}
								onChange={(e) => {
									if (e === "new") {
										const values = {
											name: undefined,
											saleAddress: undefined,
											faoliyat_turi: undefined,
											shoxobcha_toifasi: undefined,
										};

										toPairs(values).forEach(([key, value]) => {
											resetField(key);
										});
									}

									field.onChange(e);
								}}
							/>
						);
					}}
				/>

				<Controller
					control={control}
					rules={{ required: true }}
					name="name"
					render={({ field }) => {
						return (
							<div>
								<Input
									disabled={selectedReestr !== "new"}
									label={t("Номи")}
									// className=""
									placeholder="-"
									size="large"
									{...field}
								/>

								<ErrorText>{get(errors, "name", null) ? t(t("Тўлдириш шарт!")) : null}</ErrorText>
							</div>
						);
					}}
				/>

				<Controller
					control={control}
					rules={{ required: true }}
					name="faoliyat_turi"
					render={({ field }) => {
						return (
							<div>
								<Select
									disabled={selectedReestr !== "new"}
									label={t("Асосий фаолият тури")}
									options={faoliyatTurlari.map((reestr: any) => {
										return {
											label: reestr.name,
											value: String(reestr.code),
										};
									})}
									{...field}
									onChange={(value) => {
										field.onChange(value);
										resetField("shoxobcha_toifasi");
									}}
								/>
								<ErrorText>{get(errors, "faoliyat_turi", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
							</div>
						);
					}}
				/>

				<Controller
					control={control}
					rules={{ required: true }}
					name="shoxobcha_toifasi"
					render={({ field }) => {
						return (
							<div>
								<Select
									label={t("Шаҳобча фаолият тоифаси")}
									options={faoliyatQoshimcha.map((reestr: any) => {
										return {
											label: reestr.name,
											value: String(reestr.code),
										};
									})}
									{...field}
								/>

								<ErrorText>{get(errors, "shoxobcha_toifasi", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
							</div>
						);
					}}
				/>

				<div className="grid grid-cols-1 gap-5">
					{/* <Controller
						name="saleAddress"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<div>
									<Input label={t("Манзили")} placeholder="-" {...field} />
									<ErrorText>{get(errors, "saleAddress", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
								</div>
							);
						}}
					/> */}

					<Controller
						name="phone"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<div>
									<PatternFormat
										label={t("Телефон рақам")}
										{...field}
										prefix={"+998"}
										customInput={Input}
										format="## ### ## ##"
										size="middle"
									/>

									<ErrorText>{get(errors, "phone", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
								</div>
							);
						}}
					/>
				</div>
			</div>
		</>
	);
}

export default SavdoShoxobcha;
