import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classes from "../Style.module.scss";
import { Col, Row, Space } from "antd";
import classNames from "classnames";
import QueryHook from "utils/queryHook";
import { Button, Input, Select } from "components/shared";
import LabelForInput from "components/shared/LabelForInput";

const ApplicationTxkmFilterBtn = () => {
	const { t } = useTranslation();
	const { MergeQueryParams } = QueryHook();
	const [filer] = useState<boolean>(true);
	const { handleSubmit, control, reset, watch, getValues, setValue } = useForm();

	const onSubmit = (values: any) => {
		MergeQueryParams({ ...values });
	};

	const filterClear = () => {
		MergeQueryParams({
			fm_number: "",
			status: "",
		});
		reset();
	};

	return (
		<div className="w-1/2">
			{filer && (
				<div
					className={classNames({
						[classes.filter_box]: true,
					})}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Row gutter={[20, 20]}>
							<Col span={8}>
								<Controller
									name="fm_number"
									control={control}
									render={({ field }) => <Input label={t("ФМ терминал")} {...field} />}
								/>
							</Col>

							<Col span={8}>
								<Controller
									name="status"
									control={control}
									render={({ field }) => (
										<LabelForInput label={t("Статус")}>
											<Select
												size="small"
												{...field}
												options={[
													{ value: "1", label: t("Янги") },
													{ value: "0", label: t("Тасдиқланмаган") },
													{ value: "2", label: t("Кўриб чиқилмоқда") },
													{ value: "3", label: t("Қабул қилинди") },
													{ value: "4", label: t("Рад этилди") },
													{ value: "5", label: t("Корхона тасдиклади") },
													{ value: "6", label: t("Корхона рад килди") },
												]}
											/>
										</LabelForInput>
									)}
								/>
							</Col>
							<Col>
								<Space>
									<Button onClick={filterClear}>{t("Тозалаш")}</Button>
									<Button htmlType="submit" type="primary">
										{t("Қидириш")}
									</Button>
								</Space>
							</Col>
						</Row>
					</form>
				</div>
			)}
		</div>
	);
};

export default ApplicationTxkmFilterBtn;
