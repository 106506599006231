import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { SelectProps } from "antd";
import { get } from "lodash";
import { req } from "services/api";
import { Select } from "components/shared";

export interface IViloyat {
	code: number;
	name: string;
}

function SelectViloyat(props: SelectProps) {
	const { t, i18n } = useTranslation();
	const queryViloyats = useQuery({
		queryKey: ["queryViloyats", i18n.language],
		queryFn: () => {
			return req("reestr")({
				url: `/information/index/region`,
				method: "GET",
				params: {
					lang: i18n.language,
				},
			});
		},
	});
	const viloyats = get(queryViloyats, "data.data.data", []) as IViloyat[];

	return (
		<>
			<Select
				{...props}
				label={t("Вилоят")}
				options={viloyats.map((viloyat) => ({
					label: viloyat.name,
					value: viloyat.code,
				}))}
			/>
		</>
	);
}

export default SelectViloyat;
