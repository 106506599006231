import { ReactNode, useRef, useSyncExternalStore } from "react";
import Sidebar from "../Sidebar/Sidebar";

const Content = ({ children }: { children: ReactNode }) => {
	const ref = useRef<HTMLDivElement>(null);

	const windowWidth = useSyncExternalStore(
		(cb) => {
			window.addEventListener("resize", cb);
			return () => {
				window.removeEventListener("offline", cb);
			};
		},
		() => window.innerWidth
	);

	return (
		<div className="flex overflow-x-hidden !bg-white">
			<div ref={ref}>
				<Sidebar />
			</div>

			<div style={{ width: windowWidth - (ref.current?.offsetWidth || 70) }} className="overflow-x-auto">
				<main>{children}</main>
			</div>
		</div>
	);
};

export default Content;
