import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { UploadOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input as AntdInput, Button, Col, Modal, Row, Select, Upload, message } from "antd";
import { get } from "lodash";
import * as yup from "yup";
import { request } from "services/api";
import { useGetNeighborhoodQ, useGetNs10Q, useGetNs11Q, useNs413Q } from "hooks/queries/general";
import YMap from "components/YMap";
import AntInput from "components/shared/AntComponents/Input";

const schema = yup.object().shape({
	ns412: yup.string().required(),
	ns413: yup.string().required(),
});

type Props = {
	modal: boolean;
	setModal: any;
	data: any;
};

function getExtension(fileName: any) {
	return fileName.split(".").pop();
}

const MAX_FILE_SIZE = 102400 * 5;

const UpdateAddres = ({ modal, setModal, data }: Props) => {
	const { t } = useTranslation();
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);

	useEffect(() => {
		setLatitude(data.latitude);
		setLongitude(data.longitude);
	}, [data]);

	const [fileList, setFileList] = useState<any>(null);

	const schema = yup.object().shape({
		ns10: yup.mixed().required(t("Тўлдириш мажбурий")),
		ns11: yup.mixed().required(t("Тўлдириш мажбурий")),
		neighborhood: yup.mixed().required(t("Тўлдириш мажбурий")),
		// address: yup.mixed().required(t("Тўлдириш мажбурий")),
		latitude: yup.mixed().required(t("Тўлдириш мажбурий")),
		longitude: yup.mixed().required(t("Тўлдириш мажбурий")),
		phone: yup.string(),
		file: yup.mixed().required(t("Тўлдириш мажбурий")),
		// .test({
		// 	message: t("Файл .pdf, ёки .zip бўлиши керак"),
		// 	test: (file, context) => {
		// 		try {
		// 			const isValid = ["zip", "pdf"].includes(getExtension(file?.name));
		// 			if (!isValid) context?.createError();
		// 			return isValid;
		// 		} catch (error) {
		// 			return false;
		// 		}
		// 	}
		// })
		// .test({
		// 	message: `Файл ўлчами ${MAX_FILE_SIZE / 100} kbayt дан катта бўлмаслиги керак`,
		// 	test: (file) => {
		// 		const isValid = file?.size < MAX_FILE_SIZE;
		// 		return isValid;
		// 	}
		// })
	});

	const { control, handleSubmit, reset, watch, setValue } = useForm<any>({ resolver: yupResolver(schema) });

	const ns10Data = useGetNs10Q({});
	const ns10List = get(ns10Data, "data.data.data", [])?.map((item: any) => {
		return {
			label: item?.name,
			value: String(item?.code),
		};
	});

	const ns11Data = useGetNs11Q(Number(watch("ns10")) || data.regionId, {
		enabled: !!watch("ns10"),
	});

	const ns11List = get(ns11Data, "data.data.data", [])?.map((item: any) => ({
		label: item?.name,
		value: String(item?.code),
	}));

	const neighborhoodData = useGetNeighborhoodQ(
		Number(watch("ns10")) || data.regionId,
		Number(watch("ns11")) || data.districtId,
		{
			enabled: !!watch("ns10") && !!watch("ns11"),
		}
	);

	const neighborhoodList = get(neighborhoodData, "data.data.data", [])?.map((item: any) => ({
		label: item?.name,
		value: item?.code,
	}));

	useEffect(() => {
		reset({
			...data,
			ns10: data?.regionId,
			ns11: data?.districtId,
			neighborhood: data?.neighborhoodCode,
		});
	}, [data]);

	const updateData = useMutation(
		(body: any) =>
			request.post(`/sale-point/update-address`, body, {
				headers: {
					"Content-Type": "multipart/form-data", // <- HERE
				},
			}),
		{
			onSuccess: (res: any) => {
				if (res?.data?.success) {
					message.success(t(`Муваффақиятли қўшилди`));
					// refetch();
					setModal(false);
				} else {
					message.info(t(`Avval ariza yuborilgan`));
				}
			},

			onError: (err: any) => {
				message.error(err);
			},
		}
	);

	const onSubmit = async (data: any) => {
		console.log(data);

		const { id, address, phone, ns10, ns11, neighborhood, longitude, latitude, file } = data;
		const formData = new FormData();

		formData.append("id", id);
		formData.append("address", address);
		formData.append("phone", String(phone || "").replaceAll(" ", ""));
		formData.append("regionId", ns10);
		formData.append("districtId", ns11);
		formData.append("neighborhoodCode", neighborhood);
		formData.append("longitude", longitude);
		formData.append("latitude", latitude);
		formData.append("files", file);

		await updateData.mutateAsync(formData);
		reset();
		setFileList(null);
	};

	const showModal = () => {
		setModal(true);
	};

	const handleOk = () => {
		setModal(false);
	};

	const handleCancel = () => {
		setModal(false);
	};

	const handleNs10Change = (value: any) => {
		setValue("ns11", "");
		setValue("neighborhood", "");
	};
	const handleNs11Change = (value: any) => {
		setValue("neighborhood", "");
	};

	return (
		<>
			<Modal open={modal} onOk={handleOk} width={700} footer={false} onCancel={handleCancel} centered>
				<form>
					<div className="flex items-center justify-center flex-col">
						<h3>{t("Манзилни ўзгартириш")}</h3>
					</div>

					<div className="h-[20px]" />

					<div className={""}>
						<Row gutter={[20, 20]}>
							<Col span={12}>
								<p>{t("Телефон рақам")}</p>
							</Col>

							<Col span={12}>
								<Controller
									name="phone"
									control={control}
									render={({ field }: any) => (
										<>
											<PatternFormat {...field} customInput={AntInput} format={"## ### ## ##"} size="middle" prefix="+998" />
										</>
									)}
								/>
							</Col>

							<Col span={12}>
								<p>{t("Вилоят")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="ns10"
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											{...field}
											options={ns10List}
											onChange={(value) => {
												field.onChange(value);
												handleNs10Change(value);
											}}
										/>
									)}
								/>
							</Col>
							<Col span={12}>
								<p>{t("Туман")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="ns11"
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											{...field}
											options={ns11List}
											onChange={(value) => {
												field.onChange(value);
												handleNs11Change(value);
											}}
										/>
									)}
								/>
							</Col>
							<Col span={12}>
								<p>{t("Махалла")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="neighborhood"
									control={control}
									render={({ field }) => <Select style={{ width: "100%" }} {...field} options={neighborhoodList} />}
								/>
							</Col>
							<Col span={12}>
								<p>{t("Манзил")}</p>
							</Col>
							<Col span={12}>
								<Controller name="address" control={control} render={({ field }: any) => <AntdInput {...field} />} />
							</Col>
							<Col span={12}>
								<p>{t("Ариза файли")}</p>
							</Col>
							<Col span={12}>
								<Upload
									maxCount={1}
									multiple={false}
									fileList={fileList}
									beforeUpload={(file) => {
										setFileList([file]);
										setValue("file", file);
										return false;
									}}
								>
									<Button icon={<UploadOutlined rev="" />}>{t("Файл юклаш")}</Button>
								</Upload>
							</Col>
						</Row>
						<div style={{ padding: "20px 0" }}>
							<div className="flex items-center !gap-3 !font-thin">
								{/* <p className="font-thin">{watch("latitude")}</p> */}
								{/* <p className="font-thin">{watch("longitude")}</p> */}
							</div>

							<Row gutter={[20, 20]}>
								<Col span={24}>
									<div className="h-[250px] rounded-lg overflow-hidden">
										<YMap
											latitude={latitude}
											longitude={longitude}
											setLat={(latitude: any) => setValue("latitude", latitude)}
											setLong={(longitude: any) => setValue("longitude", longitude)}
										/>
									</div>
								</Col>
							</Row>
						</div>

						<div className="flex items-center !gap-5">
							<Button onClick={() => setModal(false)} block>
								{t("Бекор қилиш")}
							</Button>
							<Button type="primary" htmlType="submit" onClick={handleSubmit(onSubmit)} loading={updateData.isLoading} block>
								{t("Юбориш")}
							</Button>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default UpdateAddres;
