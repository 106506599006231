import { LogOut } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classes from "./Profile.module.scss";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import UserImage from "assets/images/svg/common/user.svg";

const Profile = ({ tin, fio }: any) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const logout = () => {
		localStorage.removeItem("token");

		navigate("/");
	};

	return (
		<div
			className={classNames({
				[classes.dropdown]: true,
				"w-80": true,
			})}
		>
			<div className="flex items-center justify-center w-full !gap-2">
				<img
					onDoubleClick={() => {
						navigate(`/select-firm?token=${localStorage.getItem("token")}`);
					}}
					src={UserImage}
					alt="profile"
				/>
				<div>
					<>{fio}</>
				</div>

				<Tooltip title={t("Чиқиш")}>
					<Button type="default" className="!ml-5" onClick={logout}>
						<LogOut />
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export default Profile;
