import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tag } from "antd";
import classNames from "classnames";
import { get } from "lodash";
import { useCtoQuery } from "pages/Application/TxkmChange/CtoView";
import MapSection from "pages/Rester/components/SaleView/components/MapSection";

function CtoShoxobchaTab() {
	const { id } = useParams();
	const { t, i18n } = useTranslation();

	const queryCto = useCtoQuery({ id });
	const data = get(queryCto, "data.data.data.salePoint", []);

	const infos = [
		{ title: t("СТИР"), value: get(data, "tin", "-") },
		{ title: t("ЖШШИР"), value: get(data, "pinfl", "-") },
		{ title: t("Корҳона номи"), value: get(data, "legalName", "-") },
		{ title: t("Шаҳобча номи"), value: get(data, "name", "-") },
		{ title: t("Вилоят"), value: get(data, i18n.language === "Ru" ? "regionNameRu" : "regionNameUz", "-") },
		{ title: t("Туман"), value: get(data, i18n.language === "Ru" ? "districtNameRu" : "districtNameUz", "-") },
		{ title: t("Маҳалла"), value: get(data, "neighborhoodNameUz", "-") },
		{ title: t("Манзил"), value: get(data, "address", "-") },
		{ title: t("ФМ терминал"), value: get(data, "terminalId", "-") },
		{ title: t("Онлайн-НКМ категорияси"), value: get(data, "kkmCategoryName", "-") },
		{ title: t("ОНКМ серия рақами"), value: get(data, "serialNumber", "-") },
		{ title: t("Яратилган сана"), value: get(data, "createdDate", "-") },
		{
			title: t("Xolati"),
			value: (
				<>
					{(() => {
						if (get(data, "status") === 1) {
							return <Tag color="#0356fc">{t("Yangi")}</Tag>;
						}

						if (get(data, "status") === 2) {
							return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
						}

						if (get(data, "status") === 3) {
							return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
						}

						if (get(data, "status") === 4) {
							return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
						}
						return null;
					})()}
				</>
			),
		},
		{ title: t("Фаолият тури"), value: get(data, "ns412Name", "-") },
		{ title: t("Асосий фаолият тури"), value: get(data, "ns413Name", "-") },
		{ title: t("ТХКМ"), value: get(data, "ctoName", "-") },
	];

	return (
		<>
			<div className="space-y-7">
				<MapSection latitude={get(data, "latitude", 46)} longitude={get(data, "longitude", 46)} draggable={false} />

				<div className="w-full rounded-md border !border-gray-300 overflow-hidden">
					{infos.map((info, idx) => {
						return (
							<div
								key={idx}
								className={classNames({
									"w-full grid grid-cols-2 !py-2 !px-3": true,
									"bg-gray-50": idx % 2 === 0,
								})}
							>
								<div className="">{info.title}</div>
								<div className="">{info.value}</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default CtoShoxobchaTab;
