import { memo } from "react";

const ComputerTroubleshootingRafiki = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
		<path
			style={{
				fill: "#ebebeb"
			}}
			d="M0 382.4h500v.25H0zM416.78 398.49h33.12v.25h-33.12zM322.53 401.21h8.69v.25h-8.69zM396.59 389.21h19.19v.25h-19.19zM52.46 390.89h43.19v.25H52.46zM104.56 390.89h6.33v.25h-6.33zM131.47 395.11h93.68v.25h-93.68zM237 337.8H43.91a5.71 5.71 0 0 1-5.7-5.71V60.66a5.71 5.71 0 0 1 5.7-5.66H237a5.71 5.71 0 0 1 5.71 5.71v271.38a5.71 5.71 0 0 1-5.71 5.71ZM43.91 55.2a5.46 5.46 0 0 0-5.45 5.46v271.43a5.46 5.46 0 0 0 5.45 5.46H237a5.47 5.47 0 0 0 5.46-5.46V60.66A5.47 5.47 0 0 0 237 55.2ZM453.31 337.8h-193.1a5.72 5.72 0 0 1-5.71-5.71V60.66a5.72 5.72 0 0 1 5.71-5.66h193.1a5.71 5.71 0 0 1 5.69 5.66v271.43a5.71 5.71 0 0 1-5.69 5.71ZM260.21 55.2a5.47 5.47 0 0 0-5.46 5.46v271.43a5.47 5.47 0 0 0 5.46 5.46h193.1a5.47 5.47 0 0 0 5.46-5.46V60.66a5.47 5.47 0 0 0-5.46-5.46Z"
		/>
		<path
			d="M227.33 158.82v22.77l11.72 1.1a60.89 60.89 0 0 0 8.75 21l-7.53 9.1 16.09 16.1 9.1-7.54a60.7 60.7 0 0 0 21 8.75l1.1 11.72h22.77l1.11-11.72a61 61 0 0 0 21-8.75l9.09 7.54 16.1-16.1-7.53-9.1a60.68 60.68 0 0 0 8.74-21l11.73-1.1v-22.77l-11.73-1.11a60.63 60.63 0 0 0-8.74-21l7.53-9.1-16.1-16.1-9.03 7.49a60.76 60.76 0 0 0-21-8.74l-1.11-11.73h-22.8l-1.1 11.73a60.68 60.68 0 0 0-21 8.74l-9.1-7.53-16.09 16.1 7.53 9.09a61 61 0 0 0-8.75 21ZM299 139.38a30.82 30.82 0 1 1-30.82 30.82A30.82 30.82 0 0 1 299 139.38ZM188.86 260.7l6.54 5.41 11.6-11.57-5.44-6.54a43.82 43.82 0 0 0 6.28-15.11l8.42-.79v-16.35l-8.42-.8a43.69 43.69 0 0 0-6.28-15.1l5.41-6.54-11.57-11.57-6.54 5.41a43.66 43.66 0 0 0-15.11-6.28l-.79-8.42H156.6l-.79 8.42a43.82 43.82 0 0 0-15.11 6.28l-6.54-5.41-11.56 11.57 5.41 6.54a43.5 43.5 0 0 0-6.28 15.1l-8.43.8v16.36l8.43.79A43.63 43.63 0 0 0 128 248l-5.41 6.53 11.56 11.57 6.54-5.41a43.63 43.63 0 0 0 15.12 6.31l.79 8.43H173l.79-8.43a43.47 43.47 0 0 0 15.07-6.3Zm-46.22-36.77a22.15 22.15 0 1 1 22.14 22.14 22.15 22.15 0 0 1-22.14-22.14ZM261.57 371.51a52.44 52.44 0 0 0 18-7.48l7.78 6.44 13.77-13.77-6.44-7.78a52.07 52.07 0 0 0 7.47-18l10-.95v-19.46l-10-.95a52.07 52.07 0 0 0-7.47-18l6.44-7.78L287.34 270l-7.78 6.44a52.44 52.44 0 0 0-18-7.48l-.95-10h-19.46l-1 10a52.28 52.28 0 0 0-18 7.48l-7.72-6.44-13.77 13.77 6.44 7.78a52.09 52.09 0 0 0-7.48 18l-10 .95V330l10 .95a52.09 52.09 0 0 0 7.48 18l-6.44 7.78 13.77 13.77 7.78-6.44a52.28 52.28 0 0 0 18 7.48l1 10h19.47Zm-37-51.27a26.37 26.37 0 1 1 26.36 26.37 26.36 26.36 0 0 1-26.41-26.37Z"
			style={{
				fill: "#e0e0e0"
			}}
		/>
		<path
			d="M401.55 211.23h21.51a2.7 2.7 0 0 0 2.34-4l-10.76-18.63a2.69 2.69 0 0 0-4.67 0l-10.75 18.63a2.7 2.7 0 0 0 2.33 4Zm12.2-17.6-.47 9.28h-2l-.49-9.28Zm-3 12.47a1.51 1.51 0 0 1 1.57-1.52 1.53 1.53 0 0 1 1.6 1.52 1.54 1.54 0 0 1-1.6 1.55 1.53 1.53 0 0 1-1.6-1.55ZM87 127.23h21.5a2.7 2.7 0 0 0 2.34-4L100 104.55a2.7 2.7 0 0 0-4.68 0l-10.71 18.63a2.7 2.7 0 0 0 2.39 4.05Zm12.2-17.59-.48 9.27h-2l-.5-9.27Zm-3 12.47a1.51 1.51 0 0 1 1.56-1.53 1.54 1.54 0 1 1 0 3.07 1.52 1.52 0 0 1-1.64-1.54ZM144.65 356.39h21.5a2.7 2.7 0 0 0 2.34-4.05l-10.75-18.63a2.7 2.7 0 0 0-4.68 0l-10.75 18.63a2.7 2.7 0 0 0 2.34 4.05Zm12.2-17.6-.48 9.28h-2l-.5-9.28Zm-3 12.47a1.51 1.51 0 0 1 1.57-1.52 1.54 1.54 0 0 1 1.61 1.52 1.55 1.55 0 0 1-1.61 1.55 1.53 1.53 0 0 1-1.61-1.55ZM346 329.44h21.51a2.7 2.7 0 0 0 2.33-4.05l-10.74-18.63a2.7 2.7 0 0 0-4.68 0l-10.75 18.63a2.7 2.7 0 0 0 2.33 4.05Zm12.2-17.6-.48 9.28h-2l-.5-9.28Zm-3 12.47a1.5 1.5 0 0 1 1.56-1.52 1.54 1.54 0 0 1 1.61 1.52 1.55 1.55 0 0 1-1.61 1.55 1.52 1.52 0 0 1-1.58-1.55ZM361.47 93.79h11.91a1.5 1.5 0 0 0 1.3-2.24l-6-10.31a1.49 1.49 0 0 0-2.59 0l-5.95 10.31a1.49 1.49 0 0 0 1.33 2.24Zm6.76-9.74-.23 5.14h-1.09l-.28-5.14ZM366.55 91a.84.84 0 0 1 .87-.85.85.85 0 1 1 0 1.7.84.84 0 0 1-.87-.85ZM64.44 251.5h11.91a1.49 1.49 0 0 0 1.3-2.24l-6-10.31a1.48 1.48 0 0 0-2.58 0l-6 10.31a1.49 1.49 0 0 0 1.37 2.24Zm6.76-9.74-.26 5.14h-1.09l-.27-5.14Zm-1.68 6.91a.84.84 0 0 1 .87-.85.85.85 0 1 1 0 1.7.84.84 0 0 1-.87-.85ZM292.48 175.29h11.91a1.5 1.5 0 0 0 1.29-2.25l-5.95-10.31a1.49 1.49 0 0 0-2.59 0l-6 10.31a1.5 1.5 0 0 0 1.34 2.25Zm6.75-9.75-.26 5.14h-1.09l-.27-5.14Zm-1.67 6.91a.84.84 0 0 1 .86-.85.86.86 0 1 1-.86.85Z"
			style={{
				fill: "#f0f0f0"
			}}
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M164.11 88.33h30.94v20.45h-30.94z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M164.11 88.33h31v3.36h-31z"
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M96.5 164.16h30.94v20.45H96.5z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M96.5 164.16h31v3.36h-31z"
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M92.22 309.72h30.94v20.45H92.22z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M92.22 309.72h31v3.36h-31z"
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M330.44 257.02h30.94v20.45h-30.94z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M330.44 257.02h31v3.36h-31z"
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M398.25 283.55h30.94V304h-30.94z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M398.25 283.55h31v3.36h-31z"
		/>
		<path
			style={{
				fill: "#e0e0e0"
			}}
			d="M386.87 130.76h30.94v20.45h-30.94z"
		/>
		<path
			style={{
				fill: "#f0f0f0"
			}}
			d="M386.87 130.76h31v3.36h-31z"
		/>
		<ellipse
			cx={250}
			cy={416.24}
			rx={193.89}
			ry={11.32}
			style={{
				fill: "#f5f5f5"
			}}
		/>
		<path
			d="M213.48 119.42h-28.72a8.85 8.85 0 0 0-8.85 8.85v25a8.84 8.84 0 0 0 8.85 8.84h5.38l-2 7.9 8-7.9h17.4a8.84 8.84 0 0 0 8.84-8.84v-25a8.85 8.85 0 0 0-8.9-8.85Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M213.48 119.42h-28.72a8.85 8.85 0 0 0-8.85 8.85v25a8.84 8.84 0 0 0 8.85 8.84h5.38l-2 7.9 8-7.9h17.4a8.84 8.84 0 0 0 8.84-8.84v-25a8.85 8.85 0 0 0-8.9-8.85Z"
			style={{
				fill: "#fff",
				opacity: 0.7000000000000001
			}}
		/>
		<path
			d="M199.12 155.79a14.8 14.8 0 1 1 14.8-14.8 14.82 14.82 0 0 1-14.8 14.8Zm0-27.6a12.8 12.8 0 1 0 12.8 12.8 12.81 12.81 0 0 0-12.8-12.8Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M196.85 146.71a1 1 0 0 1-.73-.32l-4.36-4.72a1 1 0 0 1 .06-1.42 1 1 0 0 1 1.41.06l3.63 3.94 8.14-8.65a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42l-8.89 9.42a1 1 0 0 1-.67.27Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			style={{
				fill: "#e4897b"
			}}
			d="m163.3 411.28 6.96-.12 1.73-16.08-6.96.12-1.73 16.08z"
		/>
		<path
			style={{
				opacity: 0.2
			}}
			d="m171.99 395.09-.89 8.28-6.96.12.89-8.29 6.96-.11z"
		/>
		<path
			d="M179.62 337.05c0 22-6.19 63.89-6.19 63.89l-11.64 1.81s-1.13-39.06-1.57-60.68c-.07-3.18-8.94-18.56-18.08-33.58-4.71-7.74-9.49-15.4-13.19-21.25-3.95-6.26-6.65-10.46-6.65-10.46l27.63-10.34s29.67 49.43 29.69 70.61Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m175.06 401.62-14.02.89-.24-4.51 15.39-1.21-1.13 4.83z"
		/>
		<path
			d="M170.6 410.19a.18.18 0 0 1 .1-.17c.32-.15 3.17-1.48 4-1a.5.5 0 0 1 .25.45.93.93 0 0 1-.34.79c-.79.64-2.77.31-3.88.05a.16.16 0 0 1-.13-.12Zm.69-.08c1.52.31 2.67.28 3.11-.09a.57.57 0 0 0 .22-.52.18.18 0 0 0-.09-.17h-.06c-.47-.23-2.06.3-3.18.78Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M170.66 410.3a.17.17 0 0 1-.06-.15 4.72 4.72 0 0 1 1.19-2.63 1.22 1.22 0 0 1 .93-.26c.45.07.57.3.59.48.1.79-1.63 2.23-2.48 2.58a.13.13 0 0 1-.12 0Zm2-2.71a.89.89 0 0 0-.68.19 3.83 3.83 0 0 0-1 2.1c.87-.48 2-1.62 2-2.1a.22.22 0 0 0-.15-.15.61.61 0 0 0-.16-.04Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="m170.06 409.34-7.6-.24a.62.62 0 0 0-.61.45l-1.63 6.11a1 1 0 0 0 .95 1.27c2.75 0 6.71 0 10.16.12 4 .13 6.59-.06 11.31.1 2.85.09 3.77-2.76 2.58-3.06-5.39-1.36-8.92-1.11-13.51-4.15a3.19 3.19 0 0 0-1.65-.6Z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="M142.14 308.49c-4.71-7.74-9.49-15.4-13.19-21.25a27.79 27.79 0 0 1 5.09-5s8.07 14.04 8.1 26.25Z"
			style={{
				opacity: 0.2
			}}
		/>
		<path
			style={{
				fill: "#e4897b"
			}}
			d="m84.8 410.44 6.72 1.82 6.14-14.96-6.72-1.82-6.14 14.96z"
		/>
		<path
			style={{
				opacity: 0.2
			}}
			d="m97.65 397.31-3.16 7.71-6.72-1.83 3.16-7.71 6.72 1.83z"
		/>
		<path
			d="m150.63 260.47-.56 9.67-16.76 13.37s-1.15 45-5.92 54.86-27.71 61.5-27.71 61.5L87.14 396s19.93-60.7 21.94-64.29-8.55-55.93 5.59-80.68Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M171.3 217.32c.71 4.51 1.3 9 1.82 13.53s1 9.06 1.28 13.69l.18 3a8.54 8.54 0 0 0 .53 2.15 16 16 0 0 0 2.79 4.53 34.22 34.22 0 0 0 4.33 4.25c.81.66 1.64 1.34 2.5 2s1.78 1.25 2.57 1.79l-2 4.79a32.61 32.61 0 0 1-3.5-1.26c-1.11-.48-2.23-1-3.31-1.56a33.73 33.73 0 0 1-6.26-4.25 22.43 22.43 0 0 1-5.22-6.38 16.48 16.48 0 0 1-1.61-4.28c-.1-.37-.15-.77-.22-1.16l-.14-1-.23-1.63-2-13.32c-.67-4.46-1.3-8.94-1.86-13.41Z"
			style={{
				fill: "#dc897c"
			}}
		/>
		<path
			d="M156.75 205c-3.89 5.4 2.79 26 2.79 26l15.46-3.73a83.82 83.82 0 0 0-3.5-17.71c-2.88-8.31-10.5-10.5-14.75-4.56Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M156.75 205c-3.89 5.4 2.79 26 2.79 26l15.46-3.73a83.82 83.82 0 0 0-3.5-17.71c-2.88-8.31-10.5-10.5-14.75-4.56Z"
			style={{
				fill: "#fff",
				opacity: 0.7000000000000001
			}}
		/>
		<path
			d="M162 216c2.68 2.56 4.23 9.16 3.25 13.61l-5.71 1.39a104.94 104.94 0 0 1-3.54-15.22c1.45-1.2 2.92-2.69 6 .22Z"
			style={{
				opacity: 0.2
			}}
		/>
		<path
			d="M128.4 192.3a155.39 155.39 0 0 1-3.33 23c-1.15 5.29-2.47 10.26-3.77 14.74-3 10.49-6 18.27-6.63 21l36 9.41c17.78-35.69 15.35-58 15.35-58a73.38 73.38 0 0 0-8-3.87c-.58-.24-1.18-.46-1.79-.68a136 136 0 0 0-15.29-4c-1-.21-2-.39-3-.56-5-.79-9.54-1.04-9.54-1.04Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M128.4 192.3a155.39 155.39 0 0 1-3.33 23c-1.15 5.29-2.47 10.26-3.77 14.74-3 10.49-6 18.27-6.63 21l36 9.41c17.78-35.69 15.35-58 15.35-58a73.38 73.38 0 0 0-8-3.87c-.58-.24-1.18-.46-1.79-.68a136 136 0 0 0-15.29-4c-1-.21-2-.39-3-.56-5-.79-9.54-1.04-9.54-1.04Z"
			style={{
				fill: "#fff",
				opacity: 0.7000000000000001
			}}
		/>
		<path
			d="M153.79 200.28c-1.85-3.3.2-6.14 2.67-8.51l-3.85-12.08c-1.86 4.82-6 13.21-10.8 14.21a15.58 15.58 0 0 0 3 6.38Z"
			style={{
				fill: "#e4897b"
			}}
		/>
		<path
			d="m154.18 184.62 2.28 7.14a16.31 16.31 0 0 0-1.9 2.12c-1.68-1.4-2.95-5-1.94-7a7.39 7.39 0 0 1 1.56-2.26Z"
			style={{
				opacity: 0.2
			}}
		/>
		<path
			d="M177.5 180.29c-.19.92-8.38-1-9.65-2.82s2.87-2.76 5.45-1.42a11.86 11.86 0 0 1 4.2 4.24Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M177.5 180.29c-.19.92-8.38-1-9.65-2.82s2.87-2.76 5.45-1.42a11.86 11.86 0 0 1 4.2 4.24Z"
			style={{
				opacity: 0.30000000000000004
			}}
		/>
		<path
			d="M173.68 175.65c0 2.8-2.17 7.88-5.17 5s5.22-9.81 5.17-5Z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="M169.22 187.47a20.34 20.34 0 0 1-1.65 2.41c-4.12 5.19-12.36 4.93-15.43-.9a8.9 8.9 0 0 1-.55-1.21c-1.67-4.63.14-8.25 2.66-14.65A9.48 9.48 0 0 1 170 170c4.28 4.5 2.35 12.22-.78 17.47Z"
			style={{
				fill: "#e4897b"
			}}
		/>
		<path
			d="M158.9 162a9.44 9.44 0 0 0-3.79 2.26c-3.72 3.42-6.43 8.54-1.72 13.71 3.41-1.28 5.09-6 5.51-7.35Z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="M149.42 177.18a6.45 6.45 0 0 0 1.33 4.8c1.43 1.79 3.5.84 4.23-1.2.67-1.84.73-5-1.25-6a3 3 0 0 0-4.31 2.4Z"
			style={{
				fill: "#e4897b"
			}}
		/>
		<path
			d="M162.94 179.18c-.24.5-.16 1 .18 1.19s.79-.14 1-.65.16-1-.18-1.19-.76.14-1 .65ZM168.71 181.8c-.24.5-.16 1 .17 1.19s.8-.14 1-.64.15-1-.18-1.2-.76.14-.99.65Z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="M166.76 181.22a19.25 19.25 0 0 0 .52 5.31 3.33 3.33 0 0 1-2.61-.7Z"
			style={{
				fill: "#de5753"
			}}
		/>
		<path
			d="M161 186.53a6.34 6.34 0 0 0 .64.35.18.18 0 0 0 .22-.08.16.16 0 0 0-.08-.22 4.37 4.37 0 0 1-2.56-3.07.17.17 0 0 0-.33.05 4.56 4.56 0 0 0 2.11 2.97ZM162 176.52a.34.34 0 0 0 .34 0 2.65 2.65 0 0 1 2.38-.13.33.33 0 0 0 .45-.13.32.32 0 0 0-.13-.44 3.3 3.3 0 0 0-3 .12.32.32 0 0 0-.14.44.36.36 0 0 0 .1.14ZM172.11 181a.26.26 0 0 0 .19.05.32.32 0 0 0 .32-.33 3.26 3.26 0 0 0-1.37-2.66.35.35 0 0 0-.46.11.33.33 0 0 0 .11.45 2.55 2.55 0 0 1 1.06 2.11.3.3 0 0 0 .15.27Z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="M173.42 177.47c-4.6-3.57-10.47-5.19-15.06-5.93a43.72 43.72 0 0 0-7.47-.61c.2-2.58 3.5-8.48 5.57-8.91a34.55 34.55 0 0 1 10.44.35c2.42.9 7.23 4.39 7.59 5.86.75 3.07-1.07 9.24-1.07 9.24Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M158.35 171.53s6.1-1.36 11.44.71 7.71 8 7.71 8-.47-1.23-4.88-2.82c-4.89-1.71-9.03-1.74-14.27-5.89Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M166.9 162.37s-5.45 2.35-5.83 2.73-2.71 6.44-2.71 6.44a43.72 43.72 0 0 0-7.47-.61c.2-2.58 3.5-8.48 5.57-8.91a34.55 34.55 0 0 1 10.44.35Z"
			style={{
				opacity: 0.1
			}}
		/>
		<path
			d="M158.35 171.53s6.1-1.36 11.44.71 7.71 8 7.71 8-.47-1.23-4.88-2.82c-4.89-1.71-9.03-1.74-14.27-5.89Z"
			style={{
				opacity: 0.1
			}}
		/>
		<path
			d="M169.22 187.47a20.34 20.34 0 0 1-1.65 2.41c-4.12 5.19-12.36 4.93-15.43-.9a21.74 21.74 0 0 1 3.26-12.41l1.06-1.14s-1.32 5.09-.93 6.09 3.22-.28 4.53.55a5 5 0 0 1 1.82 2.55l-.81.51s-1.59-2.54-2.17-2.61-3.35 2.46-3.1 3.33-.17 1.31.66 1.7 2.85-.94 2.85-.94l1.1 1s-1.76 1.88-1.49 2.26a3.67 3.67 0 0 0 3.28.56c1.26-.51 1.21-3.26 1.45-3.54s-1.94-1.23-1.94-1.23l.67-1s2.75 1.75 2.85 2.27-.44 2.1 0 2.1a10.41 10.41 0 0 0 3.99-1.56Z"
			style={{
				opacity: 0.30000000000000004
			}}
		/>
		<path
			d="m171.65 171.18-.15-.59.43-.42 1 .26a1.13 1.13 0 0 0-.8-.84 1.09 1.09 0 0 0-1.07.28.35.35 0 0 0-.14-.06l-2.64-.75h-.14a1.12 1.12 0 0 0-1.9-.47l1 .27.15.58-.43.42-1-.26a1.14 1.14 0 0 0 .8.84 1.11 1.11 0 0 0 1.07-.27h.14l2.64.75a.34.34 0 0 0 .14 0 1.12 1.12 0 0 0 1.9.47Z"
			style={{
				fill: "#fff"
			}}
		/>
		<path
			d="m186.06 261.64 6.74 2.15-5.37 5.72s-5.9-2.28-4.6-5.27Z"
			style={{
				fill: "#e4897b"
			}}
		/>
		<path
			style={{
				fill: "#e4897b"
			}}
			d="m196.21 271.23-2.02 3.65-6.76-5.37 5.37-5.72 3.41 7.44z"
		/>
		<path
			d="M128 206.7c-3.09 3.54-6.24 7.2-9.23 10.84-1.52 1.81-3 3.66-4.35 5.48a47 47 0 0 0-3.58 5.35 9.27 9.27 0 0 0-.48 1c0 .1-.07.18-.06.09s0 0 0-.09v-.45a4.25 4.25 0 0 0-.38-1.37 5.58 5.58 0 0 0-.32-.56c-.12-.17-.27-.34-.35-.44s-.22-.21-.33-.31l-.26-.2a2.53 2.53 0 0 0-.39-.22l-.22-.1h-.11c-.08 0 0 0 .15.06a7.12 7.12 0 0 0 1.22.18c1 .08 2.11.1 3.24.08s2.3 0 3.48-.09c4.7-.22 9.54-.58 14.35-.87l1 5.11a113.35 113.35 0 0 1-14.44 3.81 52.56 52.56 0 0 1-7.82 1 18.51 18.51 0 0 1-2.41-.07 9.4 9.4 0 0 1-1.63-.32 4.25 4.25 0 0 1-.54-.18c-.2-.08-.38-.14-.64-.27a5.18 5.18 0 0 1-.78-.47 3.55 3.55 0 0 1-.43-.33c-.18-.15-.34-.31-.5-.47a5.16 5.16 0 0 1-.49-.62 4.47 4.47 0 0 1-.43-.75 5.2 5.2 0 0 1-.49-1.81 3.55 3.55 0 0 1 0-.46v-.45a4.26 4.26 0 0 1 0-.54 10.12 10.12 0 0 1 .33-1.69 18.62 18.62 0 0 1 .88-2.4 48 48 0 0 1 4.07-7C107.5 215 109 213 110.52 211c3.08-4 6.24-7.69 9.65-11.38Z"
			style={{
				fill: "#dc897c"
			}}
		/>
		<path
			d="M126.28 192.38c-6.58 1-16.9 18-16.9 18l14.28 9.28a96.41 96.41 0 0 0 9.67-14.66c4.31-8.28.91-13.88-7.05-12.62Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M126.28 192.38c-6.58 1-16.9 18-16.9 18l14.28 9.28a96.41 96.41 0 0 0 9.67-14.66c4.31-8.28.91-13.88-7.05-12.62Z"
			style={{
				fill: "#fff",
				opacity: 0.7000000000000001
			}}
		/>
		<path
			d="m140.86 193.93 3.52-2.56 6.56 8.78 2.53-4.39 2.68 2.17a8 8 0 0 1 0 8l-5.26-4.31-5.66 3.63s-6.02-7.01-4.37-11.32ZM132.9 180.92a18.14 18.14 0 1 1-30.82 3.83l9.91 12.6 9.66-1.39 3.63-9.06-9.91-12.6a18.1 18.1 0 0 1 17.53 6.62ZM203 328.64a18.13 18.13 0 1 1 30.82-3.83l-9.91-12.6-9.66 1.39-3.63 9.07 9.91 12.59a18.1 18.1 0 0 1-17.53-6.62Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<g
			style={{
				opacity: 0.2
			}}
		>
			<path
				d="M132.9 180.92a18.14 18.14 0 1 1-30.82 3.83l9.91 12.6 9.66-1.39 3.63-9.06-9.91-12.6a18.1 18.1 0 0 1 17.53 6.62ZM203 328.64a18.13 18.13 0 1 1 30.82-3.83l-9.91-12.6-9.66 1.39-3.63 9.07 9.91 12.59a18.1 18.1 0 0 1-17.53-6.62Z"
				style={{
					fill: "#fff"
				}}
			/>
		</g>
		<path
			d="M212.09 310.93a9.57 9.57 0 0 1-13.44-1.61l-76.49-97.24a9.57 9.57 0 0 1 1.6-13.44 9.57 9.57 0 0 1 13.44 1.6l76.5 97.25a9.57 9.57 0 0 1-1.61 13.44Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M212.09 310.93a9.57 9.57 0 0 1-13.44-1.61l-76.49-97.24a9.57 9.57 0 0 1 1.6-13.44 9.57 9.57 0 0 1 13.44 1.6l76.5 97.25a9.57 9.57 0 0 1-1.61 13.44Z"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
		/>
		<path
			d="M207.61 305.23a4.91 4.91 0 0 1-6.9-.82l-73.29-93.17a4.93 4.93 0 0 1 .82-6.91 4.94 4.94 0 0 1 6.91.83l73.29 93.17a4.92 4.92 0 0 1-.83 6.9Z"
			style={{
				opacity: 0.2
			}}
		/>
		<path
			d="m128.81 226.31 5.24-4.71 2.59 8.53s-5.34 3-7.24.32Z"
			style={{
				fill: "#e4897b"
			}}
		/>
		<path
			style={{
				fill: "#e4897b"
			}}
			d="m139.8 221.2 1.82 7.14-4.98 1.79-2.6-8.53 5.76-.4z"
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m98.66 403.45-13.24-4.69 1.55-4.25 14.63 4.94-2.94 4z"
		/>
		<path
			d="M92.55 410.19a.18.18 0 0 1 .1-.17c.32-.15 3.17-1.48 4-1a.5.5 0 0 1 .25.45.93.93 0 0 1-.34.79c-.79.64-2.77.31-3.88.05a.16.16 0 0 1-.13-.12Zm.69-.08c1.52.31 2.67.28 3.11-.09a.57.57 0 0 0 .22-.52.18.18 0 0 0-.09-.17h-.06c-.52-.23-2.06.3-3.18.78Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M92.62 410.3a.18.18 0 0 1-.07-.15 4.72 4.72 0 0 1 1.19-2.63 1.22 1.22 0 0 1 .93-.26c.45.07.57.3.59.48.1.79-1.63 2.23-2.48 2.58a.13.13 0 0 1-.12 0Zm2-2.71a.89.89 0 0 0-.68.19 3.83 3.83 0 0 0-1 2.1c.87-.48 2-1.62 2-2.1a.22.22 0 0 0-.15-.15.61.61 0 0 0-.17-.04Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="m92 409.34-7.6-.24a.62.62 0 0 0-.61.45l-1.63 6.11a1 1 0 0 0 1 1.27c2.75 0 6.71 0 10.16.12 4 .13 6.59-.06 11.31.1 2.85.09 3.77-2.76 2.58-3.06-5.39-1.36-8.92-1.11-13.51-4.15a3.19 3.19 0 0 0-1.7-.6ZM113.47 251.55l9.85 2.66-.88 3.61-10.44-2.51 1.47-3.76zM124.44 254.5l23.25 6.27-.54 3.02-23.71-5.72 1-3.57zM150.96 261.65l-.33 2.97-2.7-.64.61-2.98 2.42.65z"
			style={{
				fill: "#263238"
			}}
		/>
		<path
			d="m133.94 257.3-.62 2.92a.47.47 0 0 0 .35.56l5.48 1.29a.48.48 0 0 0 .57-.37l.57-2.75a.47.47 0 0 0-.34-.55l-5.42-1.46a.48.48 0 0 0-.59.36Z"
			style={{
				fill: "#fff"
			}}
		/>
		<path
			d="m160.6 220.84-.15-.58.43-.43 1 .27a1.13 1.13 0 0 0-1.88-.57l-.13-.06-2.64-.75h-.14a1.13 1.13 0 0 0-.79-.78 1.11 1.11 0 0 0-1.11.32l.95.26.16.58-.43.43-1-.27a1.11 1.11 0 0 0 1.87.57.75.75 0 0 0 .13.06l2.65.75h.14a1.12 1.12 0 0 0 1.9.47ZM269.71 413.46v3a.68.68 0 0 0 .68.68h85.15a4.44 4.44 0 0 0 4.11-6.1l-27-66.82-43.6.3 1.49 8.67 9.62 56.12a3 3 0 0 1-2.92 3.47h-26.85a.67.67 0 0 0-.68.68Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M269.71 413.46v3a.68.68 0 0 0 .68.68h85.15a4.44 4.44 0 0 0 4.11-6.1l-16.72-41.42-10.26-25.4-43.6.3 1.49 8.67 4.18 24.4 5.44 31.72a3 3 0 0 1-2.92 3.47h-26.87a.67.67 0 0 0-.68.68Z"
			style={{
				fill: "#fff",
				opacity: 0.6000000000000001
			}}
		/>
		<path
			d="M297.26 412.79h55.39a2 2 0 0 0 1.8-2.69l-23.66-56.9h-40.23l9.62 56.12a3 3 0 0 1-2.92 3.47Z"
			style={{
				isolation: "isolate",
				opacity: 0.2
			}}
		/>
		<path
			style={{
				isolation: "isolate",
				opacity: 0.1
			}}
			d="m289.07 344.53 1.49 8.67 4.18 24.4 48.19-7.97-10.26-25.4-43.6.3z"
		/>
		<path
			d="M204.29 220h201.86a5.16 5.16 0 0 1 5.16 5.17v136.61a5.17 5.17 0 0 1-5.16 5.17H204.29a5.17 5.17 0 0 1-5.17-5.17V225.13a5.16 5.16 0 0 1 5.17-5.13Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="M204.29 220h201.86a5.16 5.16 0 0 1 5.16 5.17v136.61a5.17 5.17 0 0 1-5.16 5.17H204.29a5.17 5.17 0 0 1-5.17-5.17V225.13a5.16 5.16 0 0 1 5.17-5.13Z"
			style={{
				fill: "#fff",
				opacity: 0.7000000000000001
			}}
		/>
		<path
			d="M401.87 366.94h4.28a5.16 5.16 0 0 0 5.16-5.16V225.13a5.16 5.16 0 0 0-5.16-5.17h-4.28a5.16 5.16 0 0 1 5.16 5.17v136.65a5.16 5.16 0 0 1-5.16 5.16Z"
			style={{
				isolation: "isolate",
				opacity: 0.2
			}}
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="M203.77 225.02h198.6v131.77h-198.6z"
		/>
		<path
			style={{
				fill: "#407bff"
			}}
			d="M203.77 225.02h198.6v10.11h-198.6z"
		/>
		<path
			d="M397.73 230.44a2.47 2.47 0 1 1-2.47-2.47 2.48 2.48 0 0 1 2.47 2.47ZM389.57 230.44a2.47 2.47 0 1 1-2.47-2.44 2.47 2.47 0 0 1 2.47 2.44ZM381.42 230.44A2.47 2.47 0 1 1 379 228a2.48 2.48 0 0 1 2.42 2.44Z"
			style={{
				opacity: 0.2
			}}
		/>
		<path
			style={{
				fill: "#fff",
				opacity: 0.4
			}}
			d="M203.77 235.18h198.6v34.21h-198.6z"
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m208.32 236.7-4.55 8.15v-8.15h4.55zM232.85 236.7l-17.43 31.28h-11.65v-3.83l15.3-27.45h13.78zM239.88 268.11H226.1l17.51-31.42h13.77l-17.5 31.42zM264.41 268.11h-13.78l17.51-31.42h13.78l-17.51 31.42zM288.94 268.11h-13.78l17.51-31.42h13.78l-17.51 31.42zM313.47 268.11H299.7l17.5-31.42h13.78l-17.51 31.42zM338.01 267.88h-13.78l17.51-31.42h13.77l-17.5 31.42zM362.54 267.88h-13.78l17.51-31.42h13.78l-17.51 31.42zM402.37 236.59v3.84l-15.3 27.45h-13.78l17.44-31.29h11.64zM402.37 259.96v8.02h-4.47l4.47-8.02z"
		/>
		<path
			style={{
				fill: "#fff",
				opacity: 0.4
			}}
			d="M203.77 322.45h198.6v34.21h-198.6z"
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m208.32 324.25-4.55 8.08v-8.08h4.55zM232.85 324.25l-17.43 31.02h-11.65v-3.8l15.3-27.22h13.78zM239.88 355.4H226.1l17.51-31.15h13.77l-17.5 31.15zM264.41 355.4h-13.78l17.51-31.15h13.78l-17.51 31.15zM288.94 355.4h-13.78l17.51-31.15h13.78l-17.51 31.15zM313.47 355.4H299.7l17.5-31.15h13.78l-17.51 31.15zM338.01 355.17h-13.78l17.51-31.15h13.77l-17.5 31.15zM362.54 355.17h-13.78l17.51-31.15h13.78l-17.51 31.15zM402.37 324.14v3.8l-15.3 27.22h-13.78l17.44-31.02h11.64zM402.37 347.31v7.96h-4.47l4.47-7.96z"
		/>
		<path
			d="M270.17 301v2.85h-13.7v-18.27h13.34v2.84h-9.94v4.75h8.82V296h-8.82v5ZM285.91 303.85l-3.73-5.35h-4.83v5.33H274v-18.25h7.52c4.8 0 7.78 2.45 7.78 6.5a5.85 5.85 0 0 1-3.89 5.77l4.2 6Zm-4.6-15.4h-4v7.28h4c3 0 4.52-1.36 4.52-3.65s-1.54-3.63-4.52-3.63ZM305 303.85l-3.73-5.35h-4.83v5.33H293v-18.25h7.51c4.81 0 7.78 2.45 7.78 6.5a5.85 5.85 0 0 1-3.89 5.77l4.2 6Zm-4.59-15.4h-4v7.28h4c3 0 4.51-1.36 4.51-3.65s-1.56-3.63-4.53-3.63ZM310.79 294.71c0-5.4 4.18-9.39 9.84-9.39s9.84 4 9.84 9.39-4.17 9.4-9.84 9.4-9.84-3.99-9.84-9.4Zm16.26 0a6.42 6.42 0 1 0-6.42 6.42 6.23 6.23 0 0 0 6.42-6.42ZM346 303.85l-3.73-5.35h-4.83v5.33h-3.39v-18.25h7.52c4.8 0 7.77 2.45 7.77 6.5a5.85 5.85 0 0 1-3.89 5.77l4.21 6Zm-4.59-15.4h-4v7.28h4c3 0 4.51-1.36 4.51-3.65s-1.52-3.63-4.49-3.63Z"
			style={{
				fill: "#fff"
			}}
		/>
		<path
			d="M365.61 306.73h21.51a2.7 2.7 0 0 0 2.34-4.05l-10.76-18.63a2.69 2.69 0 0 0-4.67 0l-10.76 18.63a2.7 2.7 0 0 0 2.34 4.05Zm12.2-17.6-.47 9.28h-2l-.49-9.28Zm-3 12.47a1.5 1.5 0 0 1 1.56-1.52 1.53 1.53 0 0 1 1.63 1.52 1.55 1.55 0 0 1-1.61 1.55 1.52 1.52 0 0 1-1.61-1.55ZM219 306.73h21.51a2.7 2.7 0 0 0 2.33-4.05l-10.75-18.63a2.69 2.69 0 0 0-4.67 0l-10.76 18.63a2.7 2.7 0 0 0 2.34 4.05Zm12.2-17.6-.48 9.28h-2l-.49-9.28Zm-3 12.47a1.5 1.5 0 0 1 1.56-1.52 1.53 1.53 0 0 1 1.61 1.52 1.55 1.55 0 0 1-1.61 1.55 1.52 1.52 0 0 1-1.56-1.55Z"
			style={{
				fill: "#fff",
				opacity: 0.4
			}}
		/>
		<path
			d="M224.47 225c27.46 27.51 98 88.73 177.9 84.21V225ZM203.77 275.58v81.22h119.57c-4.59-16.41-13.75-38.87-31.53-54.92-31.99-28.88-69.11-28.8-88.04-26.3Z"
			style={{
				fill: "#fff",
				opacity: 0.1
			}}
		/>
		<path
			d="m374 411 3.56 1.56a5.43 5.43 0 0 1-5.7 1.19 5 5 0 0 1-2.73-6 4.34 4.34 0 0 1 1.3-2.11l-9.68-24.73c-3.48.14-4.95-3.12-4.87-5.36l3.54 1.57a1.64 1.64 0 1 0 1.3-3l-3.56-1.56a5.42 5.42 0 0 1 5.7-1.19 5.06 5.06 0 0 1 2.87 5.95 3.88 3.88 0 0 1-1.16 2.05l9.68 24.74c2.71.15 4.81 3.17 4.73 5.41l-3.61-1.52a1.68 1.68 0 0 0-2.2.86A1.65 1.65 0 0 0 374 411Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="m374 411 3.56 1.56a5.43 5.43 0 0 1-5.7 1.19 5 5 0 0 1-2.73-6 4.34 4.34 0 0 1 1.3-2.11l-9.68-24.73c-3.48.14-4.95-3.12-4.87-5.36l3.54 1.57a1.64 1.64 0 1 0 1.3-3l-3.56-1.56a5.42 5.42 0 0 1 5.7-1.19 5.06 5.06 0 0 1 2.87 5.95 3.88 3.88 0 0 1-1.16 2.05l9.68 24.74c2.71.15 4.81 3.17 4.73 5.41l-3.61-1.52a1.68 1.68 0 0 0-2.2.86A1.65 1.65 0 0 0 374 411Z"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
		/>
		<path
			d="m346.39 389.14-3.77.94a5.4 5.4 0 0 1 3.79-4.42 5 5 0 0 1 5.83 3.09 4.32 4.32 0 0 1 .25 2.47l22.76 13.69c2.67-2.24 5.82-.55 7.13 1.27l-3.76.92a1.63 1.63 0 0 0-1.2 2 1.65 1.65 0 0 0 2 1.19l3.78-.94a5.4 5.4 0 0 1-3.79 4.42 5.06 5.06 0 0 1-5.91-3 4 4 0 0 1-.34-2.33l-22.75-13.7c-2.25 1.54-5.75.43-7.05-1.4l3.8-1a1.69 1.69 0 0 0 1.22-2 1.65 1.65 0 0 0-1.99-1.2Z"
			style={{
				fill: "#407bff"
			}}
		/>
		<path
			d="m346.39 389.14-3.77.94a5.4 5.4 0 0 1 3.79-4.42 5 5 0 0 1 5.83 3.09 4.32 4.32 0 0 1 .25 2.47l22.76 13.69c2.67-2.24 5.82-.55 7.13 1.27l-3.76.92a1.63 1.63 0 0 0-1.2 2 1.65 1.65 0 0 0 2 1.19l3.78-.94a5.4 5.4 0 0 1-3.79 4.42 5.06 5.06 0 0 1-5.91-3 4 4 0 0 1-.34-2.33l-22.75-13.7c-2.25 1.54-5.75.43-7.05-1.4l3.8-1a1.69 1.69 0 0 0 1.22-2 1.65 1.65 0 0 0-1.99-1.2Z"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m422.89 377.76.06-.16h-35.17l-8.25 21.79h35.02l-.02.06h2.89l7.42-19.43-1.95-2.26z"
		/>
		<path
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
			d="m422.89 377.76.06-.16h-35.17l-8.25 21.79h35.02l-.02.06h2.89l7.42-19.43-1.95-2.26z"
		/>
		<path
			style={{
				isolation: "isolate",
				opacity: 0.4
			}}
			d="m422.95 377.6-8.25 21.79h-35.17l8.25-21.79h35.17z"
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m361.9 392.85 8.14 10.46 2.92-.44-8.59-10.02h-2.47z"
		/>
		<path
			style={{
				fill: "#fff",
				opacity: 0.1
			}}
			d="m361.9 392.85 8.14 10.46 2.92-.44-8.59-10.02h-2.47z"
		/>
		<path
			style={{
				fill: "#263238"
			}}
			d="m364.37 382.96 8.14 10.46 2.92-.44-8.59-10.02h-2.47z"
		/>
		<path
			style={{
				fill: "#fff",
				opacity: 0.1
			}}
			d="m364.37 382.96 8.14 10.46 2.92-.44-8.59-10.02h-2.47z"
		/>
		<path
			transform="rotate(180 384.29 408.27)"
			style={{
				fill: "#263238"
			}}
			d="M353.88 399.39h60.82v17.76h-60.82z"
		/>
		<path
			transform="rotate(180 384.29 408.27)"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
			d="M353.88 399.39h60.82v17.76h-60.82z"
		/>
		<path
			transform="rotate(180 388.415 392.55)"
			style={{
				fill: "#263238"
			}}
			d="M360.48 391.14h55.87v2.81h-55.87z"
		/>
		<path
			transform="rotate(180 388.415 392.55)"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
			d="M360.48 391.14h55.87v2.81h-55.87z"
		/>
		<path
			transform="rotate(180 405.625 392.55)"
			style={{
				isolation: "isolate",
				opacity: 0.2
			}}
			d="M394.9 391.14h21.45v2.81H394.9z"
		/>
		<path
			transform="rotate(180 391.71 384.3)"
			style={{
				fill: "#263238"
			}}
			d="M363.77 382.9h55.87v2.81h-55.87z"
		/>
		<path
			transform="rotate(180 391.71 384.3)"
			style={{
				fill: "#fff",
				opacity: 0.2
			}}
			d="M363.77 382.9h55.87v2.81h-55.87z"
		/>
		<path
			transform="rotate(180 408.925 384.3)"
			style={{
				isolation: "isolate",
				opacity: 0.2
			}}
			d="M398.2 382.9h21.45v2.81H398.2z"
		/>
		<path
			transform="rotate(180 402.74 408.27)"
			style={{
				isolation: "isolate",
				opacity: 0.2
			}}
			d="M390.78 399.39h23.92v17.76h-23.92z"
		/>
	</svg>
);

export default memo(ComputerTroubleshootingRafiki);
