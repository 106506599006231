import classes from "./loader.module.scss";
import { Spinner } from "@geist-ui/core";

const PageLoader = () => (
	<div className={classes.preloader}>
		<div className={classes.preloaderContainer}>
			<Spinner className="!h-8" />
		</div>
	</div>
);

export default PageLoader;
