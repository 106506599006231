import { useQuery } from "@tanstack/react-query";
import { ArrowRight, Plus } from "lucide-react";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import classes from "./Style.module.scss";
import { Button, Col, Form, Row, Spin, Tag, Space } from "antd";
import { get } from "lodash";
import { ISelect } from "types";
import { ColumnsType } from "antd/es/table";
import { dtoToSelect } from "utils/utils";
import { req } from "services/api";
import { useInformationApplicationStatusFmoQ, useInformationApplicationTypesFmoQ } from "hooks/queries/general";
import useAutoReducer from "hooks/useAutoReducer";
import { StatusTag } from "components/UI";
import { Input, Select, Pagination, Table } from "components/shared";

interface IDsi {
	id: number;
	tin: number;
	pinfl: number;
	name: string;
	cashboxName: string;
	terminalId: string;
	serialNumber: string;
	applicationTypeId: number;
	saleName: string;
	saleAddress: string;
	status: number;
	createdDate: string;
}

const SIZE = 20;

interface IFilter {
	page: number;
	tin?: string;
	status?: string;
	terminalId?: string;
	applicationTypeId?: string;
}

const filterDefaultValues = {
	page: 1,
	tin: undefined,
	status: undefined,
	terminalId: undefined,
	applicationTypeId: undefined,
};

function ApplicationDSI() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [filter, dispatch] = useAutoReducer<IFilter>(filterDefaultValues);

	const { data: typesData, isLoading: typesLoading } = useInformationApplicationTypesFmoQ({});
	const { data: statusData, isLoading: statusLoading } = useInformationApplicationStatusFmoQ({});

	const typeOptions = useMemo(() => dtoToSelect(typesData), [typesData]);
	const statusOptions = useMemo(() => dtoToSelect(statusData), [statusData]);

	const params = {
		tin: filter.tin,
		pageNo: filter.page - 1,
		status: filter.status,
		pageSize: SIZE,
		terminalId: filter.terminalId,
		applicationTypeId: filter.applicationTypeId,
	};

	const queryDsi = useQuery({
		queryKey: ["queryDsi", filter],
		queryFn: () => req("reestr")({ url: `/application-fmo/index`, params }),
	});

	const total = get(queryDsi, "data.data.recordsTotal", 0);
	const data = get(queryDsi, "data.data.data") as IDsi[];

	const columns: ColumnsType<IDsi> = [
		{
			title: "№",
			dataIndex: "IDX",
			align: "center",
			render(_, __, index) {
				return (filter.page - 1) * SIZE + index + 1;
			},
		},
		{ title: t(`Фискал модул рақами`), dataIndex: "terminalId" },
		{ title: t("СТИР"), dataIndex: "tin" },
		{ title: t("ЖШШИР"), dataIndex: "pinfl" },
		{ title: t("Корхона номи"), dataIndex: "name" },
		// { title: t("Фискал модул рақами"), dataIndex: "terminalId" },
		{ title: t("Савдо шохобчаси номи"), dataIndex: "saleName" },
		{ title: t("Савдо шохобча манзили"), dataIndex: "saleAddress" },
		{ title: t("Яратилган сана"), dataIndex: "createdDate" },
		{
			title: t("Status"),
			dataIndex: "status",
			align: "center",
			render: (status, record) => <StatusTag status={status} name={get(record, "statusName", "")} />,
		},
		{
			title: t("Ариза тури"),
			dataIndex: "applicationTypeName",
			align: "center",
			render: (type) =>
				type ? (
					<Tag className="w-full text-center" color="blue">
						{type}
					</Tag>
				) : (
					"-"
				),
		},
		{
			title: " ",
			dataIndex: "IDX",
			render(_, record) {
				return (
					<Button
						className="!shadow-none !w-9 !h-9 !p-0 flex items-center justify-center"
						onClick={() => navigate(`/application/dsi/${record.id}`)}
					>
						<ArrowRight size={20} strokeWidth={1.5} />
					</Button>
				);
			},
		},
	];

	const filterClear = () => {
		dispatch({ type: "applicationTypeId", param: filterDefaultValues.applicationTypeId });
		dispatch({ type: "status", param: filterDefaultValues.status });
		dispatch({ type: "terminalId", param: filterDefaultValues.terminalId });
		dispatch({ type: "tin", param: filterDefaultValues.tin });
	};

	const onFinish = (values: IFilter) => {
		dispatch({ type: "applicationTypeId", param: values.applicationTypeId });
		dispatch({ type: "status", param: values.status });
		dispatch({ type: "terminalId", param: values.terminalId });
		dispatch({ type: "tin", param: values.tin });
	};

	return (
		<div className={classes.table}>
			<Button
				type="primary"
				className="flex items-center justify-center !gap-4 mb-40"
				onClick={() => navigate(`/add-application`)}
			>
				<Plus strokeWidth={1.5} size={20} />
				{t("Ариза жўнатиш")}
			</Button>

			<div className={classes.filter_box}>
				<Form onFinish={onFinish}>
					<Row gutter={[20, 20]}>
						<Col span={4}>
							<Form.Item name={"terminalId"} rules={[{ len: 14, message: t("Длина должна быть 14 символов") }]}>
								<Input size="middle" label={t("Фискал модул рақами")} />
							</Form.Item>
						</Col>

						<Col span={4}>
							<Form.Item
								name={"tin"}
								rules={[
									{
										required: false,
										type: "string",
										min: 9,
										max: 14,
										pattern: /^[0-9]+$/, // Позволяет только цифры
										message: t("Длина должна быть либо 9, либо 14 цифр"),
									},
								]}
							>
								<Input type="number" label={t("СТИР/ЖШШИР")} />
							</Form.Item>
						</Col>

						<Col span={4}>
							<Form.Item name={"applicationTypeId"}>
								<Select size="small" label={t("Ариза тури")} loading={typesLoading} options={typeOptions} />
							</Form.Item>
						</Col>

						<Col span={4}>
							<Form.Item name={"status"}>
								<Select size="small" label={t("Xolati")} loading={statusLoading} options={statusOptions} />
							</Form.Item>
						</Col>

						<Col>
							<Space>
								<Button htmlType="reset" onClick={filterClear}>
									{t("Тозалаш")}
								</Button>

								<Button type="primary" htmlType="submit">
									{t("Қидириш")}
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</div>

			<Spin spinning={queryDsi.isLoading}>
				<Table columns={columns} dataSource={data} />

				<div className="!h-[20px]" />

				<Pagination
					pageSize={SIZE}
					total={total}
					current={filter.page}
					onChange={(page) => dispatch({ param: page, type: "page" })}
				/>
			</Spin>
		</div>
	);
}

export default ApplicationDSI;
