export default function Close(props: any) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.4449 15.8893C17.8893 16.3338 17.8893 17.0004 17.4449 17.4449C17.2227 17.6671 17.0004 17.7782 16.6671 17.7782C16.3338 17.7782 16.1115 17.6671 15.8893 17.4449L10.0004 11.556L4.11155 17.4449C3.88932 17.6671 3.6671 17.7782 3.33377 17.7782C3.00043 17.7782 2.77821 17.6671 2.55599 17.4449C2.11155 17.0004 2.11155 16.3338 2.55599 15.8893L8.44488 10.0004L2.55599 4.11155C2.11155 3.6671 2.11155 3.00043 2.55599 2.55599C3.00043 2.11155 3.6671 2.11155 4.11155 2.55599L10.0004 8.44488L15.8893 2.55599C16.3338 2.11155 17.0004 2.11155 17.4449 2.55599C17.8893 3.00043 17.8893 3.6671 17.4449 4.11155L11.556 10.0004L17.4449 15.8893Z"
				fill="black"
			/>
			<mask
				id="mask0_3_86131"
				maskUnits="userSpaceOnUse"
				x="2"
				y="2"
				width="16"
				height="16"
			>
				<path
					d="M17.4449 15.8893C17.8893 16.3338 17.8893 17.0004 17.4449 17.4449C17.2227 17.6671 17.0004 17.7782 16.6671 17.7782C16.3338 17.7782 16.1115 17.6671 15.8893 17.4449L10.0004 11.556L4.11155 17.4449C3.88932 17.6671 3.6671 17.7782 3.33377 17.7782C3.00043 17.7782 2.77821 17.6671 2.55599 17.4449C2.11155 17.0004 2.11155 16.3338 2.55599 15.8893L8.44488 10.0004L2.55599 4.11155C2.11155 3.6671 2.11155 3.00043 2.55599 2.55599C3.00043 2.11155 3.6671 2.11155 4.11155 2.55599L10.0004 8.44488L15.8893 2.55599C16.3338 2.11155 17.0004 2.11155 17.4449 2.55599C17.8893 3.00043 17.8893 3.6671 17.4449 4.11155L11.556 10.0004L17.4449 15.8893Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_3_86131)">
				<rect x="2.22266" y="2.22266" width="15.5556" height="15.5556" fill="#E9573F" />
			</g>
		</svg>
	);
}
