import { initReactI18next } from "react-i18next";
import { config } from "config";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationRu from "assets/locales/ru/translation.json";
import translationUz from "assets/locales/uz/translation.json";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		keySeparator: "|",
		resources: {
			uz: { translation: translationUz },
			ru: { translation: translationRu },
		},
		fallbackLng: config.languages,
		detection: {
			order: ["localStorage"],
		},
		react: {
			useSuspense: false,
		},
		supportedLngs: config.languages,
	});

export default i18n;

export const getLocaleName = (name: string) => (i18n.language ? `${name}_${i18n.language}` : `${name}Ru`);
export const getLocaleNameUzRu = (name: string) => (i18n.language === "Ru" ? `${name}Ru` : `${name}Uz`);
