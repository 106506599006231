import { useMutation, useQuery } from "@tanstack/react-query";
import { Plus, UploadIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { Description, Input } from "@geist-ui/core";
import { Button, Modal, message, Input as AntdInput, Upload, Spin } from "antd";
import { get, isEmpty } from "lodash";
import FilterBtn from "pages/Application/Txkm/TxkmFilterBtn";
import { req } from "services/api";

interface IEmployeeForm {
	pinfl: number;
	phone: string;
	description: string;
	file: string;
}

function AddTxkmEmployeeModal() {
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const { control, register, handleSubmit, watch, setValue, resetField } = useForm<IEmployeeForm>();

	const mutateAddUser = useMutation({
		mutationFn: (data: FormData) => {
			return req("reestr")({
				method: "POST",
				url: `/applications-cto/add-employee`,
				data: data,
			});
		},
	});

	const onSubmit = async (values: IEmployeeForm) => {
		if (!values) {
			return message.error(t(`Маълумотлар топилмади`));
		}

		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (key === "phone") {
				return formData.append(key, `998${value.replaceAll(" ", "")}`);
			}
			formData.append(key, value);
		});

		const res = await mutateAddUser.mutateAsync(formData);
		const success = get(res, "data.success", false);
		if (!success) {
			const errorCode = get(res, "data.code", "-");

			message.error(t(errorCode));
		} else {
			message.success(t(`Qo'shildi`));
			setIsOpen(false);
		}
	};

	const pinfl = watch("pinfl");
	const pinflLength = String(pinfl).trim().length;

	const queryUserByPinfl = useQuery({
		queryKey: ["queryUser", pinfl],
		queryFn: () => {
			return req("reestr")({
				url: `/information/get-physical-person-by-pinfl/${pinfl}`,
			});
		},
		enabled: pinflLength === 14,
	});

	const userByPinfl = get(queryUserByPinfl, "data.data.data", {}) as {
		fio: string;
		pinfl: string;
	};

	return (
		<>
			<Button
				type="primary"
				onClick={() => setIsOpen(true)}
				className="truncate !shadow-none !px-3 !gap-3 !h-9 !p-0 flex items-center justify-center"
			>
				<Plus size={20} strokeWidth={1.5} /> {t("Xodim Қўшиш")}
			</Button>

			<Modal open={isOpen} onCancel={() => setIsOpen(false)} title={t("Xodim Қўшиш")} footer={false}>
				<div className="h-[20px]" />
				<div className="flex flex-col gap-5">
					<div className="flex flex-col gap-5">
						<div className="col-span-2">
							<Description
								title={t("ЖШШИР")}
								content={
									<Controller
										control={control}
										name="pinfl"
										render={({ field }) => {
											return (
												<>
													<PatternFormat
														placeholder="..."
														format="##############"
														mask={" "}
														customInput={AntdInput}
														{...field}
													/>

													{(() => {
														if (pinflLength !== 14) {
															return null;
														}

														if (queryUserByPinfl.isLoading) {
															return <Spin spinning={true} size="small" />;
														}

														if (isEmpty(userByPinfl)) {
															return <>{t("User topilmadi!")}</>;
														}

														return (
															<div className="!pt-3">
																{t("Ф.И.Ш.")}: {userByPinfl.fio}
															</div>
														);
													})()}
												</>
											);
										}}
									/>
								}
							/>
						</div>
						<div className="h-[20px]" />
						<div className="col-span-2">
							<Description
								title={t("xodim tel raqami")}
								content={
									<Controller
										control={control}
										name="phone"
										render={({ field }) => {
											return (
												<PatternFormat
													format="## ### ## ##"
													mask={" "}
													placeholder="..."
													customInput={AntdInput}
													addonBefore="+998"
													{...field}
												/>
											);
										}}
									/>
								}
							/>
						</div>
					</div>
					<div className="h-[20px]" />
					<div className="col-span-2">
						<Description
							title={t("Қўшимча изоҳ")}
							content={
								<Controller
									control={control}
									name="description"
									render={({ field }) => {
										return <Input placeholder="..." width={"100%"} {...field} />;
									}}
								/>
							}
						/>
					</div>
					<div className="h-[20px]" />
					<div className="w-full">
						<Description
							title={t("Асослантирувчи ҳужжатлар")}
							content={
								<Controller
									name="file"
									control={control}
									render={({ field }: any) => (
										<div>
											<Upload
												maxCount={1}
												multiple={false}
												beforeUpload={(file: any) => {
													setValue("file", file);
													return false;
												}}
											>
												<Button icon={<UploadIcon strokeWidth={1.5} size={16} />} className="w-full" block>
													{t("Файл")}
												</Button>
											</Upload>
										</div>
									)}
								/>
							}
						/>
					</div>
					<div className="h-[20px]" />
					<div className="grid grid-cols-2 gap-5">
						<Button type="primary" onClick={() => setIsOpen(false)} danger>
							{t("Yopish")}
						</Button>
						<Button type="primary" onClick={handleSubmit(onSubmit)} loading={mutateAddUser.status === "loading"}>
							{t("Қўшиш")}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default AddTxkmEmployeeModal;
