import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Description, Text } from "@geist-ui/core";
import clsx from "clsx";
import { get } from "lodash";
import SimpletPercentageDonut from "pages/warehouse/SimpletPercentageDonut";
import WareHouseDonut from "pages/warehouse/WareHouseDonut";
import WarehouseVisualChart from "pages/warehouse/WarehouseVisualChart";
import { formatNumber } from "utils/utils";
import { req } from "../../services/api";
import classes from "components/shared/Layout/Content/Style.module.scss";
import Footer from "components/shared/Layout/Footer";
import Header from "components/shared/Layout/Header";

interface IStat {
	tree: [
		{
			status: number;
			statusCount: number;
		}
	];
	activeCash: number;
	companyCount: number;
	expiryCount: number;
	blocked: number;
	blockedComp: number;
	unblocked: number;
	unblockedComp: number;
	eimzo: number;
}

function Warehouse() {
	const queryVisualChartData = useQuery({
		queryKey: ["queryVisualChartData"],
		queryFn: () => {
			return req("reestr")({
				url: `/fm-list-storage/stat`,
				method: "GET",
			});
		},
	});

	const stat = get(queryVisualChartData, "data.data.data", []) as IStat;

	const statCards = [
		{
			title: "Муддати тугаган",
			count: stat.expiryCount,
			companyCount: stat.unblockedComp + stat.blockedComp,
			total: "1 201 316 млн.сўм",
			color: "#F3AFA5",
			children: [
				{
					title: "Блокланган ФМлар",
					count: stat.blocked,
					companyCount: stat.blockedComp,
					total: "",
					color: "#C8D9F8",
				},
				{
					title: "Блокланмаган ФМлар",
					count: stat.unblocked,
					companyCount: stat.unblockedComp,
					total: "1 201 316 млн.сўм",
					color: "#B0D884",
				},
			],
		},
	];
	return (
		<>
			<Header />
			<div className={`container !max-w-[1500px] ${classes.main}`}>
				<div className={clsx(classes.content, "p-5 !flex-col")}>
					<Text h1 className="mx-auto !my-0">
						Омбоpхона
					</Text>
					<div className="flex !gap-10 w-full">
						<div className="w-1/2 p-5  shadow-md rounded-lg">
							<div className="flex justify-between">
								<div>
									<WareHouseDonut total={stat.activeCash} expiryCount={stat.expiryCount} />
								</div>
								<div className="">
									<Text h2 className="!pb-2 inline-block !border-[#1E61B4]" style={{ borderBottom: "1px solid" }}>
										Актив фискал модуллар
									</Text>
									<div className="h-[20px]" />
									<Description
										title="Корхона сони"
										content={
											<Text h3 className="text-3xl !text-[#102E7A]">
												{formatNumber(stat.companyCount)}
											</Text>
										}
									/>
									<div className="h-[20px]" />
									<Description
										title="Фискал модул сони"
										content={
											<Text h3 className="text-3xl !text-[#102E7A]">
												{formatNumber(stat.activeCash)}
											</Text>
										}
									/>
								</div>
							</div>
							<div className="h-[20px]" />
							{statCards.map((statCard) => {
								return (
									<>
										<div className="w-full bg-[#F5F9FF] px-5 py-3 shadow-lg rounded-lg">
											<div className="grid grid-cols-12">
												<div className="col-span-10">
													<Text p className="!pb-2 inline-block !border-[#1E61B4]" style={{ borderBottom: "1px solid" }}>
														{statCard.title}
													</Text>
													<div className="h-[20px]" />
													<div className="flex gap-16">
														<Description
															title="Корхона сони"
															content={
																<Text h3 my={0} className="!text-[#102E7A]">
																	{formatNumber(statCard.companyCount)}
																</Text>
															}
														/>
														<Description
															title="Фискал модуллар сони"
															content={
																<Text h3 my={0} className="!text-[#102E7A]">
																	{formatNumber(statCard.count)}
																</Text>
															}
														/>
													</div>
												</div>
												<div className="col-span-2">
													<SimpletPercentageDonut
														content="100%"
														colors={[statCard.color, "#F2F2F2"]}
														data={[
															{
																type: "Муддати тугаган",
																value: 100,
															},
														]}
													/>
												</div>
											</div>
										</div>

										<div className="h-[10px]" />

										{statCard.children.map((childStatCard, idx) => {
											return (
												<React.Fragment key={idx}>
													<div className="h-[20px]" />

													<div className="!pl-10">
														<div className="grid grid-cols-12 border !border-gray-300 bg-white px-5 py-3 rounded-lg">
															<div className="col-span-10">
																<Text
																	p
																	className="!pb-2 inline-block !border-[#1E61B4]"
																	style={{ borderBottom: "1px solid" }}
																>
																	{childStatCard.title}
																</Text>

																<div className="h-[20px]" />

																<div className="flex gap-16">
																	<Description
																		title="Корхона сони"
																		content={
																			<Text h3 my={0} className="!text-[#102E7A]">
																				{formatNumber(childStatCard.companyCount)}
																			</Text>
																		}
																	/>
																	<Description
																		title="Фискал модуллар сони"
																		content={
																			<Text h3 my={0} className="!text-[#102E7A]">
																				{formatNumber(childStatCard.count)}
																			</Text>
																		}
																	/>
																</div>
															</div>

															<div className="col-span-2">
																<SimpletPercentageDonut
																	content={((childStatCard.count / statCards[0].count) * 100).toFixed(2).concat("%")}
																	colors={[childStatCard.color, "#F2F2F2"]}
																	data={[
																		{
																			type: "Блокланган ФМлар",
																			value: (childStatCard.count / statCards[0].count) * 100,
																		},
																		{
																			type: "Barchasi",
																			value: 100 - (childStatCard.count / statCards[0].count) * 100,
																		},
																	]}
																/>
															</div>
														</div>
													</div>
												</React.Fragment>
											);
										})}
									</>
								);
							})}
						</div>

						<div className="w-1/2 h-full rounded-lg">
							<WarehouseVisualChart tree={stat.tree} />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default Warehouse;
