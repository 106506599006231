import classes from "./Style.module.scss";

type PropsType = {
  label: any;
  children: any;
}

const LabelForInput = ({label, children}: PropsType) => {
  return (
    <div className={classes.wrap}>
      <label>{label}</label>
      {children}
    </div>
  )
}

export default LabelForInput;