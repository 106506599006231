import { FC } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Table.module.scss";
import { Table as AntTable, Col, Row, Space, TableProps } from "antd";
import useItemRender from "components/shared/AntComponents/Pagination/useItemRender";

type Props = TableProps<any> & {
	bordered?: boolean;
	width?: boolean;
	height?: number;
	scrollable?: boolean;
	wrapClassName?: string;
	filter?: any;
	defaultSize?: any;
	defaultSortOrder?: any;
	breadcrumbs?: any;
};

const Table: FC<Props> = ({
	bordered = true,
	scrollable,
	height = 500,
	wrapClassName,
	defaultSize = "10",
	filter = {},
	defaultSortOrder,
	breadcrumbs,
	pagination = false,
	...props
}) => {
	const { t } = useTranslation();
	const { itemRender } = useItemRender();

	const classnames = `${wrapClassName} ${classes.table} ${scrollable ? classes.scrollTable : ""}`;

	return (
		<div className={classnames}>
			{filter && (
				<div className="mb-10">
					<Row justify="space-between" align="middle" gutter={[10, 10]}>
						<Col>
							<div className={classes.icon}>
								<Space size="middle">{filter?.left}</Space>
							</div>
						</Col>
						<Col>
							<Space size="middle">{filter?.right}</Space>
						</Col>
					</Row>
				</div>
			)}
			{breadcrumbs && <div className="mb20">{breadcrumbs}</div>}

			<AntTable
				scroll={scrollable ? { x: 1500, y: height } : undefined}
				{...props}
				locale={{
					triggerDesc: t("Юқоридан пастга саралаш"),
					triggerAsc: t("Пастдан юқорига саралаш"),
					cancelSort: t("Саралашни бекор қилиш"),
				}}
				bordered={bordered}
				pagination={
					pagination
						? {
								itemRender,
								showSizeChanger: false,
								...pagination,
						  }
						: false
				}
			/>
		</div>
	);
};

export default Table;
