import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";
import { req } from "services/api";

function ImzolashForm() {
	const { getValues } = useFormContext();
	console.log(getValues());

	const queryShartnoma = useQuery({
		queryKey: ["queryShartnoma", "BB"],
		queryFn: () => {
			const tinOrPinfl = getValues("tin").length === 9 ? "tin" : "pinfl";
			const values = getValues();

			return req("reestr")({
				url: `/application-fmo/get-pdf`,
				method: "POST",
				responseType: "blob",
				data: {
					saleName: get(values, "name"),
					ns10Code: get(values, "view_viloyat"),
					ns11Code: get(values, "view_tuman"),

					[tinOrPinfl]: get(values, "tin"),
					address: get(values, "address"),
					salePointId: get(values, "reestr") === "new" ? get(values, "sale-point-add-api.id") : get(values, "reestr"),
					saleAddress: get(values, "view_address", "-"),
					phone: get(values, "phone"),
					ns412Code: get(values, "faoliyat_turi"),
					ns413Code: get(values, "shoxobcha_toifasi"),
					terminalId: get(values, "fiskal_modul_seariya"),
					kkmCategoryId: get(values, "onlayn_nkm"),
					kkmCategoryName: get(values, "onlayn_nkm"),
					serialNumber: get(values, "onlayn_nkm__vk_seriya"),
				},
			});
		},
	});

	const res = get(queryShartnoma, "data.data", "");

	const blob = new Blob([res], { type: "application/pdf" });
	const blobUrl = URL.createObjectURL(blob);

	return (
		<>
			{queryShartnoma.status === "loading" ? (
				<>Yuklanmoqda...</>
			) : (
				<object data={blobUrl} type="application/pdf" width="100%" height="500px">
					<p>Unable to display PDF file.</p>
				</object>
			)}
		</>
	);
}

export default ImzolashForm;
