import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import classes from "./Pagination.module.scss";
import { PaginationProps } from "antd";

import Button from "components/shared/AntComponents/Button";

import Svg from "assets/images/svg";

const useItemRender = () => {
	const { t } = useTranslation();

	const itemRender: PaginationProps["itemRender"] = useCallback(
		(_: any, type: string, originalElement: any) => {
			if (type === "prev") {
				return (
					<Button
						className={classes.pagination__left}
						startIcon={<Svg icon="arrow-left" />}
					>
						{t("Предыдущая")}
					</Button>
				);
			}
			if (type === "next") {
				return (
					<Button
						className={classes.pagination__right}
						endIcon={<Svg icon="arrow-left" />}
					>
						{t("Следующая")}
					</Button>
				);
			}
			return originalElement;
		},
		[]
	);

	return {
		itemRender
	};
};

export default useItemRender;
