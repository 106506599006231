import { useQuery } from "@tanstack/react-query";
import { Download } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Tag } from "antd";
import { get } from "lodash";
import FilterBtn from "pages/Application/Txkm/TxkmFilterBtn";
import { ColumnsType } from "antd/es/table";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Button, Pagination, Table } from "components/shared";
import DownloadFile from "pages/Rester/components/OnlineNKM/DownloadFile";

interface IEmployee {
	id: number;
	status: number;
	tin: number;
	pinfl: number;
	fio: string;
	fileID: number;
	fileName: number;
	descriptions: string;
	answerDescription: string;
	phone: string;
	createdDate: string;
}

const SIZE = 20;

export function tinOrPinfl(param: string) {
	try {
		if (param.length === 9) return "tin";

		return "pinfl";
	} catch (error) {
		//
	}

	return "";
}

const ApplicationTxkmEmployeeList = () => {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const tinQS = new URLSearchParams(window.location.search).get("tin");
	const statusQS = new URLSearchParams(window.location.search).get("status");

	const queryEmployee = useQuery({
		queryKey: ["queryEmployee", state.page, tinQS, statusQS],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/applications-cto/index/employee`,
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
					status: statusQS,
					[tinOrPinfl(tinQS as string)]: tinQS,
				},
			});
		},
	});

	const data = get(queryEmployee, "data.data.data", []);
	const total = get(queryEmployee, "data.data.recordsTotal", 0) as number;

	const columns: ColumnsType<IEmployee> = [
		{
			title: "№",
			dataIndex: "number",

			width: 50,
			render(value, record, index) {
				return <>{(state.page - 1) * SIZE + index + 1}</>;
			},
		},
		{
			title: t("ЖШШИР"),
			dataIndex: "pinfl",
		},
		{
			title: t("СТИР"),
			dataIndex: "tin",
		},
		{
			title: t("Ф.И.Ш."),
			dataIndex: "fio",
		},
		{
			title: t("Телефон рақами"),
			dataIndex: "phone",
		},
		{
			title: t("Хужжатлар"),
			dataIndex: "fileName",
			align: "center",
			render(value, record, index) {
				if (!value) {
					return "-";
				}

				return (
					<div className="!mx-auto flex items-center justify-center">
						<DownloadFile fileId={record.fileID}>
							<Button className="truncate !shadow-none !px-4 w-48 !h-9 !p-0 flex items-center justify-center">
								<Download size={20} strokeWidth={1.5} /> {record.fileID}.pdf
							</Button>
						</DownloadFile>
					</div>
				);
			},
		},
		{
			title: t("Статус"),
			dataIndex: "status",
			align: "center",
			render(value, record, index) {
				if (get(record, "status") === 1) {
					return <Tag color="#0356fc">{t("Yangi")}</Tag>;
				}

				if (get(record, "status") === 2) {
					return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
				}

				if (get(record, "status") === 3) {
					return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
				}

				if (get(record, "status") === 4) {
					return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
				}
				return null;
			},
		},
		{
			title: t("Яратилган сана"),
			dataIndex: "createdDate",
		},
	];
	return (
		<Spin spinning={queryEmployee.isLoading}>
			<FilterBtn />

			<Table columns={columns} dataSource={data} pagination={false} />

			<div className="h-[20px]" />

			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</Spin>
	);
};

export default ApplicationTxkmEmployeeList;
