import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import "./App.less";
import AllRoutes from "./Routes";
import { GeistProvider } from "@geist-ui/core";
import { ConfigProvider } from "antd";
import { globalAntdConfig } from "config";
import CommonInfoProvider from "context/commonInfo";
import locale from "antd/locale/ru_RU";
import { EIMZOClient } from "services/eimzo/id-card-client";
import i18n from "services/i18n";
import { PageLoader } from "components/shared";
import "assets/output.css";
import "assets/styles/index.scss";

EIMZOClient.installApiKeys();

const App = () => {
	return (
		<I18nextProvider i18n={i18n}>
			<CommonInfoProvider>
				<GeistProvider>
					<ConfigProvider theme={globalAntdConfig} locale={locale}>
						<Suspense fallback={<PageLoader />}>
							<>
								<AllRoutes />
								<ReactQueryDevtools initialIsOpen={false} />
							</>
						</Suspense>
					</ConfigProvider>
				</GeistProvider>
			</CommonInfoProvider>
		</I18nextProvider>
	);
};

export default App;
