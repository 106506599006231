import { createContext } from "react";
import { Outlet } from "react-router-dom";
import Content from "./Content";
import Header from "./Header";
import { UserDTO } from "types";

export const UserInfo = createContext<UserDTO | undefined>(undefined);

const Layout = () => {
	return (
		<>
			<Header />
			<Content>
				<Outlet />
			</Content>
		</>
	);
};

export default Layout;
