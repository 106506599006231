export default function MenuArrowRight(props: any) {
	return (
		<svg
			width="11"
			height="10"
			viewBox="0 0 11 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10.5 5.00001L3 9.33014L3 0.669889L10.5 5.00001Z" fill="#777B82" />
		</svg>
	);
}
