import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { get } from "lodash";
import { ColumnsType } from "antd/es/table";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Pagination, Table } from "components/shared";

interface IFilialFmChange {
	pkey: string;
	tin: number;
	status: number;
	pinfl: string | null;
	terminalId: string;
	createdDate: string;
}

export enum EFmChangeType {
	FISKAL_XOTIRASI_TOLGAN = 1,
	FISKAL_MODUL_YAROQSIZ = 2,
	FISKAL_MODUL_YOQOTILGAN = 3,
}

const SIZE = 20;

function TabFilialFmChange() {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const oldFmQS = new URLSearchParams(window.location.search).get("oldFm");
	const newFmQS = new URLSearchParams(window.location.search).get("newFm");
	const statusQS = new URLSearchParams(window.location.search).get("status");

	const fmChangeOptions = [
		{
			label: t("Хотираси тўлган"),
			value: EFmChangeType.FISKAL_XOTIRASI_TOLGAN,
		},
		{
			label: t("Яроқсиз"),
			value: EFmChangeType.FISKAL_MODUL_YAROQSIZ,
		},
		{
			label: t("Йўқотилган"),
			value: EFmChangeType.FISKAL_MODUL_YOQOTILGAN,
		},
	];

	const queryFilialFmChange = useQuery({
		queryKey: ["queryFilialFmChange", state.page, oldFmQS, newFmQS, statusQS],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/fm-list/fm-change-application-filial`,
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
					oldTerminalId: oldFmQS,
					terminalId: newFmQS,
					status: statusQS,
				},
			});
		},
	});

	const data = get(queryFilialFmChange, "data.data.data", []) as IFilialFmChange[];
	const total = get(queryFilialFmChange, "data.data.recordsTotal", 0);

	const columns: ColumnsType<IFilialFmChange> = [
		{
			width: 50,
			dataIndex: "number",
			title: "№",
			render: (data, mock, i) => <>{Number(state.page - 1) * Number(SIZE) + i + 1}</>,
		},
		{
			title: t("ФМ терминал"),
			dataIndex: "terminalId",
		},
		{
			title: t("Эски ФМ"),
			dataIndex: "oldTerminalId",
		},
		{
			title: t("Сабаб"),
			dataIndex: "reason",
		},
		{
			title: t("Юборидган сана"),
			dataIndex: "createdDate",
		},

		{
			title: t("Тип"),
			dataIndex: "type",
			render(value, record, index) {
				const reason = fmChangeOptions.find((option) => option.value === value)?.label;

				return <Tag color="#f50">{t((reason || "").trim())}</Tag>;
			},
		},
		{
			title: t("Бош ТХКМ тасдиқлаганлиги"),
			dataIndex: "headCtoApproved",
		},
		{
			title: t("ТХКМ"),
			dataIndex: "ctoCompanyName",
		},
	];

	return (
		<>
			<Table columns={columns} dataSource={data} />
			<div className="h-[20px]" />
			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</>
	);
}

export default TabFilialFmChange;
