import { ArrowRightLeft, Database, File, Layout, LayoutGrid } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classes from "./Sidebar.module.scss";
import { Badge, Tooltip } from "antd";
import classNames from "classnames";
import { get } from "lodash";
import { useQueryNotificationCount } from "pages/Application/components/Tabs";

export default function Sidebar() {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const menuList = [
		{
			location: "/reestr",
			name: t("Реестр"),
			icon: Database,
		},
		{
			location: "/application",
			name: t("Аризалар"),
			icon: LayoutGrid,
		},
		{
			location: "/payments",
			name: t("FM TO`LOVLAR"),
			icon: ArrowRightLeft,
		},
	];

	const queryNotificationCount = useQueryNotificationCount();
	const noitifcation_count = get(queryNotificationCount, "data.data.recordsTotal", 0);

	return (
		<div
			className={classNames({
				[classes.sidebar]: true,
				"!py-7 flex-shrink-0 w-[70px]": true,
			})}
		>
			<ul>
				{menuList.map(({ location, name, ...item }) => (
					<li
						className={classNames({
							[classes.active]: pathname.startsWith(location),
							"!py-3": true,
						})}
						key={location}
					>
						<Badge count={location === "/application" ? noitifcation_count : 0} showZero={false} className="w-full">
							<Tooltip title={name} placement="right">
								<Link to={location} className="mx-auto">
									<item.icon className="mx-auto" />
								</Link>
							</Tooltip>
						</Badge>
					</li>
				))}
			</ul>
		</div>
	);
}
