import { ArrowUpRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Offerta = () => {
	const { t } = useTranslation();
	return (
		<div className="flex items-center !gap-4">
			<Link
				target="_blank"
				to={`/offerta.pdf`}
				style={{ textDecoration: "none" }}
				className="flex items-center justify-center !gap-1 bg-gray-100/10 !py-2 !px-2 !pl-4 rounded-lg hover:bg-gray-100/20 duration-200 !no-underline"
			>
				<p className="my-0 !text-white">{t("Оферта")}</p>
				<ArrowUpRight className="!text-white !h-4" strokeWidth={1.5} />
			</Link>

			<Link
				target="_blank"
				to={`/offerta.pdf`}
				style={{ textDecoration: "none" }}
				className="flex items-center justify-center !gap-1 bg-gray-100/10 !py-2 !px-2 !pl-4 rounded-lg hover:bg-gray-100/20 duration-200 !no-underline"
			>
				<p className="my-0 !text-white">{t("Инструкция")}</p>
				<ArrowUpRight className="!text-white !h-4" strokeWidth={1.5} />
			</Link>
		</div>
	);
};

export default Offerta;
