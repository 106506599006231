import { useMutation } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, InputRef, Modal, message } from "antd";
import classNames from "classnames";
import { get } from "lodash";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";

function CompleteBrokenNkm({ id, onSuccess, data }: { id: string; data: any; onSuccess: () => unknown }) {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		isOpen: false,
	});

	const mutateSendToFixing = useMutation({
		mutationKey: ["mutateSendToFixing", id],
		mutationFn: ({ reason }: { reason: string }) => {
			return req("reestr")({
				url: `/application-invalid-cash/finish/${id}`,
				method: "POST",
				data: {
					reason,
				},
			});
		},
	});

	const inputRef = useRef<InputRef>(null);

	const onAccept = async () => {
		const reason = get(inputRef, "current.input.value", "-");

		try {
			const res = await mutateSendToFixing.mutateAsync({ reason });

			const success = get(res, "data.success", false);
			if (!success) {
				const errorCode = get(res, "data.code", "-");

				message.error(t(errorCode));
			} else {
				message.success(t(`Qo'shildi`));
				onSuccess();
			}
		} catch (error) {
			message.error(t(`Xatolik!`));
		}
	};

	const infos = [
		{ title: "STIR", value: get(data, "tin", "-") ?? "-" },
		{ title: "JSHSHIR", value: get(data, "pinfl", "-") ?? "-" },
		{ title: "ФМ терминал", value: get(data, "terminalId", "-") ?? "-" },
		{ title: "Status", value: get(data, "status", "-") ?? "-" },
		{ title: "Ariza sababi", value: get(data, "reason", "-") ?? "-" },
		{ title: "Txkm izoxi", value: <Input ref={inputRef} placeholder="..." /> },
		{ title: "Ariza sanasi", value: get(data, "createdDate", "-") ?? "-" },
	];

	return (
		<>
			<Button
				onClick={() => dispatch({ param: true, type: "isOpen" })}
				loading={mutateSendToFixing.isLoading}
				className="!shadow-none !w-9 !h-9 !p-0 flex items-center justify-center"
			>
				<Pencil size={20} strokeWidth={1.5} />
			</Button>

			<Modal
				title={t("Arizani yakunlash")}
				centered
				open={state.isOpen}
				footer={false}
				onCancel={() => dispatch({ param: false, type: "isOpen" })}
			>
				<div className="space-y-7">
					<div className="w-full rounded-md border !border-gray-300 overflow-hidden">
						{infos.map((info, idx) => {
							return (
								<div
									key={idx}
									className={classNames({
										"w-full grid grid-cols-2 !py-2 !px-3": true,
										"bg-gray-50": idx % 2 === 0,
									})}
								>
									<div className="">{info.title}</div>
									<div className="">{info.value}</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="h-[20px]" />

				<Button type="primary" block loading={mutateSendToFixing.isLoading} onClick={onAccept}>
					Yakunlash
				</Button>
			</Modal>
		</>
	);
}

export default CompleteBrokenNkm;
