import { UploadCloud } from "lucide-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Upload } from "antd";
import { get } from "lodash";
import { RcFile } from "antd/es/upload";
import ErrorText from "pages/Rester/components/BranchesView/ErrorText";

function YakuniyForm() {
	const { t } = useTranslation();
	const {
		setValue,
		register,
		formState: { errors },
	} = useFormContext();

	const upload = (fileName: string) => (file: RcFile, fileList: RcFile[]) => {
		setValue(fileName, file);

		return false;
	};

	useEffect(() => {
		register("imzolangan_ariza_file", { required: true });
		register("ozaro_ariza_file", { required: true });
		register("2d_skaner_file", { required: false });
	}, []);

	return (
		<>
			<div className="grid grid-cols-3 !gap-5">
				<div className="border !border-gray-400 rounded-lg !p-5 space-y-3" style={{ border: "1px solid" }}>
					<p className="!mb-3">{t("Заявление предпринимателя")}</p>

					<Upload multiple={false} beforeUpload={upload("imzolangan_ariza_file")}>
						<Button
							block
							type="dashed"
							className="flex items-center justify-center gap-3"
							icon={<UploadCloud strokeWidth={2} height={16} width={16} />}
						>
							{t("Ҳужжатларни юкланг")}
						</Button>
						<ErrorText>{get(errors, "imzolangan_ariza_file", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
					</Upload>
				</div>

				<div className="border !border-gray-400 rounded-lg !p-5 space-y-3" style={{ border: "1px solid" }}>
					<p className="!mb-3">{t("Договор между ЦТО и предпринимателем")}</p>

					<Upload multiple={false} beforeUpload={upload("ozaro_ariza_file")}>
						<Button
							block
							type="dashed"
							className="flex items-center justify-center gap-3"
							icon={<UploadCloud strokeWidth={2} height={16} width={16} />}
						>
							{t("Ҳужжатларни юкланг")}
						</Button>
						<ErrorText>{get(errors, "ozaro_ariza_file", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
					</Upload>
				</div>
				<div className="border !border-gray-400 rounded-lg !p-5 space-y-3" style={{ border: "1px solid" }}>
					<p className="!mb-3">{t("2D сканер шартномаси")}</p>

					<Upload multiple={false} beforeUpload={upload("2d_skaner_file")}>
						<Button
							block
							type="dashed"
							className="flex items-center justify-center gap-3"
							icon={<UploadCloud strokeWidth={2} height={16} width={16} />}
						>
							{t("Ҳужжатларни юкланг")}
						</Button>
						{/* <ErrorText>{get(errors, "2d_skaner_file", null) ? t("Тўлдириш шарт!") : null}</ErrorText> */}
					</Upload>
				</div>
			</div>
		</>
	);
}

export default YakuniyForm;
