import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { SelectProps } from "antd";
import { get } from "lodash";
import { req } from "services/api";
import { Select } from "components/shared";
import { IViloyat } from "pages/Rester/components/BranchesView/SelectViloyat";

export interface ITuman {
	ns11PK: {
		ns10Code: number;
		code: number;
	};
	nameUz: string;
	nameRu: string;
	ns10: IViloyat;
	code: number;
	name: string;
}

function SelectTuman({ selectedViloyat, ...props }: { selectedViloyat: string | number } & SelectProps) {
	const { t, i18n } = useTranslation();
	const queryTumans = useQuery({
		queryKey: ["queryTumans", selectedViloyat, i18n.language],
		queryFn: () => {
			return req("reestr")({
				url: `/information/index/district/${selectedViloyat}`,
				method: "GET",
				params: {
					selectedViloyat,
					lang: i18n.language,
				},
			});
		},
		enabled: !!selectedViloyat,
	});
	const tumans = get(queryTumans, "data.data.data", []) as ITuman[];

	return (
		<>
			<Select
				label={t("Туман")}
				disabled={!selectedViloyat}
				options={tumans.map((tuman) => ({
					label: tuman?.name,
					value: tuman?.code,
				}))}
				{...props}
			/>
		</>
	);
}

export default SelectTuman;
