import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import CtoView from "pages/Application/TxkmChange/CtoView";
import Backdoor from "pages/Backdoor";
import ApplicationView from "pages/application-view";
import SelectFirm from "pages/select-firm";
import Warehouse from "pages/warehouse";
import AddApplication from "pages/Rester/components/BranchesView/AddApplication";

const Layout = lazy(() => import("./components/shared/Layout"));
const General = lazy(() => import("./pages/General"));
const Application = lazy(() => import("./pages/Application"));
const Payments = lazy(() => import("./pages/Payments"));
const Rester = lazy(() => import("./pages/Rester"));
const Ticket = lazy(() => import("./pages/Ticket"));
const SignIn = lazy(() => import("./pages/Auth"));

export default function AllRoutes() {
	const isAuth = !!localStorage.getItem("token");

	const routes = useRoutes([
		{
			path: "/select-firm",
			element: <SelectFirm />,
			index: false,
		},
		{
			path: "/backdoor/:type/:tinOrPinfl",
			element: <Backdoor />,
			index: false,
		},
		...(isAuth
			? [
					{
						path: "/warehouse",
						element: <Warehouse />,
						index: false,
					},

					{
						path: "/",
						element: <Navigate to={`/reestr/sale-point`} />,
						index: false,
					},
					{
						path: "/",
						element: <Layout />,
						children: [
							{
								path: "/general",
								element: <General />,
								index: false,
							},

							{
								path: "/reestr/*",
								element: <Rester />,
								index: false,
							},
							{
								path: "/application/*",
								element: <Outlet />,
								children: [
									{
										path: "",
										element: <Navigate to={"/application/dsi"} />,
									},
									{
										path: ":page",
										element: <Application />,
									},
									{
										path: "dsi/:id",
										element: <ApplicationView />,
									},
									{
										path: "txkm-almashtirish/:id",
										element: <CtoView />,
									},
								],
							},
							{
								path: "/add-application",
								element: <AddApplication />,
								index: false,
							},
							{
								path: "/payments",
								element: <Payments />,
								index: false,
							},
						],
					},
			  ]
			: [
					{
						path: "*",
						element: <SignIn />,
					},
			  ]),
		{
			path: "/login/ticket",
			element: <Ticket />,
		},
	]);

	return routes;
}
