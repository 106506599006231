import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { SelectProps } from "antd";
import { get } from "lodash";
import { req } from "services/api";
import { Select } from "components/shared";

export interface IMaxalla {
	code: number;
	name: string;
	nameUz: string;
	nameRu: string;
	regionCode: number;
	districtCode: number;
	updatedDate: string;
	updatedBy: number;
	createdDate: string;
	createdBy: number;
}

function SelectMaxalla({
	selectedViloyat,
	selectedTuman,
	defaultName,
	...props
}: { selectedViloyat: string | number; selectedTuman: string | number; defaultName?: string | number } & SelectProps) {
	const { t } = useTranslation();
	const queryMaxalla = useQuery({
		queryKey: ["queryMaxalla", selectedViloyat, selectedTuman],
		queryFn: () => {
			return req("reestr")({
				url: `/information/index/neighborhood`,
				method: "GET",
				params: {
					regionCode: selectedViloyat,
					districtCode: selectedTuman,
				},
			});
		},
		enabled: !!selectedViloyat && !!selectedTuman,
	});
	const maxallas = get(queryMaxalla, "data.data.data", []) as IMaxalla[];

	return (
		<>
			<Select
				label={t("Махалла")}
				disabled={!selectedViloyat && !selectedTuman}
				options={maxallas.map((maxalla) => ({
					label: maxalla.name,
					title: maxalla.name,
					value: String(maxalla.code),
				}))}
				{...props}
				value={maxallas.find((maxalla) => String(maxalla.code) === String(props.value)) ? props.value : defaultName}
			/>
		</>
	);
}

export default SelectMaxalla;
