import { QueryCache, QueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosInstance } from "axios";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	},
});

export const queryCache = new QueryCache({
	onError: (error) => {
		console.log(error);
	},
	onSuccess: (data) => {
		console.log(data);
	},
});

queryCache.clear();

type TApiServices = "reestr" | "my3" | "my3-auth";

export const AuthApiNotAuth = axios.create({
	baseURL: process.env.REACT_APP_AUTH,
});

export const request = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

const my3Req = axios.create({
	baseURL: process.env.REACT_APP_MY3_SOLIQ_API,
});

export const req = (url?: TApiServices, isAuth?: boolean) => {
	const requstInstance = (() => {
		if (url === "my3") {
			return my3Req;
		}

		// Default is reestr
		return request;
	})();

	if (isAuth) {
		addToken(requstInstance);
	}

	return requstInstance;
};

export const AuthApi = axios.create({
	baseURL: process.env.REACT_APP_AUTH,
});

export function useAPIInstances() {
	const token =
		"eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJNeXNvbGlxLWF1dGhvcml6YXRpb24iLCJzdWIiOiJlNGU0MDIwYTg4NTgwYjRkMDE4ODc2Njk2YTU0MDUyZSIsImlhdCI6MTQ2Njc5NjgyMiwianRpIjoiZTRlNDAyMGE4ODU4MGI0ZDAxODg3NjY5NmE1NDA1MmUifQ.YicJXQacwfQoWpOyX9754KIkPjlyAfhBgKIEz8bmkck";

	// MySoliq UAA
	const my3SoliqRequest = axios.create({
		baseURL: process.env.REACT_APP_MY3_SOLIQ_API,
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	// General request (project request)
	const generalRequest = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return {
		my3SoliqRequest,
		generalRequest,
	};
}

const addToken = (req: AxiosInstance) => {
	req.interceptors.request.use(
		(config: any) => {
			config.headers = {
				...config.headers,
				Authorization: "Bearer " + localStorage.getItem("token"),
			};
			return config;
		},
		(error) => Promise.reject(error)
	);

	return req;
};

[request, AuthApi].map((r) =>
	r.interceptors.request.use(
		(config: any) => {
			config.headers = {
				...(localStorage.getItem("token") ? { Authorization: "Bearer " + localStorage.getItem("token") } : {}),
				...config.headers,
			};
			return config;
		},
		(error) => Promise.reject(error)
	)
);

[request, AuthApi].map((r) =>
	r.interceptors.response.use(
		(config) => config,
		(error: AxiosError) => {
			if (error.response?.status === 401) {
				localStorage.removeItem("token");
				localStorage.removeItem("refresh_token");
				// window.location.pathname = "/";
			}
			throw error;
		}
	)
);
