import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "@geist-ui/core";
import { message } from "antd";
import { saveAs } from "file-saver";
import { request } from "services/api";

function DownloadFile({ children, fileId }: { children: React.ReactNode; fileId: string | number }) {
	const { t } = useTranslation();
	const mutateDownloadFile = useMutation({
		mutationKey: ["mutateDownloadFile", fileId],
		mutationFn: () => {
			return request({
				method: "GET",
				responseType: "blob",
				url: `/information/get-file/${fileId}`,
			});
		},
	});
	const onDownload = async () => {
		try {
			const { data } = await mutateDownloadFile.mutateAsync();

			const file = new Blob([data], { type: "application/pdf" });

			saveAs(file, `${fileId}.pdf`, { autoBom: true });
		} catch (error) {
			message.error(t("Fayl topilmadi"));
		}
	};

	return <>{mutateDownloadFile.status === "loading" ? <Spinner /> : <div onClick={onDownload}>{children}</div>}</>;
}

export default DownloadFile;
