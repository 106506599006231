import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LoginOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { queryCache, req } from "services/api";

interface IUserCompanies {
	id: number;
	parentName: string | null;
	name: string;
	tin: string;
	isActive: number;
	selected: number;
	userId: number;
	roleId: number;
	roleName: string;
}

function SelectFirm() {
	const { t } = useTranslation();
	const token = new URLSearchParams(window.location.search).get("token") as string;

	const queryUserCompanies = useQuery({
		queryKey: ["queryUserCompanies", token],
		queryFn: () => {
			return req("reestr")({
				url: "/user/my-companies",
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		},
		enabled: Boolean(token),
	});

	const companies = get(queryUserCompanies, "data.data.data", []) as IUserCompanies[];

	useEffect(() => {
		if (queryUserCompanies.isSuccess && isEmpty(companies)) {
			localStorage.removeItem("token");
			localStorage.removeItem("refresh_token");
			window.location.href = "/reestr/sale-point";

			message.error(t("Korxona yo'q"));
		}
	}, [companies]);

	const mutateActivateUserCompany = useMutation({
		mutationKey: ["mutateActivateUserCompany"],
		mutationFn: ({ id }: { id: string | number }) => {
			return req("reestr")({
				url: `/user/set-active-company/${id}`,
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		},
	});

	const onAccept = async (id: string | number) => {
		try {
			const res = await mutateActivateUserCompany.mutateAsync({ id });

			localStorage.setItem("token", token);

			const success = get(res, "data.success", false);

			if (!success) {
				const errorCode = get(res, "data.code", "-");

				message.error(t(errorCode));
			} else {
				queryCache.clear();

				window.location.href = "/reestr/sale-point";
			}
		} catch (error) {
			message.error(t(`Xatolik!`));
		}
	};

	return (
		<div className="flex items-center justify-center | container mx-auto !h-full overflow-y-auto !py-10">
			<div className="grid grid-cols-1 !gap-5">
				{companies.map((company) => {
					return (
						<div
							key={company.id}
							className={classNames({
								"bg-white border !border-[0.5px] !border-gray-400 | rounded-md !py-5 !px-5": true,
								"opacity-50 cursor-not-allowed": company.isActive === 0,
							})}
						>
							<div className=" flex items-center !gap-10 | duration-150">
								<p className="!my-0 text-black text-sm font-bold !capitalize">
									<span className="text-gray-400 !mr-1 !font-normal">{t("Ташкилот")}:</span> {company.name}{" "}
									{company.parentName ? `(${company.parentName})` : null}
								</p>

								<p className="!my-0 text-black text-sm font-bold !capitalize">
									<span className="text-gray-400 !mr-1 !font-normal">{t("Role")}:</span> {company.roleName}{" "}
								</p>

								<p className="!my-0 text-black text-sm font-bold">
									<span className="text-gray-400 !mr-1 !font-normal">{t("СТИР")}:</span> {company.tin}
								</p>
							</div>

							<div className="h-[25px]" />

							<Button
								disabled={company.isActive === 0}
								onClick={() => onAccept(company.id)}
								loading={mutateActivateUserCompany.isLoading}
								block
								type="text"
								size="large"
								className="!bg-gray-100 hover:!bg-gray-200 active:!bg-gray-300"
							>
								Kirish <LoginOutlined rev={""} />
							</Button>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default SelectFirm;
