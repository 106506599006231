import { useQuery } from "@tanstack/react-query";
import { Download } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Tag } from "antd";
import { get } from "lodash";
import { tinOrPinfl } from "pages/Application/Txkm/ApplicationTxkmEmployeeList";
import FilterBtn from "pages/Application/Txkm/TxkmFilterBtn";
import { ColumnsType } from "antd/es/table";
import { request } from "../../../services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Button, Pagination, Table } from "components/shared";
import DownloadFile from "pages/Rester/components/OnlineNKM/DownloadFile";

interface IPartnerFilial {
	id: number;
	type: number;
	name: string;
	tin: number;
	pinfl: number;
	fio: string;
	userTin: number;
	phone: string;
	fileID: number;
	fileName: number;
	status: number;
	address: string;
	descriptions: string;
	answerDescription: string;
	ns10Code: number;
	ns10Name: string;
	ns11Code: number;
	ns11Name: string;
	longitude: number;
	latitude: number;
	createdDate: string;
}

const SIZE = 20;

const ApplicationTxkmFilials = () => {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const tinQS = new URLSearchParams(window.location.search).get("tin");
	const statusQS = new URLSearchParams(window.location.search).get("status");

	const queryFilials = useQuery({
		queryKey: ["queryFilials", state.page, tinQS, statusQS],
		queryFn: () => {
			return request({
				method: "GET",
				url: `/applications-cto/index`,
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
					type: 2,
					status: statusQS,
					[tinOrPinfl(tinQS as string)]: tinQS,
				},
			});
		},
	});
	const data = get(queryFilials, "data.data.data", []);
	const total = get(queryFilials, "data.data.data.totalElements", 0) as number;

	const columns: ColumnsType<IPartnerFilial> = [
		{
			width: 50,
			dataIndex: "number",
			title: "№",
			render: (data, mock, i) => <>{Number(state.page) * Number(total) + i + 1} </>,
		},

		{
			title: t("Вилоят"),
			dataIndex: "ns10Name",
		},
		{
			title: t("Туман"),
			dataIndex: "ns11Name",
		},
		{
			title: t("Корхона номи"),
			dataIndex: "name",
		},
		{
			title: t("СТИР"),
			dataIndex: "tin",
		},
		{
			title: t("ЖШШИР"),
			dataIndex: "pinfl",
		},
		{
			title: t("Ф.И.Ш."),
			dataIndex: "fio",
		},
		{
			title: t("Фойдаланувчи ЖШШИРи"),
			dataIndex: "userTin",
		},
		{
			title: t("Телефон рақами"),
			dataIndex: "phone",
		},
		{
			title: t("Хужжатлар"),
			dataIndex: "fileName",
			align: "center",
			render(value, record, index) {
				if (!value) {
					return "-";
				}

				return (
					<div className="!mx-auto flex items-center justify-center">
						<DownloadFile fileId={record.fileID}>
							<Button className="truncate !shadow-none !px-4 w-48 !h-9 !p-0 flex items-center justify-center">
								<Download size={20} strokeWidth={1.5} /> {record.fileID}.pdf
							</Button>
						</DownloadFile>
					</div>
				);
			},
		},
		{
			title: t("Статус"),
			dataIndex: "status",
			align: "center",
			render(value, record, index) {
				if (get(record, "status") === 1) {
					return <Tag color="#0356fc">{t("Yangi")}</Tag>;
				}

				if (get(record, "status") === 2) {
					return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
				}

				if (get(record, "status") === 3) {
					return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
				}

				if (get(record, "status") === 4) {
					return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
				}
				return null;
			},
		},
		{
			title: t("Яратилган сана"),
			dataIndex: "createdDate",
		},
	];

	return (
		<Spin spinning={queryFilials.isLoading}>
			<FilterBtn />

			<Table columns={columns} dataSource={data} />

			<div className="h-[20px]" />

			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</Spin>
	);
};

export default ApplicationTxkmFilials;
