import { useQuery } from "@tanstack/react-query";
import { Building2Icon, Download } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, Steps, Tabs, Tag } from "antd";
import { get } from "lodash";
import moment from "moment";
import { req } from "services/api";
import DownloadFile from "pages/Rester/components/OnlineNKM/DownloadFile";
import MapSection from "pages/Rester/components/SaleView/components/MapSection";

interface IFmoDetails {
	id: number;
	name: string;
	tin: number;
	pinfl?: null;
	saleName: string;
	ns412Name: string;
	saleNs412: number;
	ns413Name: string;
	saleNs413: number;
	ns11Code: string;
	ns10Code: string;
	saleAddress: string;
	longitude: string;
	latitude: string;
	phone: string;
	cashboxName: string;
	kkmCategoryName: string;
	serialNumber: string;
	terminalId: string;
	createdDate: string;
	updatedDate: string;
	status: number;
	answerDescription?: null;
	markirovkaName: string;
	applicationTypeId: number;
	kadastrNumber: string;
	arendaNumber: string;
	mutualAgreementId: number;
	mutualAgreementName: string;
	signedFileId: number;
	signedFileName: string;
	fileId: number;
	fileName: string;
}

function ApplicationView() {
	const { id } = useParams();
	const { t } = useTranslation();

	const queryFmoDetails = useQuery({
		queryKey: ["queryFmoDetails", id],
		queryFn: () => {
			return req("reestr")({
				url: `/application-fmo/view/${id}`,
			});
		},
	});

	const data = get(queryFmoDetails, "data.data.data", []) as IFmoDetails;

	return (
		<div className="!px-7 !py-7">
			<div className="flex items-center !gap-5">
				<div className="bg-white rounded-md shadow-lg !w-16 !h-16 flex items-center justify-center">
					<Building2Icon strokeWidth={1} size={28} />
				</div>

				<div className="flex flex-col !gap-y-2">
					<h3 className="uppercase font-normal">
						{id}. {get(data, "name")}
					</h3>
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to={`/application/dsi`}>{t("Asosiy")}</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/application/dsi`}>{t("DSI arizalar")}</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							{id}. {get(data, "name")}
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>

			<div className="h-[40px]" />

			<div className="bg-white rounded-md shadow-lg !p-5">
				<p className="text-3xl font-thin text-gray-600">{get(data, "saleName")}</p>

				<div className="h-[20px]" />

				<div className="flex items-center !gap-5">
					<div>
						{t("Xolati")}:{" "}
						{(() => {
							if (get(data, "status") === 1) {
								return <Tag color="#0356fc">{t("Yangi")}</Tag>;
							}

							if (get(data, "status") === 2) {
								return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
							}

							if (get(data, "status") === 3) {
								return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
							}

							if (get(data, "status") === 4) {
								return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
							}
							return null;
						})()}
					</div>

					<div>
						{t("Yaratilgan sana")}: <Tag>{moment(get(data, "createdDate")).format("DD.MM.YYYY HH:mm")}</Tag>
					</div>
				</div>

				<div className="h-[30px]" />

				<Tabs
					defaultActiveKey="1"
					items={[
						{
							key: "1",
							label: t("Ariza xarakatlanish sxemasi"),
							children: (
								<>
									<Steps
										progressDot
										current={(() => {
											if (get(data, "status") === 3 || get(data, "status") === 4) {
												return 3;
											}

											return get(data, "status") - 1;
										})()}
										direction="vertical"
										size="small"
										items={[
											{
												title: t("DSI ga yuborildi"),
											},
											{
												title: t("DSI tomondan korib chiqilmoqda"),
											},
											{
												title:
													get(data, "status") === 4
														? t("Rad qilindi").concat(
																` ${get(data, "answerDescription") ? get(data, "answerDescription") : ""}`
														  )
														: t("DSI qabul qildi"),
											},
										]}
									/>
								</>
							),
						},
						{
							key: "2",
							label: t("Shoxobcha joylashgan joy"),
							children: (
								<>
									<MapSection draggable={false} latitude={+get(data, "latitude")} longitude={+get(data, "longitude")} />
								</>
							),
						},
						{
							key: "3",
							label: t("Ilova qilingan fayllar"),
							children: (
								<div className="space-y-7">
									<div className="!space-y-2">
										<p className="text-sm uppercase">{t("Obyektivkaga tegishli fayllar")}</p>
										<DownloadFile fileId={get(data, "fileId")}>
											<Button className="!shadow-none !px-5 !h-9 !p-0 !gap-5 flex items-center justify-center">
												<Download size={20} strokeWidth={1.5} /> {get(data, "fileName")}
											</Button>
										</DownloadFile>
									</div>

									<div className="!space-y-2">
										<p className="text-sm uppercase">{t("Imzolangan ariza fayllari")}</p>
										<DownloadFile fileId={get(data, "signedFileId")}>
											<Button className="!shadow-none !px-5 !h-9 !p-0 !gap-5 flex items-center justify-center">
												<Download size={20} strokeWidth={1.5} /> {get(data, "signedFileName")}
											</Button>
										</DownloadFile>
									</div>

									<div className="!space-y-2">
										<p className="text-sm uppercase">{t("TXKM va tadbirkor ortasidagi ozaro shartnoma fayllari")}</p>
										<DownloadFile fileId={get(data, "mutualAgreementId")}>
											<Button className="!shadow-none !px-5 !h-9 !p-0 !gap-5 flex items-center justify-center">
												<Download size={20} strokeWidth={1.5} /> {get(data, "mutualAgreementName")}
											</Button>
										</DownloadFile>
									</div>
								</div>
							),
						},
					]}
				/>
			</div>
		</div>
	);
}

export default ApplicationView;
