import { useMutation, useQuery } from "@tanstack/react-query";
import { Plus, UploadIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { Description } from "@geist-ui/core";
import { Button, Modal, message, Input as AntdInput, Upload } from "antd";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { req } from "services/api";
import { Select } from "components/shared";
import { MapSection } from "pages/Rester/components/SaleView/components";

interface IChangeForm {
	tin?: number;
	pinfl?: string;
	terminalId?: string;
	contractFile?: string;
	serialNumber?: string;
	kkmKategoryId?: number;
	applicationFile?: string;
	answererCtoCompanyId?: number;
}

interface ITxkmAnswerer {
	id: number;
	ctoName: string;
}

interface IKkmCategory {
	id: number;
	name: string;
}

const defaultValues = {
	tin: undefined,
	pinfl: undefined,
	terminalId: undefined,
	answererCtoCompanyId: undefined,
	kkmKategoryId: undefined,
	serialNumber: undefined,
	applicationFile: undefined,
	contractFile: undefined,
};

const AddApplicationTxkmChangeModal = ({ open, onCancel }: { open: boolean; onCancel?: (args?: any) => void }) => {
	const { t, i18n } = useTranslation();
	const [isValidChecked, setIsValidChecked] = useState(false);

	const { control, getValues, handleSubmit, setValue, watch } = useForm<IChangeForm>({ mode: "all", defaultValues });

	useEffect(() => {
		watch(["answererCtoCompanyId"]);
	}, []);

	const checkDataM = useMutation({
		mutationFn: (data: any) => req("reestr")({ url: `change-cto-application/check-data`, method: "POST", data }),
	});

	const mutateAddApplicationTxkmChange = useMutation({
		mutationFn: (data: FormData) => req("reestr")({ url: `/change-cto-application/create`, method: "POST", data }),
	});

	const queryAnswererTxkm = useQuery({
		queryKey: ["queryAnswererTxkm"],
		queryFn: () => {
			return req("reestr")({
				url: `/change-cto-application/check-data/answerer-cto-list`,
				method: "GET",
				params: {
					pageNo: 0,
					pageSize: 20,
				},
			});
		},
	});

	const answererTxkmData = get(queryAnswererTxkm, "data.data.data", []) as ITxkmAnswerer[];

	const queryKkmCategoryList = useQuery({
		queryKey: ["queryKkmCategoryList", getValues().answererCtoCompanyId],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/change-cto-application/check-data/kkm-category-list/${getValues().answererCtoCompanyId}`,
				params: {
					pageNo: 0,
					pageSize: 20,
				},
			});
		},
	});

	const queryKkmCategoryData = get(queryKkmCategoryList, "data.data.data", []) as IKkmCategory[];

	const onSubmit = async (values: IChangeForm) => {
		if (!values) {
			return message.error(t(`Маълумотлар топилмади`));
		}

		const tinOrPinfl = String(values.tin).length === 9 ? "tin" : "pinfl";

		const data = {
			...values,
			[tinOrPinfl]: String(values.tin),
		};

		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			formData.append(key, value as string);
		});

		const res = await mutateAddApplicationTxkmChange.mutateAsync(formData);
		const success = get(res, "data.success", false);
		if (!success) {
			message.error(t(String(res?.data?.code) || `Kutilmagan xatolik!`));
		} else {
			message.success(t(`Qo'shildi`));
		}
	};

	const refCheckChequeData = useRef<null | {
		id: 116958;
		ctoId: 1;
		tin: "306100639";
		pinfl: null;
		legalName: 'ЧП "NONI AZIZ CHAMANIM"';
		salePointName: "NONI AZIZ CHAMANIM";
		regionNameUz: "НАВОИЙ ВИЛОЯТИ";
		regionNameRU: "НАВОИЙСКАЯ ОБЛАСТЬ";
		districtNameUz: "ХАТИРЧИ ТУМАНИ";
		districtNameRu: "ХАТЫРЧИНСКИЙ РАЙОН";
		neighborhoodNameUz: null;
		neighborhoodNameRu: null;
		address: "Galabek МФЙ  - -";
		terminalId: "UZ201125109029";
		kkmCategoryName: "UZKASSA N5";
		kkmCategoryId: 1;
		kkmItemsId: 109264;
		serialNumber: "N500W486046";
		markirovkaCode: null;
		markirovkaName: null;
		markirovkaFileId: null;
		status: 1;
		ns412Name: "Савдо фаолияти";
		ns413Name: "Озик-овкат махсулотлари савдоси";
		expiryDate: "2022-01-24 00:00:00";
		longitude: string | number;
		latitude: string | number;
	}>(null);

	const [isOpenCheckCheque, setIsOpenCheckCheque] = useState(false);
	const checkingHandler = () => {
		const body: { [key: string]: any } = {
			terminalId: getValues().terminalId,
			serialNumber: getValues().serialNumber,
			kkmKategoryId: getValues().kkmKategoryId,
			answererCtoCompanyId: getValues().answererCtoCompanyId,
		};
		const length = getValues().tin?.toString()?.trim().length;

		if (length === 14) {
			body.pinfl = String(getValues().tin || "")?.trim();
		} else if (length === 9) {
			body.tin = String(getValues().tin || "")?.trim();
		}
		checkDataM.mutate(body, {
			onSuccess: (data) => {
				if (data?.data?.success) {
					refCheckChequeData.current = get(data, "data.data", {});
					setIsOpenCheckCheque(true);
					setIsValidChecked(true);
					message.success(t(String(data?.data?.code)));
				} else {
					setIsValidChecked(false);
					message.error(t(String(data?.data?.code) || "Xatolik"));
				}
			},
			onError: (err) => {
				message.error(t("Xatolik"));
			},
		});
	};

	return (
		<>
			<Modal centered open={open} onCancel={onCancel} title={t("ТХКМ алмаштириш")} footer={false}>
				<div className="h-[20px]" />
				<div className="flex flex-col gap-5 !gap-y-5">
					<div className="grid grid-cols-2 gap-5">
						<Description
							className="!col-span-2"
							title={t("СТИР/ЖШШИР")}
							content={
								<Controller
									control={control}
									name="tin"
									render={({ field }) => {
										return (
											<PatternFormat
												placeholder="..."
												className="w-full !col-span-2"
												format="##############"
												mask={" "}
												customInput={AntdInput}
												{...field}
											/>
										);
									}}
								/>
							}
						/>
					</div>

					<div className="grid grid-cols-2 gap-5">
						<Description
							className="w-full"
							title={t("ФМга жавобгар ТХКМ")}
							content={
								<Controller
									control={control}
									name="answererCtoCompanyId"
									render={({ field }) => {
										return (
											<Select
												{...field}
												placeholder={"..."}
												className="w-full"
												defaultValue={field.value}
												options={answererTxkmData.map((txkm, idx) => {
													return {
														value: txkm.id,
														label: txkm.ctoName,
													};
												})}
											/>
										);
									}}
								/>
							}
						/>

						<Description
							className="w-full"
							title={t("Онлайн-НКТ тури")}
							content={
								<Controller
									control={control}
									name="kkmKategoryId"
									render={({ field }) => {
										return (
											<Select
												{...field}
												placeholder={"..."}
												className="w-full"
												defaultValue={field.value}
												loading={queryKkmCategoryList.isLoading}
												options={queryKkmCategoryData.map((kkm) => {
													return {
														value: kkm.id,
														label: kkm.name,
													};
												})}
											/>
										);
									}}
								/>
							}
						/>
					</div>

					<div className="grid grid-cols-2 gap-5">
						<Description
							title={t("Фискал модуль")}
							content={
								<Controller
									control={control}
									name="terminalId"
									render={({ field }) => {
										return <AntdInput placeholder="..." {...field} />;
									}}
								/>
							}
						/>

						<Description
							title={t("ОНКМ серия рақами")}
							content={
								<Controller
									control={control}
									name="serialNumber"
									render={({ field }) => {
										return <AntdInput placeholder="..." {...field} />;
									}}
								/>
							}
						/>
					</div>

					<Button loading={checkDataM.isLoading} type="primary" onClick={checkingHandler}>
						{t("Текшириш")}
					</Button>

					<div className="grid grid-cols-2 gap-5">
						<Description
							title={t("Ариза файли")}
							content={
								<Controller
									name="applicationFile"
									control={control}
									render={() => (
										<Upload
											disabled={!isValidChecked}
											maxCount={1}
											multiple={false}
											className="w-full"
											beforeUpload={(file: any) => {
												setValue("applicationFile", file);
												return false;
											}}
										>
											<Button
												icon={<UploadIcon strokeWidth={1.5} size={16} />}
												className="flex items-center justify-center"
												block
											>
												{t("Файл")}
											</Button>
										</Upload>
									)}
								/>
							}
						/>
						<Description
							title={t("Шартнома файли")}
							content={
								<Controller
									name="contractFile"
									control={control}
									render={() => (
										<Upload
											disabled={!isValidChecked}
											maxCount={1}
											multiple={false}
											beforeUpload={(file: any) => {
												setValue("contractFile", file);
												return false;
											}}
											className="w-full"
										>
											<Button
												icon={<UploadIcon strokeWidth={1.5} size={16} />}
												className="flex items-center justify-center"
												block
											>
												{t("Файл")}
											</Button>
										</Upload>
									)}
								/>
							}
						/>
					</div>

					<div className="grid grid-cols-2 gap-5">
						<Button type="primary" onClick={onCancel} danger>
							{t("Yopish")}
						</Button>
						<Button
							disabled={!isValidChecked}
							type="primary"
							onClick={handleSubmit(onSubmit)}
							loading={mutateAddApplicationTxkmChange.status === "loading"}
						>
							{t("Қўшиш")}
						</Button>
					</div>
				</div>
			</Modal>

			<Modal
				centered
				open={isOpenCheckCheque}
				onCancel={() => setIsOpenCheckCheque(false)}
				footer={false}
				width={1000}
				title={`${get(refCheckChequeData.current, "legalName", "-")} - ${get(refCheckChequeData.current, "tin", "-")}`}
			>
				<div className="h-[20px]" />

				<MapSection
					latitude={+get(refCheckChequeData.current, "latitude", 0)}
					longitude={+get(refCheckChequeData.current, "longitude", 0)}
					draggable={false}
				/>

				<div className="space-y-7">
					<div className="w-full rounded-md border !border-gray-300 overflow-hidden">
						{[
							{ title: t("Савдо шоҳобчаси номи"), value: get(refCheckChequeData.current, "salePointName", "-") },
							{
								title: t("Вилоят"),
								value: get(refCheckChequeData.current, i18n.language === "ru" ? "regionNameRU" : "regionNameUz", "-"),
							},
							{
								title: t("Туман"),
								value: get(refCheckChequeData.current, i18n.language === "ru" ? "districtNameRu" : "districtNameUz", "-"),
							},
							{ title: t("Манзил"), value: get(refCheckChequeData.current, "address", "-") },
							{ title: t("ФМ терминал"), value: get(refCheckChequeData.current, "terminalId", "-") },
							{ title: t("Онлайн-НКМ серия рақами"), value: get(refCheckChequeData.current, "serialNumber", "-") },
							{ title: t("Status"), value: get(refCheckChequeData.current, "status", 1) === 1 ? t("Фаол") : t("Фаол эмас") },
							{ title: t("Фаолият тури"), value: get(refCheckChequeData.current, "ns412Name", "-") },
							{ title: t("Фаолият тоифаси"), value: get(refCheckChequeData.current, "ns413Name", "-") },
							{ title: t("Онлайн-НКМ категорияси"), value: get(refCheckChequeData.current, "kkmCategoryName", "-") },
						].map((info, idx) => {
							return (
								<div
									key={idx}
									className={classNames({
										"w-full grid grid-cols-2 !py-2 !px-3": true,
										"bg-gray-50": idx % 2 === 0,
									})}
								>
									<div className="">{info.title}</div>
									<div className="">{info.value}</div>
								</div>
							);
						})}
					</div>
				</div>
			</Modal>
		</>
	);
};

function AddApplicationTxkmChangeM() {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				type="primary"
				onClick={() => setIsOpen(true)}
				className="truncate !shadow-none !px-3 !gap-3 !h-9 !p-0 flex items-center justify-center"
			>
				<Plus size={20} strokeWidth={1.5} /> {t("ТХКМ алмаштириш")}
			</Button>

			{isOpen && <AddApplicationTxkmChangeModal open={isOpen} onCancel={() => setIsOpen(false)} />}
		</>
	);
}

export default AddApplicationTxkmChangeM;
