import { ArrowRightLeft, Database, LayoutGrid } from "lucide-react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { IDTO, ISelect } from "types";

// import { ReactComponent as IconCash } from "assets/images/svg/common/cash_icon.svg";
// import { ReactComponent as IconCoin } from "assets/images/svg/common/coin_icon.svg";
// import { ReactComponent as IconDoc } from "assets/images/svg/common/doc_icon.svg";
// import { ReactComponent as IconHome } from "assets/images/svg/common/home_icon.svg";

export function formatNumber(num: number) {
	if (typeof num === "number") {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	return num;
}

export const useListMenu = () => {
	const { t } = useTranslation();

	return [
		{
			location: "/reestr",
			name: t("Реестр"),
			icon: Database,
		},
		{
			location: "/application/dsi",
			name: t("Аризалар"),
			icon: LayoutGrid,
		},
		{
			location: "/payments",
			name: t("FM TO`LOVLAR"),
			icon: ArrowRightLeft,
		},
	];
};

export const dtoToSelect = (res?: AxiosResponse<IDTO<ISelect[]>, Error>) => {
	if (res?.data?.success && res?.data?.data?.length) {
		return res?.data?.data?.map((item) => ({
			label: item?.name || "",
			value: item?.code,
		}));
	}
	return [];
};
