import { FC, ReactNode } from "react";

import classes from "./Button.module.scss";
import { Button as AntButton, ButtonProps } from "antd";
import cn from "classnames";

interface Props extends Omit<ButtonProps, "icon"> {
	strictlyDisabled?: boolean;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
}

const Button: FC<Props> = ({
	children,
	className,
	startIcon,
	endIcon,
	disabled,
	loading,
	onClick,
	...props
}) => {
	return (
		<AntButton
			className={cn(classes.button, className)}
			onClick={!disabled && !loading ? onClick : undefined}
			disabled={disabled}
			loading={loading}
			{...props}
		>
			{startIcon && startIcon}
			{children}
			{endIcon && endIcon}
		</AntButton>
	);
};

export default Button;
