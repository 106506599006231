import { Pie } from "@ant-design/plots";
import { formatNumber } from "utils/utils";

const WareHouseDonut = ({ total, expiryCount }: { expiryCount: number; total: number }) => {
	const data = [
		{
			type: "Актив фискал модуллар",
			value: total - expiryCount,
		},
		{
			type: "Муддати тугаган",
			value: expiryCount,
		},
	];
	const config = {
		appendPadding: 0,
		data,
		angleField: "value",
		colorField: "type",
		radius: 1,
		innerRadius: 0.7,
		tooltip: {
			shared: true,
		},
		label: {
			type: "inner",
			offset: "-50%",
			content: "{value}",
			style: {
				display: "none",
				opacity: 0,
			},
		},
		interactions: [
			{
				type: "element-selected",
			},
			{
				type: "element-active",
			},
		],
		legend: false,
		statistic: {
			title: undefined,
			content: {
				content: formatNumber(total),
				style: {
					whiteSpace: "pre-wrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					fontSize: 16,
				},
			},
		},
	};

	// @ts-ignore
	return <Pie {...config} height={200} width={300} />;
};

export default WareHouseDonut;
