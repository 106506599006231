import { useTranslation } from "react-i18next";
import { Tag } from "antd";

interface IProps {
	name?: string;
	status?: number | string;
}

export const StatusTag = ({ status, name }: IProps) => {
	const { t } = useTranslation();
	if (!name || !status) return <>-</>;

	switch (Number(status)) {
		case 1:
			return (
				<Tag className="w-full text-center" color="#0356fc">
					{name || t("Yangi")}
				</Tag>
			);
		case 2:
			return (
				<Tag className="w-full text-center" color="#FFA500">
					{name || t("Korib chiqilmoqda")}
				</Tag>
			);
		case 3:
			return (
				<Tag className="w-full text-center" color="#87d068">
					{name || t("Qabul qilindi")}
				</Tag>
			);
		case 4:
			return (
				<Tag className="w-full text-center" color="#f50">
					{name || t("Rad qilindi")}
				</Tag>
			);
		default:
			return <>-</>;
	}
};
