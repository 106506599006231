import { useQuery } from "@tanstack/react-query";
import { ArrowRightLeft, Download } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, Tabs, Tag } from "antd";
import classNames from "classnames";
import { get } from "lodash";
import CtoShoxobchaTab from "pages/Application/TxkmChange/CtoShoxobchaTab";
import { req } from "services/api";
import DownloadFile from "pages/Rester/components/OnlineNKM/DownloadFile";

export const useCtoQuery = ({ id }: { id?: string }) => {
	return useQuery({
		queryKey: ["queryCto", id],
		queryFn: () => {
			return req("reestr")({
				url: `/change-cto-application/view/${id}`,
			});
		},
	});
};

interface ICto {
	id: number;
	salePointId: number;
	status: number;
	oid: number;
	terminalId: string;
	newCtoName: string;
	oldCtoName: string;
	kkmCategoryName: string;
	serialNumber: string;
	createdDate: string;
	updatedDate: string;
	actFileName: string;
	actFileId: string;
	contractFileName: string;
	contractFileId: string;
	appFileName: string;
	appFileId: string;
	reactedFio: string;
	createdFio: string;
	answererCto: string;
}

function CtoView() {
	const { id } = useParams();
	const { t } = useTranslation();

	const queryCto = useCtoQuery({ id });

	const data = get(queryCto, "data.data.data.app", []) as ICto;

	const infos = [
		{ title: t("ID"), value: get(data, "id", "-") ?? "-" },
		{ title: t("Касса IDси"), value: get(data, "salePointId", "-") ?? "-" },
		{ title: t("Эски ТХКМ"), value: get(data, "oldCtoName", "-") ?? "-" },
		{ title: t("Янги ТХКМ"), value: get(data, "newCtoName", "-") ?? "-" },
		{ title: t("ФМ терминал"), value: get(data, "terminalId", "-") ?? "-" },
		{ title: t("Яратилган сана"), value: get(data, "createdDate", "-") ?? "-" },
		{ title: t("Ариза жунатувчи"), value: get(data, "createdFio", "-") ?? "-" },
		{ title: t("Қабул қилинган ёки рад этилган сана"), value: get(data, "updatedDate", "-") ?? "-" },
		{ title: t("Қабул қилган ёки рад этган фойдаланувчи"), value: get(data, "reactedFio", "-") ?? "-" },
		{ title: t("Фискал модул учун жавобгар ТХКМ"), value: get(data, "answererCto", "-") ?? "-" },
		{ title: t("Status"), value: String(get(data, "status", "-")) === "1" ? t("Фаол") : t("Фаол эмас") },
		{
			title: t("Имзоланган ва мухрланган ариза файли"),
			value: (
				<div className="!space-y-2">
					<DownloadFile fileId={get(data, "appFileId")}>
						<Button className="!shadow-none !px-5 !h-9 !p-0 !gap-5 flex items-center justify-center">
							<Download size={20} strokeWidth={1.5} /> {get(data, "appFileName")}
						</Button>
					</DownloadFile>
				</div>
			),
		},
		{
			title: t("ТХКМ билан тузилган шартнома файли"),
			value: (
				<div className="!space-y-2">
					<DownloadFile fileId={get(data, "contractFileId")}>
						<Button className="!shadow-none !px-5 !h-9 !p-0 !gap-5 flex items-center justify-center">
							<Download size={20} strokeWidth={1.5} /> {get(data, "contractFileName")}
						</Button>
					</DownloadFile>
				</div>
			),
		},
	];

	return (
		<div className="!px-7 !py-7">
			<div className="flex items-center !gap-5">
				<div className="bg-white rounded-md shadow-lg !w-16 !h-16 flex items-center justify-center">
					<ArrowRightLeft strokeWidth={1} size={28} />
				</div>

				<div className="flex flex-col !gap-y-2">
					<h3 className="uppercase font-normal">
						{id}. {t("Kassani TXKMni almashtirish uchun ariza")}
					</h3>
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to={`/application/txkm-almashtirish`}>{t("Asosiy")}</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/application/txkm-almashtirish`}>{t("Kassani TXKMni almashtirish")}</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{t("Kassani TXKMni almashtirish uchun ariza")}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>

			<div className="h-[40px]" />

			<div className="bg-white rounded-md shadow-lg !p-5">
				<Tabs
					defaultActiveKey="1"
					items={[
						{
							key: "1",
							label: t("Ariza ma'lumotlari"),
							children: (
								<>
									<div className="flex items-center !gap-5 !text-2xl">
										<div>
											{t("Xolati")}:{" "}
											{(() => {
												if (get(data, "status") === 1) {
													return <Tag color="#0356fc">{t("Yangi")}</Tag>;
												}

												if (get(data, "status") === 2) {
													return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
												}

												if (get(data, "status") === 3) {
													return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
												}

												if (get(data, "status") === 4) {
													return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
												}
												return null;
											})()}
										</div>
									</div>

									<div className="h-[20px]" />

									<div className="space-y-7">
										<div className="w-full rounded-md border !border-gray-300 overflow-hidden">
											{infos.map((info, idx) => {
												return (
													<div
														key={idx}
														className={classNames({
															"w-full grid grid-cols-2 !py-2 !px-3": true,
															"bg-gray-50": idx % 2 === 0,
														})}
													>
														<div className="">{info.title}</div>
														<div className="">{info.value}</div>
													</div>
												);
											})}
										</div>
									</div>
								</>
							),
						},
						{
							key: "2",
							label: t("Shoxobcha"),
							children: (
								<>
									<CtoShoxobchaTab />
								</>
							),
						},
					]}
				/>
			</div>
		</div>
	);
}

export default CtoView;
