import { useQuery } from "@tanstack/react-query";
import { request } from "services/api";

export const useGetLanguages = (options?: any) =>
	useQuery([`/information/get-languages`], async () => await request.get(`/information/get-languages`), options);

export const useGetNs10Q = (options?: any) =>
	useQuery([`/information/index/region`], async () => await request.get(`/information/index/region`), options);

export const useGetNs11Q = (ns10: number, options?: any) =>
	useQuery(
		[`/information/index/district`, ns10],
		async () => await request.get(`/information/index/district/${ns10}`),
		options
	);
export const useGetNeighborhoodQ = (ns10: number, ns11: number, options?: any) =>
	useQuery(
		[`/information/index/neighborhood`, ns10, ns11],
		async () => await request.get(`/information/index/neighborhood?regionCode=${ns10}&districtCode=${ns11}`),
		options
	);

export const useNs412Q = (options?: any) =>
	useQuery([`/information/index/ns412`], async () => await request.get(`/information/index/ns412`), options);

export const useNs413Q = (ns412: number, options?: any) =>
	useQuery([`/information/index/ns413`, ns412], async () => await request.get(`/information/index/ns413/${ns412}`), options);

export const useScannerListQ = (options?: any) =>
	useQuery([`/ofd-main/scanner-list`], async () => await request.get(`/ofd-main/scanner-list`), options);

export const useKKMCategoryQ = (options?: any) =>
	useQuery([`/cto-kkm-category/index/active`], async () => await request.get(`/cto-kkm-category/index/active`), options);

export const useSalePointListQ = (options?: any) =>
	useQuery(
		[`/sale-point/index?pageNo=0&pageSize=100000`],
		async () => await request.get(`/sale-point/index?pageNo=0&pageSize=100000`),
		options
	);

export const useSalePointListForFilterQ = (options?: any) =>
	useQuery([`/information/index/sale-point`], async () => await request.get(`/information/index/sale-point`), options);

export const useInformationOfdMainDeregistryReasonsQ = (options?: any) =>
	useQuery(
		[`/information/ofd-main/deregistry-reasons`],
		async () => await request.get(`/information/ofd-main/deregistry-reasons`),
		options
	);

export const useInformationApplicationTypesFmoQ = (options?: any) =>
	useQuery(
		[`/information/application-types/fmo`],
		async () => await request.get(`/information/application-types/fmo`),
		options
	);

export const useInformationApplicationStatusFmoQ = (options?: any) =>
	useQuery(
		[`/information/application-status/fmo`],
		async () => await request.get(`/information/application-status/fmo`),
		options
	);

export const useInformationOfdMainFiscalChangeReasonsQ = (options?: any) =>
	useQuery(
		[`/information/ofd-main/fiscal-change/reasons`],
		async () => await request.get(`/information/ofd-main/fiscal-change/reasons`),
		options
	);
