import { memo } from "react";

const Excel = ({ ...props }: any) => (
	<svg width={32} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x={8} width={24} height={28} rx={2} fill="#2FB776" />
		<path d="M8 21h24v5a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-5Z" fill="url(#a)" />
		<path fill="#229C5B" d="M20 14h12v7H20z" />
		<path fill="#27AE68" d="M20 7h12v7H20z" />
		<path d="M8 2a2 2 0 0 1 2-2h10v7H8V2Z" fill="#1D854F" />
		<path fill="#197B43" d="M8 7h12v7H8z" />
		<path fill="#1B5B38" d="M8 14h12v7H8z" />
		<path
			d="M8 10a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H8V10Z"
			fill="#000"
			fillOpacity={0.3}
		/>
		<rect y={5} width={18} height={18} rx={2} fill="url(#b)" />
		<path
			d="m13 19-2.818-5.1L12.876 9h-2.199l-1.663 3.129L7.378 9H5.11l2.708 4.9L5 19h2.2l1.773-3.314L10.732 19H13Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient
				id="a"
				x1={8}
				y1={24.5}
				x2={32}
				y2={24.5}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#163C27" />
				<stop offset={1} stopColor="#2A6043" />
			</linearGradient>
			<linearGradient
				id="b"
				x1={0}
				y1={14}
				x2={18}
				y2={14}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#185A30" />
				<stop offset={1} stopColor="#176F3D" />
			</linearGradient>
		</defs>
	</svg>
);

export default memo(Excel);
