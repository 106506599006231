export default function RefreshCcv(props: any) {
	return (
		<svg
			width="28"
			height="23"
			viewBox="0 0 28 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.28466 10.1805C1.33899 10.2078 1.39333 10.2352 1.44767 10.2898C1.55634 10.3991 1.77369 10.3991 1.88236 10.3991H8.40281C9.05486 10.3991 9.48956 9.96189 9.48956 9.30604C9.48956 8.65018 9.05486 8.21295 8.40281 8.21295H4.59922L7.75077 5.37093C8.62016 4.38715 9.81558 3.7313 11.011 3.29407C13.1845 2.52891 15.5753 2.63822 17.6401 3.62199C19.7049 4.71508 21.335 6.46402 22.0958 8.65018C22.3131 9.19673 22.9652 9.52465 23.5085 9.30604C24.0519 9.08742 24.3779 8.43157 24.1606 7.88503C23.1825 5.15231 21.2264 2.96614 18.6182 1.65444C16.01 0.452048 13.0758 0.233431 10.359 1.21721C8.83751 1.76375 7.42475 2.63822 6.33801 3.7313L3.07778 6.79194V2.74753C3.07778 2.09168 2.64308 1.65444 1.99104 1.65444C1.33899 1.65444 0.904297 2.09168 0.904297 2.74753V9.41534V9.52465V9.74327C0.904297 9.79792 0.931465 9.82525 0.958634 9.85258C0.985803 9.8799 1.01297 9.90723 1.01297 9.96189C1.12165 9.96189 1.12165 10.0712 1.12165 10.0712C1.17598 10.1258 1.23032 10.1532 1.28466 10.1805ZM26.8771 13.2411V13.4598C26.8771 13.5691 26.8771 13.5691 27.0945 13.6784V20.2369C27.0945 20.8927 26.6598 21.33 26.0077 21.33C25.3557 21.33 24.921 20.8927 24.921 20.2369V16.1925L21.6608 19.2531C19.596 21.33 16.8791 22.423 14.0536 22.423C11.2281 22.423 8.5112 21.4393 6.33772 19.2531C5.1423 18.16 4.27291 16.739 3.72954 15.2087C3.51219 14.6621 3.83821 14.0063 4.38158 13.7877C4.92495 13.5691 5.577 13.897 5.79435 14.4435C6.22904 15.6459 6.98976 16.8483 7.85916 17.7228C11.2281 21.1113 16.7704 21.1113 20.1393 17.7228L23.1822 14.7715H19.3786C18.7266 14.7715 18.2919 14.3342 18.2919 13.6784C18.2919 13.0225 18.7266 12.5853 19.3786 12.5853H25.8991H26.1164H26.3338L26.4424 12.6946L26.6598 12.9132C26.7685 12.9132 26.7685 13.0225 26.7685 13.0225C26.7685 13.0772 26.7956 13.1045 26.8228 13.1318C26.85 13.1592 26.8771 13.1865 26.8771 13.2411Z"
				fill="#F6F6F7"
			/>
			<mask
				id="mask0_2_162608"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="28"
				height="23"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.28466 10.1805C1.33899 10.2078 1.39333 10.2352 1.44767 10.2898C1.55634 10.3991 1.77369 10.3991 1.88236 10.3991H8.40281C9.05486 10.3991 9.48956 9.96189 9.48956 9.30604C9.48956 8.65018 9.05486 8.21295 8.40281 8.21295H4.59922L7.75077 5.37093C8.62016 4.38715 9.81558 3.7313 11.011 3.29407C13.1845 2.52891 15.5753 2.63822 17.6401 3.62199C19.7049 4.71508 21.335 6.46402 22.0958 8.65018C22.3131 9.19673 22.9652 9.52465 23.5085 9.30604C24.0519 9.08742 24.3779 8.43157 24.1606 7.88503C23.1825 5.15231 21.2264 2.96614 18.6182 1.65444C16.01 0.452048 13.0758 0.233431 10.359 1.21721C8.83751 1.76375 7.42475 2.63822 6.33801 3.7313L3.07778 6.79194V2.74753C3.07778 2.09168 2.64308 1.65444 1.99104 1.65444C1.33899 1.65444 0.904297 2.09168 0.904297 2.74753V9.41534V9.52465V9.74327C0.904297 9.79792 0.931465 9.82525 0.958634 9.85258C0.985803 9.8799 1.01297 9.90723 1.01297 9.96189C1.12165 9.96189 1.12165 10.0712 1.12165 10.0712C1.17598 10.1258 1.23032 10.1532 1.28466 10.1805ZM26.8771 13.2411V13.4598C26.8771 13.5691 26.8771 13.5691 27.0945 13.6784V20.2369C27.0945 20.8927 26.6598 21.33 26.0077 21.33C25.3557 21.33 24.921 20.8927 24.921 20.2369V16.1925L21.6608 19.2531C19.596 21.33 16.8791 22.423 14.0536 22.423C11.2281 22.423 8.5112 21.4393 6.33772 19.2531C5.1423 18.16 4.27291 16.739 3.72954 15.2087C3.51219 14.6621 3.83821 14.0063 4.38158 13.7877C4.92495 13.5691 5.577 13.897 5.79435 14.4435C6.22904 15.6459 6.98976 16.8483 7.85916 17.7228C11.2281 21.1113 16.7704 21.1113 20.1393 17.7228L23.1822 14.7715H19.3786C18.7266 14.7715 18.2919 14.3342 18.2919 13.6784C18.2919 13.0225 18.7266 12.5853 19.3786 12.5853H25.8991H26.1164H26.3338L26.4424 12.6946L26.6598 12.9132C26.7685 12.9132 26.7685 13.0225 26.7685 13.0225C26.7685 13.0772 26.7956 13.1045 26.8228 13.1318C26.85 13.1592 26.8771 13.1865 26.8771 13.2411Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_2_162608)">
				<rect
					x="0.904297"
					y="-1.58594"
					width="26.1905"
					height="26.1905"
					fill="#F6F6F7"
				/>
			</g>
		</svg>
	);
}
