import { GeolocationControl } from "@pbe/react-yandex-maps";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Plus, UploadIcon } from "lucide-react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { Description, Input } from "@geist-ui/core";
import { Button, Modal, message, Input as AntdInput, Upload, Spin } from "antd";
import { get, isEmpty } from "lodash";
import { req, request } from "../../../services/api";
import YMap from "../../../components/YMap";
import SelectTuman from "../../Rester/components/BranchesView/SelectTuman";
import SelectViloyat from "../../Rester/components/BranchesView/SelectViloyat";

interface IPartnerForm {
	tin: number;
	userPinfl: string;
	ns10Code: number;
	ns11Code: number;
	address: string;
	longitude: string;
	latitude: string;
	phone: string;
	description: string;
	file: string;
}

function AddTxkmPartnerModal() {
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const { control, register, handleSubmit, watch, setValue, resetField } = useForm<IPartnerForm>();
	const selectedViloyat = watch("ns10Code");
	const [latitude, setLatitude] = useState<number | null>(41.2995);
	const [longitude, setLongitude] = useState<number | null>(69.2401);
	const locationChangeRef = useRef<any>(null);
	const methods = useForm<any>({
		defaultValues: {
			reestr: "new",
		},
	});

	const mutateAddPartner = useMutation({
		mutationFn: (data: FormData) => {
			return request({
				method: "POST",
				url: `/applications-cto/add-partner`,
				data: data,
			});
		},
	});

	const onSubmit = async (values: IPartnerForm) => {
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (key === "phone") {
				return formData.append(key, `998${value.replaceAll(" ", "")}`);
			}
			formData.append(key, value);
		});
		formData.append("longitude", `${longitude}`);
		formData.append("latitude", `${latitude}`);

		const res = await mutateAddPartner.mutateAsync(formData);
		const success = get(res, "data.success", false);

		if (!success) {
			const errorCode = get(res, "data.code", "-");

			message.error(t(errorCode));
		} else {
			message.success(t(`Qo'shildi`));
			setIsOpen(false);
		}
	};

	const pinfl = watch("userPinfl");
	const pinflLength = String(pinfl).trim().length;

	const queryUserByPinfl = useQuery({
		queryKey: ["queryUser", pinfl],
		queryFn: () => {
			return req("reestr")({
				url: `/information/get-physical-person-by-pinfl/${pinfl}`,
			});
		},
		enabled: pinflLength === 14,
	});

	const userByPinfl = get(queryUserByPinfl, "data.data.data", {}) as {
		fio: string;
		pinfl: string;
	};

	return (
		<>
			<Button
				type="primary"
				onClick={() => setIsOpen(true)}
				className="truncate !shadow-none w-44 !gap-3 !h-9 !p-0 flex items-center justify-center"
			>
				<Plus size={20} strokeWidth={1.5} /> {t("Шерик")}
			</Button>

			<Modal open={isOpen} onCancel={() => setIsOpen(false)} title={t("Шерик кўшиш")} footer={false}>
				<div className="h-[20px]" />
				<div className="flex flex-col gap-5">
					<div className="grid grid-cols-2 gap-5">
						<Description
							title={t("СТИРи/ЖИШШРи")}
							content={
								<Controller
									control={control}
									name="tin"
									render={({ field }) => {
										return (
											<>
												<PatternFormat
													placeholder="..."
													format="##############"
													mask={" "}
													customInput={AntdInput}
													{...field}
												/>
											</>
										);
									}}
								/>
							}
						/>
						<Description
							title={t("Фойдаланувчиси ЖИШШРи")}
							content={
								<Controller
									control={control}
									name="userPinfl"
									render={({ field }) => {
										return (
											<>
												<PatternFormat
													placeholder="..."
													format="##############"
													mask={" "}
													customInput={AntdInput}
													{...field}
												/>
												{(() => {
													if (pinflLength !== 14) {
														return null;
													}

													if (queryUserByPinfl.isLoading) {
														return <Spin spinning={true} size="small" />;
													}

													if (isEmpty(userByPinfl)) {
														return <div className="!pt-3">{t("User topilmadi!")}</div>;
													}

													return (
														<div className="!pt-3">
															{t("Ф.И.Ш.")}: {userByPinfl.fio}
														</div>
													);
												})()}
											</>
										);
									}}
								/>
							}
						/>
					</div>

					<div className="h-[20px]" />
					<div className="grid grid-cols-2 gap-5">
						<Controller
							name="ns10Code"
							control={control}
							render={({ field }) => {
								return (
									<SelectViloyat
										{...field}
										onChange={(value) => {
											field.onChange(value);
											resetField("ns11Code");
										}}
									/>
								);
							}}
						/>
						<Controller
							name="ns11Code"
							control={control}
							render={({ field }) => {
								return (
									<SelectTuman
										{...field}
										selectedViloyat={selectedViloyat}
										onChange={(value) => {
											field.onChange(value);
										}}
									/>
								);
							}}
						/>
					</div>

					<div className="col-span-2">
						<Description
							title={t("Манзил")}
							content={
								<Controller
									control={control}
									name="address"
									render={({ field }) => {
										return <Input placeholder="..." width={"100%"} {...field} />;
									}}
								/>
							}
						/>
					</div>
					<div>
						<div> {t("Геолокация магазина")}</div>
						<div className="!h-[450px] !w-full">
							<YMap
								latitude={latitude}
								longitude={longitude}
								setLat={setLatitude}
								setLong={setLongitude}
								onClick={(e: any) => {
									const [lat, long] = e.get("coords");
									setLatitude(lat);
									setLongitude(long);
									methods.setValue("latitude", lat);
									methods.setValue("longitude", long);
								}}
							>
								{" "}
								<GeolocationControl instanceRef={locationChangeRef} options={{ float: "left" }} />
							</YMap>
						</div>
					</div>
					<div className="h-[20px]" />
					<div className="col-span-2">
						<Description
							title={t("Телефон")}
							content={
								<Controller
									control={control}
									name="phone"
									render={({ field }) => {
										return (
											<PatternFormat
												format="## ### ## ##"
												mask={" "}
												placeholder="..."
												customInput={AntdInput}
												addonBefore="+998"
												{...field}
											/>
										);
									}}
								/>
							}
						/>
					</div>
					<div className="col-span-2">
						<Description
							title={t("Қўшимча изоҳ")}
							content={
								<Controller
									control={control}
									name="description"
									render={({ field }) => {
										return <Input placeholder="..." width={"100%"} {...field} />;
									}}
								/>
							}
						/>
					</div>
					<div className="col-span-1">
						<Description
							title={t("Тегишли ҳужжатлар")}
							content={
								<Controller
									name="file"
									control={control}
									render={({ field }: any) => (
										<>
											<Upload
												maxCount={1}
												multiple={false}
												beforeUpload={(file: any) => {
													setValue("file", file);
													return false;
												}}
												className="w-full"
											>
												<Button
													icon={<UploadIcon strokeWidth={1.5} size={16} />}
													className="flex items-center justify-center"
													block
												>
													{t("Файл")}
												</Button>
											</Upload>
										</>
									)}
								/>
							}
						/>
					</div>

					<div className="h-[20px]" />
					<div className="grid grid-cols-2 gap-5">
						<Button type="primary" onClick={() => setIsOpen(false)} danger>
							{t("Yopish")}
						</Button>
						<Button type="primary" onClick={handleSubmit(onSubmit)} loading={mutateAddPartner.status === "loading"}>
							{t("Қўшиш")}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default AddTxkmPartnerModal;
