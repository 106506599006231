import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "../Style.module.scss";

interface Props {
	latitude: number | null;
	setLat?: any;
	longitude: number | null;
	setLong?: any;
	draggable?: boolean;
}

const MapSection = ({ latitude, setLat, longitude, setLong, draggable = true }: Props) => {
	let lat = latitude ? latitude : 41.31187985771251;
	let long = longitude ? longitude : 69.27981817707665;
	const defaultState = {
		center: [Number(latitude), Number(longitude)],
		zoom: 12,
		controls: ["zoomControl", "fullscreenControl"],
	};

	return (
		<YMaps
			query={{
				apikey: "f89fcd6e-02eb-46fc-a449-8ff53f633b7e",
				// ns: "use-load-option",
				load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
				lang: "en_RU",
			}}
		>
			<Map
				style={{ width: "100%", height: 370 }}
				defaultState={defaultState}
				modules={["control.ZoomControl", "control.FullscreenControl"]}
			>
				<Placemark
					geometry={[lat, long]}
					options={{ draggable }}
					onDragEnd={(e: any) => {
						const coordinates = e.get("target").geometry.getCoordinates();
						const [latitude, longitude] = coordinates;
						setLat(latitude);
						setLong(longitude);
					}}
				/>
			</Map>
		</YMaps>
	);
};

export default MapSection;
