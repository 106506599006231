import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import classes from "./Style.module.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Modal, Row, Select, message } from "antd";
import { get } from "lodash";
import * as yup from "yup";
import { request } from "services/api";
import { useNs412Q, useNs413Q } from "hooks/queries/general";

interface IProps {
	createModal: boolean;
	setCreateModal: Dispatch<SetStateAction<boolean>>;
}

const schema = yup.object().shape({
	ns412: yup.string().required(),
	ns413: yup.string().required(),
});

const CreateM = ({ createModal, setCreateModal }: IProps) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();

	const { control, handleSubmit, reset, watch } = useForm({ resolver: yupResolver(schema) });

	const ns412 = useNs412Q();
	const ns412List = get(ns412, "data.data.data", [])?.map((item: any) => ({
		value: item?.code,
		label: item?.name,
	}));
	const ns413 = useNs413Q(Number(watch("ns412")), {
		enabled: !!Number(watch("ns412")),
	});

	const ns413List = get(ns413, "data.data.data", [])?.map((item: any) => ({
		value: item?.code,
		label: item?.name,
	}));

	const updateData = useMutation((body: any) => request.post(`/sale-point/supplementary-activities/create`, body), {
		onSuccess: (res: any) => {
			if (res?.data?.success) {
				queryClient.invalidateQueries(["/sale-point/supplementary-activities"]);
				message.success(t(`Успешно`));
			} else message.error(t("Хатолик юз берди"));
			setCreateModal(false);
			reset();
		},

		onError: (err: any) => {
			message.error(err);
		},
	});

	const onSubmit = (data: any) => {
		const { ns412, ns413 } = data;
		updateData.mutate({
			salePointId: id,
			ns412Code: ns412,
			ns413Code: ns413,
		});
	};

	const onCancel = () => {
		reset();
		setCreateModal(false);
	};

	return (
		<Modal footer={false} width={800} open={createModal} onCancel={onCancel}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.modal}>
					<h3 className={classes.add_title}>{t("Қўшимча фаолият тури қўшиш")}</h3>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<p>{t("Фаолият тури")}</p>
						</Col>
						<Col span={12}>
							<Controller
								name="ns412"
								control={control}
								render={({ field }) => (
									<Select style={{ width: "100%" }} placeholder={t("Танланг")} {...field} options={ns412List} />
								)}
							/>
						</Col>
						<Col span={12}>
							<p>{t("Фаолият тоифаси")}</p>
						</Col>
						<Col span={12}>
							<Controller
								name="ns413"
								control={control}
								render={({ field }) => (
									<Select style={{ width: "100%" }} placeholder={t("Танланг")} {...field} options={ns413List} />
								)}
							/>
						</Col>
					</Row>

					<Row gutter={[20, 20]} justify={"center"} style={{ marginTop: "30px" }}>
						<Col>
							<Button onClick={onCancel}>{t("Бекор қилиш")}</Button>
						</Col>
						<Col>
							<Button type="primary" htmlType="submit">
								{t("Қўшиш")}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		</Modal>
	);
};

const CreateSection = ({ onAdd }: any) => {
	const { t } = useTranslation();
	const [createModal, setCreateModal] = useState(false);

	useEffect(() => {
		if (!createModal) {
			onAdd();
		}
	}, [createModal]);

	return (
		<div className={classes.create}>
			<Button type="primary" className={classes.add} onClick={() => setCreateModal(true)}>
				{t("Қўшимча фаолият турлари")} <PlusCircleOutlined rev="" twoToneColor="#fff" />
			</Button>

			{createModal && <CreateM {...{ createModal, setCreateModal }} />}
		</div>
	);
};

export default CreateSection;
