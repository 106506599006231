import { useMutation } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import { get } from "lodash";
import { req } from "services/api";

function SendToFixing({ id, onSuccess }: { id: string; onSuccess: () => unknown }) {
	const { t } = useTranslation();
	const mutateSendToFixing = useMutation({
		mutationKey: ["mutateSendToFixing", id],
		mutationFn: () => {
			return req("reestr")({
				url: `/application-invalid-cash/accept/${id}`,
				method: "POST",
			});
		},
	});

	const onAccept = async () => {
		try {
			const res = await mutateSendToFixing.mutateAsync();

			const success = get(res, "data.success", false);
			if (!success) {
				const errorCode = get(res, "data.code", "-");

				message.error(t(errorCode));
			} else {
				message.success(t(`Qo'shildi`));
				onSuccess();
			}
		} catch (error) {
			message.error(t(`Xatolik!`));
		}
	};
	return (
		<Button
			loading={mutateSendToFixing.isLoading}
			onClick={onAccept}
			className="!shadow-none !w-9 !h-9 !p-0 flex items-center justify-center"
		>
			<Check size={20} strokeWidth={1.5} />
		</Button>
	);
}

export default SendToFixing;
