import React, { useLayoutEffect, useRef } from "react";

function ErrorText({ children, ...rest }: { children: React.ReactNode }) {
	const ref = useRef<HTMLDivElement>(null);
	const refP = useRef<HTMLParagraphElement>(null);

	useLayoutEffect(() => {
		if (ref.current && refP.current) {
			if (children) {
				ref.current.style.height = `${refP.current.offsetHeight}px`;
			} else {
				ref.current.style.height = `0`;
			}
		}
	});

	return (
		<div ref={ref} className="overflow-hidden !my-0 duration-200 h-0 !pl-1">
			<p ref={refP} className="text-sm text-rose-500 !pt-1" {...rest}>
				{children}
			</p>
		</div>
	);
}

export default ErrorText;
