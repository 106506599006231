import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "@geist-ui/core";
import axios from "axios";
import { get } from "lodash";
import { req } from "services/api";

function Backdoor() {
	const { type, tinOrPinfl } = useParams();

	const mutateToken = useMutation({
		mutationKey: ["mutateToken"],
		mutationFn: () => {
			return axios({
				url: `${process.env.REACT_APP_API_URL}/user/auth/backdoor/${tinOrPinfl}`,
			});
		},
		retry: 0,
	});

	useEffect(() => {
		(async () => {
			const res = await mutateToken.mutateAsync();

			const token = get(res, "data.access_token", null);
			const refresh_token = get(res, "data.refresh_token", null);

			if (token) {
				localStorage.setItem("token", token);
				localStorage.setItem("refresh_token", refresh_token);

				window.location.pathname = `/`;
			}
		})();
	}, []);

	return (
		<div className="flex items-center justify-center h-screen w-full">
			<Spinner scale={1} />
		</div>
	);
}

export default Backdoor;
