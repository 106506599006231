import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import classes from "../Style.module.scss";
import { Col, Row, Space } from "antd";
import classNames from "classnames";
import QueryHook from "utils/queryHook";
import { Button, Input, Select } from "components/shared";
import LabelForInput from "components/shared/LabelForInput";

const TxkmXamkorFilterBtn = () => {
	const { t } = useTranslation();
	const { MergeQueryParams, AddQueryParams } = QueryHook();
	const { handleSubmit, control, reset } = useForm();

	const onSubmit = (values: Record<string, string | null>) => {
		MergeQueryParams({ ...values });
	};

	const filterClear = () => {
		AddQueryParams({});
		reset();
	};

	return (
		<>
			<div
				className={classNames({
					[classes.filter_box]: true,
				})}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row gutter={[20, 20]}>
						<Col span={4}>
							<Controller
								name="tin"
								control={control}
								render={({ field }) => <NumericFormat maxLength={14} customInput={Input} label={t("СТИР")} {...field} />}
							/>
						</Col>

						<Col span={4}>
							<Controller
								name="pinfl"
								control={control}
								render={({ field }) => <NumericFormat maxLength={14} customInput={Input} label={t("ЖШШИР")} {...field} />}
							/>
						</Col>

						<Col span={4}>
							<Controller
								name="status"
								control={control}
								render={({ field }) => (
									<LabelForInput label={t("Статус")}>
										<Select
											size="small"
											{...field}
											options={[
												{ value: "1", label: t("Yangi") },
												{ value: "2", label: t("Korib chiqilmoqda") },
												{ value: "3", label: t("Qabul qilindi") },
												{ value: "4", label: t("Rad qilindi") },
											]}
										/>
									</LabelForInput>
								)}
							/>
						</Col>
						<Col>
							<Space>
								<Button onClick={filterClear}>{t("Тозалаш")}</Button>
								<Button htmlType="submit" type="primary">
									{t("Қидириш")}
								</Button>
							</Space>
						</Col>
					</Row>
				</form>
			</div>
		</>
	);
};

export default TxkmXamkorFilterBtn;
