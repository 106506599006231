export default function AttachInterface(props: any) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g opacity="0.3" clipPath="url(#clip0_3_86134)">
				<path
					d="M10.007 3.35727C9.85465 3.35727 9.71699 3.41432 9.60915 3.50547L9.60347 3.49981L4.74409 8.34248C4.19341 8.89303 3.96762 9.43018 3.96473 10.197C3.96473 10.7967 4.18633 11.3362 4.58858 11.7161C5.00402 12.1082 5.581 12.3156 6.25741 12.3154H6.2716C6.97567 12.3123 7.63448 12.0352 8.12609 11.5361L13.7646 5.89892C15.1927 4.47068 15.2301 2.43517 13.8537 1.05862C13.171 0.375983 12.2621 0 11.2943 0C10.3265 0 9.41781 0.375983 8.73562 1.05867L1.67355 8.11974C-0.126861 9.92018 -0.126861 12.8498 1.67355 14.6502C2.5443 15.5207 3.70379 16 4.93843 16C6.17309 16 7.33267 15.5208 8.20378 14.6501L15.5148 7.31325C15.5148 7.31325 15.6759 7.15488 15.6759 6.88774C15.6759 6.54546 15.3984 6.26791 15.0561 6.26791C14.8934 6.26791 14.7465 6.332 14.6359 6.43459L14.634 6.4327L7.32286 13.7697C6.68694 14.4052 5.84009 14.7553 4.9383 14.7553C4.0365 14.7553 3.18979 14.4052 2.5541 13.7697C1.23925 12.4543 1.23925 10.3149 2.5541 9.00043L9.61617 1.93946C10.0637 1.49197 10.6596 1.24557 11.2943 1.24557C11.929 1.24557 12.5253 1.49207 12.9731 1.93946C13.4198 2.38609 13.6443 2.91666 13.6225 3.47405C13.601 4.0226 13.3457 4.55659 12.884 5.01829L7.23371 10.6674C6.9754 10.926 6.63197 11.0691 6.26719 11.07C5.5733 11.07 5.21028 10.6328 5.21028 10.2015C5.21167 9.83708 5.35458 9.49377 5.61289 9.23507L10.4841 4.38061L10.4786 4.37508C10.5697 4.26721 10.6268 4.12958 10.6268 3.97723C10.6268 3.63469 10.3493 3.35727 10.007 3.35727Z"
					fill="#717C8C"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3_86134">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
