import { useTranslation } from "react-i18next";
import { Segmented } from "antd";
import useAutoReducer from "hooks/useAutoReducer";
import ApplicationTxkmPartners from "pages/Application/Txkm/ApplicationTxkmPartners";
import AddTxkmEmployeeModal from "pages/Application/Txkm/AddTxkmEmployeeModal";
import AddTxkmFilialModal from "pages/Application/Txkm/AddTxkmFilialModal";
import AddTxkmPartnerModal from "pages/Application/Txkm/AddTxkmPartnerModal";
import ApplicationTxkmEmployeeList from "pages/Application/Txkm/ApplicationTxkmEmployeeList";
import ApplicationTxkmFilials from "pages/Application/Txkm/ApplicationTxkmFilials";

type TTxkmListTabs = "users" | "filial" | 'partners';

const ApplicationTxkmList = () => {
	const { t } = useTranslation();

	const [state, dispatch] = useAutoReducer({
		tab: "users" as TTxkmListTabs,
	});

	return (
		<div>
			<div className="flex justify-between gap-20">
				<div>
					<Segmented
						onChange={(tab) => dispatch({ param: tab as TTxkmListTabs, type: "tab" })}
						value={state.tab}
						options={[
							{
								label: <div style={{ padding: 4, width: 200 }}>{t("Ходимлар")}</div>,
								value: "users" satisfies TTxkmListTabs,
							},
							{
								label: <div style={{ padding: 4, width: 200 }}>{t("Филиал")}</div>,
								value: "filial" satisfies TTxkmListTabs,
							},
							{
								label: <div style={{ padding: 4, width: 200 }}>{t("Xамкорлар")}</div>,
								value: "partners" satisfies TTxkmListTabs,
							},
						]}
					/>
				</div>


				<div className="flex items-center !gap-5">
					<AddTxkmEmployeeModal />

					<AddTxkmFilialModal />

					<AddTxkmPartnerModal />
				</div>
			</div>

			<div className="h-[20px]" />


			{(() => {
				switch (state.tab) {
					case "users":
						return <ApplicationTxkmEmployeeList />;
					case "filial":
						return <ApplicationTxkmFilials />;
					case "partners":
						return <ApplicationTxkmPartners />;
					default:
						return null;
				}
			})()}
		</div>
	);
};

export default ApplicationTxkmList;
