import { useQuery } from "@tanstack/react-query";
import { Check, Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { get } from "lodash";
import { ColumnsType } from "antd/es/table";
import { req } from "services/api";
import useAutoReducer from "hooks/useAutoReducer";
import { Button, Pagination, Table } from "components/shared";
import CompleteBrokenNkm from "pages/Application/components/CompleteBrokenNkm";
import SendToFixing from "pages/Application/components/SendToFixing";

interface IFilialFmChange {
	pkey: string;
	tin: number;
	status: number;
	pinfl: string | null;
	terminalId: string;
	createdDate: string;
}

const SIZE = 20;

function NkmNosoz() {
	const { t } = useTranslation();
	const [state, dispatch] = useAutoReducer({
		page: 1,
	});

	const queryFilialFmChange = useQuery({
		queryKey: ["queryFilialFmChange", state.page],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/application-invalid-cash/index`,
				params: {
					pageNo: state.page - 1,
					pageSize: SIZE,
				},
			});
		},
	});

	const data = get(queryFilialFmChange, "data.data.data", []) as IFilialFmChange[];
	const total = get(queryFilialFmChange, "data.data.recordsTotal", 0);

	const columns: ColumnsType<IFilialFmChange> = [
		{
			width: 50,
			dataIndex: "number",
			title: "№",
			render: (data, mock, i) => <>{Number(state.page - 1) * Number(SIZE) + i + 1}</>,
		},
		{
			title: t("СТИР"),
			dataIndex: "tin",
		},
		{
			title: t("ЖШШИР"),
			dataIndex: "pinfl",
		},
		{
			title: t("Корҳона номи"),
			dataIndex: "legalName",
		},
		{
			title: t("ФМ терминал"),
			dataIndex: "terminalId",
		},
		{
			title: t("Сабаб"),
			dataIndex: "reason",
		},
		{
			title: t("Яратилган сана"),
			dataIndex: "createdDate",
		},
		{
			title: t("Ўзгартирилган сана"),
			dataIndex: "updatedDate",
		},
		{
			title: t("Статус"),
			dataIndex: "status",
			align: "center",
			render(value, record, index) {
				if (get(record, "status") === 1) {
					return <Tag color="#0356fc">{t("Yangi")}</Tag>;
				}

				if (get(record, "status") === 2) {
					return <Tag color="#FFA500">{t("Korib chiqilmoqda")}</Tag>;
				}

				if (get(record, "status") === 3) {
					return <Tag color="#87d068">{t("Qabul qilindi")}</Tag>;
				}

				if (get(record, "status") === 4) {
					return <Tag color="#f50">{t("Rad qilindi")}</Tag>;
				}
				return null;
			},
		},
		{
			title: " ",
			dataIndex: "status",
			align: "center",
			width: 50,
			render(value, record, index) {
				if (value === 0) {
					return <SendToFixing onSuccess={() => queryFilialFmChange.refetch()} id={record.pkey} />;
				}

				if (value === 1) {
					return <CompleteBrokenNkm data={record} onSuccess={() => queryFilialFmChange.refetch()} id={record.pkey} />;
				}

				return "-";
			},
		},
	];
	return (
		<>
			<Table columns={columns} dataSource={data} />

			<div className="h-[20px]" />

			<Pagination
				pageSize={SIZE}
				current={state.page}
				total={total}
				onChange={(page) => dispatch({ type: "page", param: page })}
			/>
		</>
	);
}

export default NkmNosoz;
