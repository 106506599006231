import { Pie } from "@ant-design/plots";

interface IProps {
	data: {
		type: string;
		value: number;
	}[];
	colors?: string[];
	content: string;
}

const SimpletPercentageDonut = ({ colors, data, content }: IProps) => {
	const config = {
		appendPadding: 10,
		legend: false,
		data,
		color: colors,
		angleField: "value",
		colorField: "type",
		radius: 1,
		innerRadius: 0.7,
		label: undefined,
		plot: "OFF",
		interactions: [
			{
				type: "element-selected",
			},
			{
				type: "element-active",
			},
		],
		tooltip: false,
		statistic: {
			title: undefined,
			content: {
				content: content,
				style: {
					whiteSpace: "pre-wrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					fontSize: 12,
				},
			},
		},
	};

	// @ts-ignore
	return <Pie {...config} height={90} />;
};

export default SimpletPercentageDonut;
