import { FC } from "react";
import classes from "./Pagination.module.scss";
import "./Pagination.module.scss";
import useItemRender from "./useItemRender";
import { Pagination, PaginationProps } from "antd";

type Props = PaginationProps & {};

const AntPagination: FC<Props> = ({ ...props }) => {
	const { itemRender } = useItemRender();

	return <Pagination className={classes.pagination} itemRender={itemRender} showSizeChanger={false} {...props} />;
};

export default AntPagination;
