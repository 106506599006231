import { useTranslation } from "react-i18next";

import classes from "./Footer.module.scss";

import Container from "components/shared/Container";

import { ReactComponent as CallCenter } from "assets/images/svg/common/call_center_icon.svg";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className={classes.footer}>
			<Container>
				<div>
					<p>{t("2023 © YANGI TEXNOLOGIYALAR ILMIY-AXBOROT MARKAZI")}</p>
					<h3>
						<CallCenter /> <span>{t("Колл-центр")}:</span> <p>1198</p>
					</h3>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
