import { ThemeConfig } from "antd/es/config-provider/context";

// https://ant.design/docs/react/customize-theme#api
export const globalAntdConfig: ThemeConfig = {
	token: {
		colorPrimary: "#123579",
		fontFamily: `PT Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif`,
	},
};

export const config = {
	languages: ["ru", "uz"],
} as const;
