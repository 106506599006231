import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

interface Props {
	latitude: number | null;
	setLat: any;
	longitude: number | null;
	setLong: any;
	children?: React.ReactNode;
	onClick?: any;
	draggable?: boolean;
}

const YMap = ({ latitude, setLat, longitude, setLong, children, onClick, draggable = true }: Props) => {
	let lat = latitude ? latitude : 41.31187985771251;
	let long = longitude ? longitude : 69.27981817707665;

	const defaultState = {
		center: [Number(latitude), Number(longitude)],
		zoom: 12,
		controls: ["zoomControl", "fullscreenControl"],
	};

	return (
		<YMaps
			query={{
				apikey: "f89fcd6e-02eb-46fc-a449-8ff53f633b7e",
				// ns: "use-load-option",
				load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
				lang: "en_RU",
			}}
		>
			<Map
				style={{ width: "100%", height: `100%` }}
				state={defaultState}
				modules={["control.ZoomControl", "control.FullscreenControl"]}
				onClick={onClick}
			>
				<Placemark
					geometry={[lat, long]}
					options={{ draggable }}
					onDragEnd={(e: any) => {
						const coordinates = e.get("target").geometry.getCoordinates();
						const [latitude, longitude] = coordinates;
						setLat(latitude);
						setLong(longitude);
					}}
				/>
				{children}
			</Map>
		</YMaps>
	);
};

export default YMap;
