export default function ArrowDown2(props: any) {
	return (
		<svg
			width="11"
			height="7"
			viewBox="0 0 11 7"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.7643 1.93288L6.05 6.64717C5.89286 6.80431 5.73571 6.88288 5.5 6.88288C5.26429 6.88288 5.10714 6.80431 4.95 6.64717L0.235714 1.93288C-0.0785714 1.6186 -0.0785714 1.14717 0.235714 0.832882C0.55 0.518597 1.02143 0.518597 1.33571 0.832882L5.5 4.99717L9.66429 0.832882C9.97857 0.518597 10.45 0.518597 10.7643 0.832882C11.0786 1.14717 11.0786 1.6186 10.7643 1.93288Z"
				fill="#929292"
			/>
		</svg>
	);
}
